<template>
	<div>
		<!-- 좌측 데이터 영역 -->
		<div class="screening-left-wrap">
			<p class="page-title">Collect Data</p>
			<!-- 데이터 검색 -->
			<input type="text" class="input-list-search" placeholder="Please enter the numbering." />
			<p class="list-title">Data List</p>
			<!-- 데이터 리스트 wrap -->
			<div class="list-wrap scroll">
				<div
					v-for="(item, index) in screeningList"
					:key="index"
					class="list-item no-drag"
					:class="selectedScreeningNo == item.testScreeningNo ? 'select' : ''"
					@click="selectData(item)"
				>
					{{ item.screeningName }}
					<div
						v-if="item.openQueryYn > 0"
						class="openQueryDataList"
					>
						!
					</div>
				</div>
			</div>
		</div>
		<!-- 중앙 컨테이너 wrap -->
		<div class="screening-center-wrap">
			<p class="schedule-title">
				{{ selectedScreeningName }}
			</p>
			<div class="schedule-status">
				<i class="schedule-disabled" /> Disabled <i class="schedule-wait" /> Before Input <i class="schedule-temp" /> Temp Save 
				<i class="schedule-success" /> Input Complete <i class="schedule-compare" /> Monitoring Request 
				<i class="schedule-modify" /> Request Edit <i class="schedule-pass" /> End Monitoring 
				<i class="schedule-sign" /> PI Signature <i class="schedule-freezing" /> Data Freezing
			</div>
			<div class="scroll-wrap">
				<div class="scroll-thead-wrap" :style="headRowSet()">
					<table class="schedule-table">
						<thead>
							<tr ref="headRow">
								<th></th>
								<template v-for="(item, index) in visitList">
									<th v-if="item.unscheduleYn == 'Y'" :key="index">
										<span
											
											@click="updateUnAdd(item)"
											style="text-decoration: underline; cursor: pointer;"
										>
											{{ item.visitName }}
										</span>
									</th>
									<th v-else-if="item.unscheduleYn == 'N' && item.useYn =='Y'" :key="item.visitNo + index" :style="headThSet()">
										<span>
											{{ item.visitName }}
										</span>
									</th>
								</template>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(pageItem, pageIndex) in pageList" :key="pageIndex">
								<th>
									{{ pageItem.pageLabel }}
								</th>
								<template
									v-for="(visitItem, visitIndex) in visitList"
								>
									<td v-if="visitItem.unscheduleYn == 'Y'" :key="visitIndex">
										<button
											v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) > -1 && visitItem.useYn == 'Y'"
											class="schedule-icon"
											:class="{
												'schedule-wait' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == null || visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '1',
												'schedule-temp' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '2',
												'schedule-success' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '3',
												'schedule-compare' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '4',
												'schedule-modify' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '5',
												'schedule-pass' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '6',
												'schedule-sign' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '7',
												'schedule-freezing' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '8',
												'schedule-disabled' : disabledCheck(pageItem, visitItem, visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)]),
											}"
											@click="selectPage(pageItem, visitItem)"
										></button>
									</td>
									<td v-else-if="visitItem.unscheduleYn == 'N' && visitItem.useYn =='Y'" :key="visitItem.visitNo + visitIndex">
										<button
											v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) > -1 && visitItem.useYn == 'Y'"
											class="schedule-icon"
											:class="{
												'schedule-wait' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == null || visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '1',
												'schedule-temp' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '2',
												'schedule-success' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '3',
												'schedule-compare' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '4',
												'schedule-modify' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '5',
												'schedule-pass' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '6',
												'schedule-sign' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '7',
												'schedule-freezing' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '8',
												'schedule-disabled' : disabledCheck(pageItem, visitItem, visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)]),
											}"
											@click="selectPage(pageItem, visitItem)"
										></button>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="scroll-tbody-wrap scroll">
					<table class="schedule-table">
						<thead>
							<tr>
								<th></th>
								<template v-for="(item, index) in visitList">
									<th v-if="item.unscheduleYn == 'Y'" :key="index">
										<span
											
											@click="updateUnAdd(item)"
											style="text-decoration: underline; cursor: pointer;"
										>
											{{ item.visitName }}
										</span>
									</th>
									<th v-else-if="item.unscheduleYn == 'N' && item.useYn =='Y'" :key="item.visitNo + index">
										<span>
											{{ item.visitName }}
										</span>
									</th>
								</template>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(pageItem, pageIndex) in pageList" :key="pageIndex">
								<th>
									{{ pageItem.pageLabel }}
								</th>
								<template
									v-for="(visitItem, visitIndex) in visitList"
								>
									<td v-if="visitItem.unscheduleYn == 'Y'" :key="visitIndex">
										<button
											v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) > -1 && visitItem.useYn == 'Y'"
											class="schedule-icon"
											:class="{
												'schedule-wait' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == null || visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '1',
												'schedule-temp' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '2',
												'schedule-success' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '3',
												'schedule-compare' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '4',
												'schedule-modify' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '5',
												'schedule-pass' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '6',
												'schedule-sign' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '7',
												'schedule-freezing' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '8',
												'schedule-disabled' : disabledCheck(pageItem, visitItem, visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)]),
											}"
											@click="selectPage(pageItem, visitItem)"
										></button>
										<div
											v-if="visitItem.openQueryList.indexOf(pageItem.pageNo) > -1 && visitItem.useYn == 'Y'"
											class="queryOpened"
										>!</div>
									</td>
									<td v-else-if="visitItem.unscheduleYn == 'N' && visitItem.useYn =='Y'" :key="visitItem.visitNo + visitIndex">
										<button
											v-if="visitItem.visitPageList.indexOf(pageItem.pageNo) > -1 && visitItem.useYn == 'Y'"
											class="schedule-icon"
											:class="{
												'schedule-wait' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == null || visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '1',
												'schedule-temp' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '2',
												'schedule-success' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '3',
												'schedule-compare' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '4',
												'schedule-modify' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '5',
												'schedule-pass' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '6',
												'schedule-sign' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '7',
												'schedule-freezing' : visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)] == '8',
												'schedule-disabled' : disabledCheck(pageItem, visitItem, visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)]),
											}"
											@click="selectPage(pageItem, visitItem)"
										>
										</button>
										<div
											v-if="visitItem.openQueryList.indexOf(pageItem.pageNo) > -1 && visitItem.useYn == 'Y'"
											class="queryOpened"
										>!</div>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<button
				v-if="workAccessCheck('UNSCHEDULE AUTH') && !(projectStateCd == '4')"
				type="button"
				class="btn-new fright"
				title="New Visit"
				style="margin-top: 15px;"
				@click="unAddPopup()"
			>
				UN ADD
			</button>
		</div>
		<ScreeningSingleCreate v-if="isSingleCreate" :param="paramSigleCreate" />
		<ScreeningMultipleList v-if="isMultipleList" :param="paramMultipleList" />
		<TestScreeningUnAdd v-if="isUnAdd" :siteCode="this.siteCode"/>
		<TestScreeningUnUpdate v-if="isUnUpdate" :siteCode="this.siteCode" :unUpdate="this.unUpdate"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import ScreeningSingleCreate from "@/components/project/testScreening/TestScreeningSingleCreate.vue";
import ScreeningMultipleList from "@/components/project/screening/ScreeningMultipleList.vue";
import TestScreeningUnAdd from "@/components/project/testScreening/TestScreeningUnAdd.vue";
import TestScreeningUnUpdate from "@/components/project/testScreening/TestScreeningUnUpdate.vue";

export default {
	name: "ScreeningEntryList",
	components: {
		ScreeningSingleCreate,
		ScreeningMultipleList,
		TestScreeningUnAdd,
		TestScreeningUnUpdate,
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.$route.params.projectNo;
		this.items.testScreeningNo = this.$route.params.testScreeningNo;
		this.selectedScreeningNo = this.$route.params.testScreeningNo;
		this.disableApply();
		this.selectProjectItem();
		this.selectScreeningList();
		this.selectDataVisitList();
		this.selectPageList();
		this.selectItemList();
		this.getAccessList('TEST ENTRY');
		this.getProjectStateCd();

		// 리스트 새로고침
		EventBus.$on("refresh-data-list", (selectedScreeningNo) => {
			this.logger.debug(this, "[EventBus] refresh-data-list(" + selectedScreeningNo + ")");
		});
	},
	updated(){
		this.headRow();
	},
	data() {
		return {
			items: {},
			screeningList: [],
			selectedScreeningNo: "",
			visitList: [],
			pageList: [],
			validList : [],
			itemList : [],
			paramSigleCreate: {},
			paramMultipleList: {},
			projectItem: {},
			visitpageList: [],
			isSingleCreate: false,
			isMultipleList: false,
			isUnAdd: false,
			isUnUpdate: false,
			unUpdate: [],
			siteCode: "",
			disablePageList: [],
			disableProcList: [],
			disableQuestionList: [],
			validResultMsg: [],
			accessList: [],
			projectStateCd: "",
			applyValidVoList: [],
			height: "",
		};
	},
	computed: {
		selectedScreeningName: function () {
			let indexList = this.screeningList.map((item) => {
				return item.testScreeningNo;
			});
			let nameList = this.screeningList.map((item) => {
				return item.screeningName;
			});
			let selectedIndex = indexList.indexOf(this.selectedScreeningNo);

			return nameList[selectedIndex];
		},
	},
	methods: {
		selectProjectItem() {
			if (this.$route.params.projectNo === undefined || this.$route.params.projectNo == "") this.projectNo = "";
			else {
				this.projectNo = this.$route.params.projectNo;
				axios
					.post(this.config.contextRoot + "/project/select.do", {projectNo: this.projectNo})
					.then((response) => {
						console.log(response);
						this.projectItem = response.data.item;
						EventBus.$emit("project-loading", false);
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
		},
		disableApply(){
			this.logger.debug(this, "disableApply()");
			axios
				.post(this.config.contextRoot + "/testScreening/disableApply.do", this.items)
				.then((response) => {
					console.log(response);
					this.applyValidVoList = response.data.applyValidVoList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectScreeningList() {
			this.logger.debug(this, "selectScreeningList()");
			axios
				.post(this.config.contextRoot + "/testScreening/selectList.do", this.items)
				.then((response) => {
					console.log(response);
					this.screeningList = response.data.screeningList;
					this.siteCode = this.screeningList[0].siteCode;

					this.$nextTick(function () {
						setTimeout(
							function () {
								EventBus.$emit("project-loading", false);
							}.bind(this),
							500
						);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectDataVisitList() {
			this.logger.debug(this, "selectDataVisitList()");

			let param = {
				projectNo: this.items.projectNo,
				testScreeningNo: this.selectedScreeningNo,
			}
			axios
				.post(this.config.contextRoot + "/testScreening/dataVisitList.do", param)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.dataVisitList;

				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/schedule/selectPageList.do", {projectNo: this.items.projectNo})
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectValidList() {
			this.logger.debug(this, "selectValidList()");
			axios
				.post(this.config.contextRoot + "/valid/selectValidList.do", {projectNo: this.items.projectNo})
				.then((response) => {
					console.log(response);
					this.validList = response.data.validList;
				})
				.catch((error) => { 
					this.exceptionHandler(this, error);
				});
		},
		selectItemList() {
			this.logger.debug(this, "selectItemList()");
			let param = {
				projectNo: this.items.projectNo,
				testScreeningNo: this.selectedScreeningNo,
			}
			axios
				.post(this.config.contextRoot + "/testScreening/selectItemList.do", param)
				.then((response) => {
					console.log(response);
					this.itemList = response.data.itemList;

					for(let i = 0; i < this.itemList.length; i++)
					{
						for(let j = 0; j < this.itemList[i].projectDictionaryList.length; j++)
						{
							for(let k = 0; k < this.itemList[i].projectDictionaryList[j].procedureList.length; k++)
							{
								for(let l = 0; l < this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; l++)
								{
									for(let p = 0; p < this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList.length; p++)
									{
										if (this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldTypeCd == "04") {
											if (this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue.indexOf(";") >= 0) {
												this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue = this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue.split(";");
											} else if (this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue.length > 0) {
												this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue = [this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue];
											} else {
												this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldList[p].fieldValue = [];
											}
										}
									}
									for(let p = 0; p < this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList.length; p++)
									{
										if (this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[p].fieldTypeCd == "04") {
											if (this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[p].fieldValue.indexOf(";") >= 0) {
												this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[p].fieldValue = this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[k].fieldValue.split(";");
											} else if (this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[p].fieldValue.length > 0) {
												this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[p].fieldValue = [this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[k].fieldValue];
											} else {
												this.itemList[i].projectDictionaryList[j].procedureList[k].procQuestionList[l].fieldRepeatList[p].fieldValue = [];
											}
										}
									}
								}
							}
						}
					}
					// this.setValidation();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				
		},
		setValidation(){
			this.logger.debug(this, "setValidation()");

			// field 값 가져와서 condetail에 value 설정
			for(let i = 0; i < this.validList.length; i++)
			{
				for(let j = 0; j < this.validList[i].validDetailList.length; j++)
				{
					for(let k = 0; k < this.validList[i].validDetailList[j].validConList.length; k++)
					{
						for(let l = 0; l < this.validList[i].validDetailList[j].validConList[k].validConDetailList.length; l++)
						{
							let firstValue = "";
							let targetValue = "";
							
							for(let q = 0; q < this.itemList.length; q++)
							{
								if(this.itemList[q].visitNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].visitNo)
								{
									for(let w = 0; w < this.itemList[q].projectDictionaryList.length; w++)
									{
										if(this.itemList[q].projectDictionaryList[w].pageNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].pageNo)
										{
											for(let e = 0; e < this.itemList[q].projectDictionaryList[w].procedureList.length; e++)
											{
												if(this.itemList[q].projectDictionaryList[w].procedureList[e].procNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].procNo)
												{
													for(let r = 0; r < this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList.length; r++)
													{
														if(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].procQuestionNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].procQuestionNo)
														{
															for(let t = 0; t < this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList.length; t++)
															{
																if(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList[t].procFieldNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].procFieldNo)
																{
																	firstValue = (this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList[t].fieldValue);
																}
															}
															// for(let t = 0; t < this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldRepeatList.length; t++)
															// {
															// 	if(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList[t].procFieldNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].procFieldNo)
															// 	{
															// 		firstValue.push(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldRepeatList[t].fieldValue);
															// 	}
															// }
														}
													}
												}
											}
										}
									}
								}
							}
							if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetTypeCd == 'FIELD')
							{
								for(let q = 0; q < this.itemList.length; q++)
								{
									if(this.itemList[q].visitNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetVisitNo)
									{
										for(let w = 0; w < this.itemList[q].projectDictionaryList.length; w++)
										{
											if(this.itemList[q].projectDictionaryList[w].pageNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetPageNo)
											{
												for(let e = 0; e < this.itemList[q].projectDictionaryList[w].procedureList.length; e++)
												{
													if(this.itemList[q].projectDictionaryList[w].procedureList[e].procNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetProcNo)
													{
														for(let r = 0; r < this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList.length; r++)
														{
															if(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].procQuestionNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetProcQuestionNo)
															{
																for(let t = 0; t < this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList.length; t++)
																{
																	if(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList[t].procFieldNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetProcFieldNo)
																	{
																		targetValue = (this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList[t].fieldValue);
																	}
																}
																// for(let t = 0; t < this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldRepeatList.length; t++)
																// {
																// 	if(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldList[t].procFieldNo == this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetProcFieldNo)
																// 	{
																// 		targetValue.push(this.itemList[q].projectDictionaryList[w].procedureList[e].procQuestionList[r].fieldRepeatList[t].fieldValue);
																// 	}
																// }
															}
														}
													}
												}
											}
										}
									}
								}
								this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "targetValue", targetValue);
							}
							this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "value", firstValue);
						}
					}
				}
			}

			// Condetail값 비교 작업
			for(let i = 0; i < this.validList.length; i++)
			{
				for(let j = 0; j < this.validList[i].validDetailList.length; j++)
				{
					for(let k = 0; k < this.validList[i].validDetailList[j].validConList.length; k++)
					{
						for(let l = 0; l < this.validList[i].validDetailList[j].validConList[k].validConDetailList.length; l++)
						{
							let value = parseInt(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].value);
							let targetValue = parseInt(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].targetValue);
							if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].operatorCd == '01') // ==
							{
								if(value == targetValue)
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "Y");
								}
								else
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "N");
								}
							}
							else if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].operatorCd == '02') // !=
							{
								if(value != targetValue)
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "Y");
								}
								else
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "N");
								}
							}
							else if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].operatorCd == '03') // <
							{
								if(value < targetValue)
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "Y");
								}
								else
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "N");
								}
							}
							else if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].operatorCd == '04') // <=
							{
								if(value <= targetValue)
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "Y");
								}
								else
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "N");
								}
							}
							else if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].operatorCd == '05') // >
							{
								if(value > targetValue)
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "Y");
								}
								else
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "N");
								}
							}
							else if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].operatorCd == '06') // >=
							{
								if(value >= targetValue)
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "Y");
								}
								else
								{
									this.$set(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l], "validConDetailYn", "N");
								}
							}
						}
					}
				}
			}

			// conList 비교 작업
			for(let i = 0; i < this.validList.length; i++)
			{
				for(let j = 0; j < this.validList[i].validDetailList.length; j++)
				{
					for(let k = 0; k < this.validList[i].validDetailList[j].validConList.length; k++)
					{
						let conYnCount = "";
						let ynCount = [];
						for(let l = 0; l < this.validList[i].validDetailList[j].validConList[k].validConDetailList.length; l++)
						{
							if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].logicalOperatorCd == "1")
							{
								if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].validConDetailYn == "Y" && this.validList[i].validDetailList[j].validConList[k].validConDetailList[l+1].validConDetailYn == "Y")
								{
									ynCount.push("Y");
								}
								else
								{
									ynCount.push("N");
								}
							}
							else if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].logicalOperatorCd == "2")
							{
								if(this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].validConDetailYn == "Y" || this.validList[i].validDetailList[j].validConList[k].validConDetailList[l+1].validConDetailYn == "Y")
								{
									ynCount.push("Y");
								}
								else
								{
									ynCount.push("N");
								}
							}
							else
							{
								if(ynCount.length != 0 && ynCount.includes("N"))
								{
									conYnCount = "N";
								}
								else if(ynCount.length == 0 && this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].validConDetailYn == "N")
								{
									conYnCount = "N";
								}
								else if(ynCount.length == 0 && this.validList[i].validDetailList[j].validConList[k].validConDetailList[l].validConDetailYn == "Y")
								{
									conYnCount = "Y";
								}
								else
								{
									conYnCount = "Y";
								}
							}
						}
						this.$set(this.validList[i].validDetailList[j].validConList[k], "validConYn", conYnCount);
					}
				}
			}

			// ValidDetail 비교 작업
			for(let i = 0; i < this.validList.length; i++)
			{
				for(let j = 0; j < this.validList[i].validDetailList.length; j++)
				{
					let conYnCount = "";
					let ynCount = [];
					for(let k = 0; k < this.validList[i].validDetailList[j].validConList.length; k++)
					{
						if(this.validList[i].validDetailList[j].validConList[k].groupOperator == "1")
						{
							if(this.validList[i].validDetailList[j].validConList[k].validConYn == "Y" && this.validList[i].validDetailList[j].validConList[k+1].validConYn == "Y")
							{
								ynCount.push("Y");
							}
							else
							{
								ynCount.push("N");
							}
						}
						else if(this.validList[i].validDetailList[j].validConList[k].groupOperator == "2")
						{
							if(this.validList[i].validDetailList[j].validConList[k].validConYn == "Y" || this.validList[i].validDetailList[j].validConList[k+1].validConYn == "Y")
							{
								ynCount.push("Y");
							}
							else
							{
								ynCount.push("N");
							}
						}
						else
						{
							if(ynCount.length != 0 && ynCount.includes("N"))
								{
									conYnCount = "N";
								}
								else if(ynCount.length == 0 && this.validList[i].validDetailList[j].validConList[k].validConYn == "N")
								{
									conYnCount = "N";
								}
								else if(ynCount.length == 0 && this.validList[i].validDetailList[j].validConList[k].validConYn == "Y")
								{
									conYnCount = "Y";
								}
								else
								{
									conYnCount = "Y";
								}
						}
					}
					this.$set(this.validList[i].validDetailList[j], "validYn", conYnCount);
				}
			}
			this.validationYn();
		},
		validationYn(){
			this.disablePageList = [];
			this.disableProcList = [];
			this.disableQuestionList = [];
			this.validResultMsg = [];
			// valid 적용 작업
				for(let i = 0; i < this.validList.length; i++)
				{
					if(this.validList[i].useYn == "Y")
					{
						for(let j = 0; j < this.validList[i].validDetailList.length; j++)
						{
							if(this.validList[i].validDetailList[j].disablePageYn == "Y")
							{
								for(let k = 0; k < this.validList[i].validDetailList[j].validResultDsaList.length; k++)
								{
									if(this.validList[i].validDetailList[j].validResultDsaList[k].disabledTypeCd == "PAGE")
									{
										if(this.validList[i].validDetailList[j].validResultDsaList[k].conditionYn == "Y" && this.validList[i].validDetailList[j].validYn == "Y")
										{
											for(let l = 0; l < this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList.length; l++)
											{
												this.disablePageList.push(this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList[l].pageNo);
											}
										}
										else if(this.validList[i].validDetailList[j].validResultDsaList[k].conditionYn == "N" && this.validList[i].validDetailList[j].validYn == "N")
										{
											for(let l = 0; l < this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList.length; l++)
											{
												this.disablePageList.push(this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList[l].pageNo);
											}
										}
									}
								}
							}
							if(this.validList[i].validDetailList[j].printMsgYn == "Y")
							{
								if(this.validList[i].validDetailList[j].validResultMsg.conditionYn == "Y" && this.validList[i].validDetailList[j].validYn == "Y")
								{
									this.validResultMsg.push(this.validList[i].validDetailList[j].validResultMsg);
								}
								else if(this.validList[i].validDetailList[j].validResultMsg.conditionYn == "N" && this.validList[i].validDetailList[j].validYn == "N")
								{
									this.validResultMsg.push(this.validList[i].validDetailList[j].validResultMsg);
								}
							}
							if(this.validList[i].validDetailList[j].disableProcYn == "Y")
							{
								for(let k = 0; k < this.validList[i].validDetailList[j].validResultDsaList.length; k++)
								{
									if(this.validList[i].validDetailList[j].validResultDsaList[k].disabledTypeCd == "PROC")
									{
										if(this.validList[i].validDetailList[j].validResultDsaList[k].conditionYn == "Y" && this.validList[i].validDetailList[j].validYn == "Y")
										{
											for(let l = 0; l < this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList.length; l++)
											{
												this.disableProcList.push(this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList[l]);
											}
										}
										else if(this.validList[i].validDetailList[j].validResultDsaList[k].conditionYn == "N" && this.validList[i].validDetailList[j].validYn == "N")
										{
											for(let l = 0; l < this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList.length; l++)
											{
												this.disableProcList.push(this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList[l]);
											}
										}
									}
								}
							}
							if(this.validList[i].validDetailList[j].disableQuestionYn == "Y")
							{
								for(let k = 0; k < this.validList[i].validDetailList[j].validResultDsaList.length; k++)
								{
									if(this.validList[i].validDetailList[j].validResultDsaList[k].disabledTypeCd == "QUESTION")
									{
										if(this.validList[i].validDetailList[j].validResultDsaList[k].conditionYn == "Y" && this.validList[i].validDetailList[j].validYn == "Y")
										{
											for(let l = 0; l < this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList.length; l++)
											{
												this.disableQuestionList.push(this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList[l]);
											}
										}
										else if(this.validList[i].validDetailList[j].validResultDsaList[k].conditionYn == "N" && this.validList[i].validDetailList[j].validYn == "N")
										{
											for(let l = 0; l < this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList.length; l++)
											{
												this.disableQuestionList.push(this.validList[i].validDetailList[j].validResultDsaList[k].validResultDsaDetailList[l]);
											}
										}
									}
								}
							}
						}
					}
				}
		},
		selectData(screening) {
			this.logger.debug(this, "selectData(screening)");
			this.selectedScreeningNo = screening.testScreeningNo;
			this.items.testScreeningNo = screening.testScreeningNo;
			
			let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/testScreening/" + screening.testScreeningNo;
			if (this.$route.path !== url)
			{
				EventBus.$emit("project-loading", true);
				this.$router.push(url);
				this.selectDataVisitList();
				this.selectItemList();
				this.disableApply();
				location.reload();
			}
		},
		disabledCheck(pageItem, visitItem, status){
			let result = false;
			for(let i = 0; i < this.applyValidVoList.length; i++){
				let detailVo = this.applyValidVoList[i].validDetailVo;

				for(let j = 0;  j < detailVo.validResultDsaList.length; j++){
					let dsaVo = detailVo.validResultDsaList[j];
					
					if(dsaVo.disabledTypeCd == 'VISIT'){
						if(dsaVo.dataRemoveYn == 'Y'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
								if(dsaDetailVo.visitNo == visitItem.visitNo){
									result = true;
								}
								if(dsaDetailVo.visitNo == 0){
									result = true;
								}
							}
						}
						else if(dsaVo.dataRemoveYn == 'N'){
							// let outcomeCnt = 0;
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];

								if(dsaDetailVo.outcomeDataCnt > 0){
									if(dsaDetailVo.visitNo == visitItem.visitNo){
										if(status != null && status != '1'){
											result = false;
										}
									}
									if(dsaDetailVo.visitNo == 0){
										if(status != null && status != '1'){
											result = false;
										}
									}
								}
								
								// outcomeCnt += dsaDetailVo.outcomeDataCnt;
							}
							// if(outcomeCnt > 0){
							// 	for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
							// 		let dsaDetailVo = dsaVo.validResultDsaDetailList[k];

							// 		if(dsaDetailVo.visitNo == visitItem.visitNo){
							// 			result = false;
							// 		}
							// 	}
							// }
							// else{
							// 	for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
							// 		let dsaDetailVo = dsaVo.validResultDsaDetailList[k];

							// 		if(dsaDetailVo.visitNo == visitItem.visitNo){
							// 			result = true;
							// 		}
							// 	}
							// }
						}
					}

					if(dsaVo.disabledTypeCd == 'PAGE'){
						if(dsaVo.dataRemoveYn == 'Y'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
	
								if(dsaDetailVo.visitNo == visitItem.visitNo && dsaDetailVo.pageNo == pageItem.pageNo){
									result = true;
								}
								if(dsaDetailVo.visitNo == 0 && dsaDetailVo.pageNo == pageItem.pageNo){
									result = true;
								}
							}
						}
						if(dsaVo.dataRemoveYn == 'N'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
								
								if(dsaDetailVo.visitNo == visitItem.visitNo && dsaDetailVo.pageNo == pageItem.pageNo){
									if(status != null && status != '1'){
										result = false;
									}
									else{
										result = true;
									}
								}
								if(dsaDetailVo.visitNo == 0 && dsaDetailVo.pageNo == pageItem.pageNo){
									if(status != null && status != '1'){
										result = false;
									}
									else{
										result = true;
									}
								}
							}
						}
					}
				}
			}

			return result;
		},
		doGotoPage(gotoVo){
			this.logger.debug(this, "parent");
			let pageItem = {}
			let visitItem = {}
			for (let i = 0; i < this.pageList.length; i++){
				if(gotoVo.goPageNo == this.pageList[i].pageNo){
					pageItem = this.pageList[i];
				}
			}
			
			for(let i = 0; i < this.visitList.length; i++){
				if(gotoVo.goVisitNo == this.visitList[i].visitNo){
					visitItem = this.visitList[i];
				}
			}

			// let gotoList = {pageItem: pageItem, visitItem: visitItem};
			
			if (this.projectItem.projectEntryUnitCd == "1") {
				if(this.disabledCheck(pageItem, visitItem))
				{
					this.$alert("Disabled Page","Warning","warning");
				}
				else
				{
					this.paramSigleCreate = {
						pageNo: pageItem.pageNo,
						centralEdcPageNo: pageItem.centralEdcPageNo,
						pageLabel: pageItem.pageLabel,
						pageName: pageItem.pageName,
						projectNo: this.items.projectNo,
						visitNo: visitItem.visitNo,
						centralEdcVisitNo: visitItem.centralEdcVisitNo,
						visitName: visitItem.visitName,
						visitPageList: visitItem.screeningVisitPageList,
						selectedScreeningNo: this.selectedScreeningNo,
						selectedScreeningName: this.selectedScreeningName,
						entryStatus: visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)],
					};
					this.isSingleCreate = true;
				}
			}
			// return gotoList;
		},
		selectPage(pageItem, visitItem) {
			this.logger.debug(this, "selectPage(" + pageItem + "," + visitItem + ")");
			// this.setValidation();
			if (this.projectItem.projectEntryUnitCd == "1") {
				if(this.disabledCheck(pageItem, visitItem))
				{
					this.$alert("Disabled Page","Warning","warning");
				}
				else
				{
					this.paramSigleCreate = {
						pageNo: pageItem.pageNo,
						centralEdcPageNo: pageItem.centralEdcPageNo,
						pageLabel: pageItem.pageLabel,
						pageName: pageItem.pageName,
						projectNo: this.items.projectNo,
						visitNo: visitItem.visitNo,
						centralEdcVisitNo: visitItem.centralEdcVisitNo,
						visitName: visitItem.visitName,
						visitPageList: visitItem.screeningVisitPageList,
						selectedScreeningNo: this.selectedScreeningNo,
						selectedScreeningName: this.selectedScreeningName,
						entryStatus: visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)],
					};
					this.isSingleCreate = true;
				}
			} else if (this.projectItem.projectEntryUnitCd == "2") {
				this.paramMultipleList = {
					pageNo: pageItem.pageNo,
					centralEdcPageNo: pageItem.centralEdcPageNo,
					pageLabel: pageItem.pageLabel,
					pageName: pageItem.pageName,
					projectNo: pageItem.projectNo,
					visitNo: visitItem.visitNo,
					centralEdcVisitNo: visitItem.centralEdcVisitNo,
					visitName: visitItem.visitName,
					visitPageList: visitItem.screeningVisitPageList,
					selectedScreeningNo: this.selectedScreeningNo,
					selectedScreeningName: this.selectedScreeningName,
					entryStatus: visitItem.visitPageUserList[visitItem.visitPageList.indexOf(pageItem.pageNo)],
				};
				this.isMultipleList = true;
			}
		},
		unAddPopup() {
			this.logger.debug(this, "unAddPopup()");
			this.isUnAdd = true;
		},
		updateUnAdd(item) {
			this.logger.debug(this, "unAddPopup()");

			let sortOrder = item.visitSortOrder.split('.', 1);

			for(let i = 0; i < this.visitList.length; i++){
				if(this.visitList[i].visitSortOrder == sortOrder){
					this.$set(item, "afterVisitNo", this.visitList[i].visitNo);
				}
			}

			this.unUpdate = [];
			this.unUpdate = item;
			this.isUnUpdate = true;
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		headRow(){
			this.height = this.$refs.headRow.scrollHeight;
		},
		headRowSet(){
			if(this.height > 48){
				var height = this.height + 2;
				return "height : " + height + "px";
			}
		},
		headThSet(){
			return "height : " + this.height + "px";
		},
	},
};
</script>

<style scoped>
.scroll-wrap {
	position: relative;
	width: 100%;
	height: calc(100% - 155px);
	border-bottom: 1px solid #ccc;
	overflow-x: visible;
	z-index: 0;
}

.scroll-thead-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-width: 100%;
	height: 48px;
	background-color: #fff;
	overflow-y: hidden;
	z-index: 10;
}

.scroll-thead-wrap tbody {
	visibility: hidden;
}

.scroll-tbody-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-width: calc(100% + 18px);
	height: 100%;
	overflow-y: scroll;
	z-index: 5;
}

.scroll-tbody-wrap thead {
	visibility: hidden;
}

.schedule-table tbody tr:last-child th, .schedule-table tbody tr:last-child td {
    border-bottom: none;
}
.queryOpened{
	position: absolute;
    right: calc(50% - 23px);
    top: -2px;
	color: #dd574f;
    font-weight: bold;
	font-size: 20px;
}
.openQueryDataList{
	position: absolute;
    left: 29px;
    top: 0;
    color: #dd574f;
    font-weight: bold;
    font-size: 20px;
}
</style>