import { render, staticRenderFns } from "./ConfirmTestQuery.vue?vue&type=template&id=0ffd2f7e&scoped=true&"
import script from "./ConfirmTestQuery.vue?vue&type=script&lang=js&"
export * from "./ConfirmTestQuery.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmTestQuery.vue?vue&type=style&index=0&id=0ffd2f7e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ffd2f7e",
  null
  
)

export default component.exports