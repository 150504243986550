<template>
	<div
		ref="fieldEdit"
		class="full-container"
		@mousemove="dragData.isDrag ? dragging($event) : ''"
		@mouseup="dragData.isDrag ? draggend($event) : ''"
	>
		<div class="full-top-wrap">
			Field Edit
		</div>
		<div id="fieldLayout" class="full-center-wrap">
			<p class="title">
				Category
			</p>
			<!-- 좌측 카테고리 영역 -->
			<div class="field-left">
				<!-- 아이템 타입 검색 -->
				<input
					type="text"
					class="input-list-search"
					placeholder="Please enter the item type name to search."
					style="margin: 0;"
					@focus="infoBalloon"
					@blur="infoBalloon"
				/>
				<div v-if="isTyping" ref="typingBalloon" class="typing-balloon">
					<p>Search function is not provided. It will be provided when there are more field types.</p>
				</div> 
				<!-- 아이템 타입 리스트-->
				<div class="field-category">
					<p class="title">Field Type</p>
					<ul class="field-category-list scroll no-drag">
						<li
							v-for="(item, index) in fieldTypeList"
							:key="index"
							:class="getFieldTypeClass(item)"
							draggable="true"
							@dragstart.prevent="dragstart($event, item)"
							@click.stop="typeClick(item)"
						>
							{{ item.comCodeName }}
						</li>
						<!-- Drag Template -->
						<div
							v-if="dragData.isDrag"
							class="field-category-drag no-drag"
							:class="'type_' + dragData.fieldData.comCode"
							:style="dragData.position"
						>
							{{ dragData.fieldData.comCodeName }}
						</div>
					</ul>
				</div>
			</div>
			<form @submit.prevent="doValidation()">
				<!-- 우측 상단 Field Name 영역 -->
				<div class="field-name" style="display: flex;" id="fieldName">
					<div class="field-name-label">
						Group Name
					</div>
					<div class="field-name-input">
						<input
							type="text"
							name="questionFieldName"
							class="input-crud"
							v-model="fieldGroupName.questionGroupName"
							v-validate="'required'"
							readonly
						/>
					</div>
					<div class="field-name-label">
						Field Name
					</div>
					<div class="field-name-input">
						<input
							type="text"
							name="questionFieldName"
							class="input-crud fieldChildName"
							:class="(errors.has('questionFieldName') ? 'input-crud-error' : '')"
							:id="'questionFieldName'"	
							:ref="'questionFieldName'"
							:placeholder="(errors.has('questionFieldName') ? '* ' + errors.first('questionFieldName') : 'Please enter the field name.')"
							v-model="item.questionFieldName"
							v-validate="'required'"
							data-vv-as="Field Name"
							@keyup="fieldChildNameDuplicatedCheck($event)"
							:disabled="existFlag"
						/>
						<p class="txt-validation" v-if="duplicatedFlag">* Duplicate values exist.</p>
					</div>
				</div>
				<!-- 우측 중앙 Survey 생성 영역 -->
				<div
					id="fieldContainer"
					ref="fieldContainer"
					class="field-contents-container"
					:style="dragData.isDrag ? {cursor: 'grab'} : {}"
					@mousemove="dragData.isDrag ? showPreview($event) : ''"
					@mouseleave="dragData.isDrag ? hidePreview() : ''"
				>
					<!-- Preview Template -->
					<div v-if="preview.isPreview && fieldList.length == 0" class="field-preview">
						<div class="field-preview-question" :class="'type_' + dragData.fieldData.comCode">
							<span>{{ dragData.fieldData.comCodeName }}</span>
						</div>
					</div>
					<template v-for="(field, index) in fieldList">
						<!-- Preview Template -->
						<div v-if="preview.isPreview && preview.index == index" :key="2000 + index" class="field-preview">
							<div class="field-preview-question" :class="'type_' + dragData.fieldData.comCode">
								<span>{{ dragData.fieldData.comCodeName }}</span>
							</div>
						</div>
						<!-- Field -->
						<div :key="index" class="field-wrap">
							<!-- Field Question -->
							<div
								class="field-wrap-question"
								:class="'type_' + field.fieldTypeCd + (['08', '09', '10', '11', '12'].includes(field.fieldTypeCd) ? ' icon_fix' : '')"
							>
								<span>{{ field.fieldTypeName }}</span>
								<button
									type="button"
									title="Remove"
									class="btn-x"
									@click.prevent="removeFieldItem(index)"
								></button>
							</div>
							<div class="field-wrap-item no-drag">
								<label>	
									Field Label	
										<input	
										type="text"	
										:name="'questionName' + index"	
										class="input-crud"	
										:class="errors.has('questionName' + index) ? 'input-crud-error' : ''"	
										v-model="item.questionName"	
										:data-vv-as="'Field Label'"	
										v-validate="'required'"	
										:placeholder="errors.has('questionName' + index) ? errors.first('questionName' + index) : 'Please enter the field label.'"	
									/>
								</label>
								<label v-if="field.fieldTypeCd != '12'">
									Layout
									<select
										:name="'fieldLayoutCd' + index"
										class="select-crud"
										:class="errors.has('fieldLayoutCd' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].fieldLayoutCd"
										v-validate="'required'"
									>
										<option
											v-for="option in fieldLayoutList"
											:key="option.comCode"
											:value="option.comCode"
										>
											{{ option.comCodeName }}
										</option>
									</select>
								</label>
								<label v-if="field.fieldTypeCd != '12'">
									Prefix Label
									<input
										type="text"
										:name="'prefixName' + index"
										class="input-crud"
										v-model="fieldList[index].prefixName"
										placeholder="Prefix Label"
									/>
								</label>
								<label v-if="field.fieldTypeCd != '12'">
									Suffix Label
									<input
										type="text"
										:name="'suffixName' + index"
										class="input-crud"
										v-model="fieldList[index].suffixName"
										placeholder="Suffix Label"
									/>
								</label>
								<label v-if="['01','02', '03', '04', '05', '08', '09', '12'].includes(field.fieldTypeCd)">
									Data Size
									<input
										type="number"
										:name="'dataSize' + index"
										class="input-crud"
										:class="errors.has('dataSize' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('dataSize' + index) ? '*' + errors.first('dataSize' + index) : '')"
										v-model="fieldList[index].dataSize"
										v-validate="'required'"
										:disabled="['08'].includes(field.fieldTypeCd) || field.dataTypeCd == '6'"
										data-vv-as="Data Size"
										min="1"
										max="2000"
									/>
								</label>
								<label v-if="['01', '02', '03', '04', '05', '12'].includes(field.fieldTypeCd)">
									Data Type
									<select
										:name="'dataType' + index"
										class="select-crud"
										:class="errors.has('dataType' + index) ? 'input-crud-error' : ''"
										:value="fieldList[index].dataTypeCd"
										v-model="fieldList[index].dataTypeCd"
										v-validate="'required'"
										:disabled="existFlag"
									>
										<option
											v-for="option in fieldDataTypeList"
											:key="option.comCode"
											:value="option.comCode"
										>
											{{ option.comCodeName }}
										</option>
									</select>
								</label>
								<label v-else-if="field.fieldTypeCd == '08'">
									Data Type
									<select
										:name="'dataType' + index"
										class="select-crud"
										:class="errors.has('dataType' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].dataTypeCd"
										v-validate="'required'"
										@change="setDateFormat(index, fieldList[index].dataTypeCd)"
										:disabled="existFlag"
									>
										<option
											v-for="option in fieldTimeTypeList"
											:key="option.comCode"
											:value="option.comCode"
										>
											{{ option.comCodeName }}
										</option>
									</select>
								</label>
								<label v-else-if="field.fieldTypeCd == '09'">
									Data Type
									<select
										:name="'dataType' + index"
										class="select-crud"
										:class="errors.has('dataType' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].dataTypeCd"
										v-validate="'required'"
										@change="setDateFormat(index, fieldList[index].dataTypeCd)"
										:disabled="existFlag"
									>
										<option
											v-for="option in fieldCalcTypeList"
											:key="option.comCode"
											:value="option.comCode"
										>
											{{ option.comCodeName }}
										</option>
									</select>
								</label>
								<label v-if="['01', '02', '03', '04', '05', '09', '12'].includes(field.fieldTypeCd) && fieldList[index].dataTypeCd == '6' || fieldList[index].dataTypeCd == '9'">
									Decimal Digit
									<input
										type="text"
										:name="'decimalDigit' + index"
										class="input-crud"
										:class="errors.has('decimalDigit' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('decimalDigit' + index) ? '*' + errors.first('decimalDigit' + index) : '(EX: 3.1 → XXX.X)')"
										v-model="fieldList[index].decimalDigit"
										v-validate="'required'"
										data-vv-as="Decimal Digit"
										@input="fieldList[index].decimalDigit = $event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');calcDataSize(index);"
									/>
								</label>
								<label v-if="['01', '02', '03', '04', '05', '07', '08'].includes(field.fieldTypeCd)">
									Required
									<select
										:name="'isRequired' + index"
										class="select-crud"
										:class="errors.has('isRequired' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].isRequired"
										v-validate="'required'"
									>
										<option
											value="N"
										>
											Not required
										</option>
										<option
											value="Y"
										>
											required
										</option>
									</select>
								</label>
								<label v-if="['08'].includes(field.fieldTypeCd)">
									Allow UK
									<select
										:name="'isAllowUk' + index"
										class="select-crud"
										:class="errors.has('isAllowUk' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].isAllowUk"
										v-validate="'required'"
									>
										<option
											value="N"
										>
											No
										</option>
										<option
											value="Y"
										>
											Yes
										</option>
									</select>
								</label>
								<label v-if="field.fieldTypeCd == '02'">
									TextArea Rows
									<input
										type="number"
										:name="'textareaRows' + index"
										class="input-crud"
										:class="errors.has('textareaRows' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('textareaRows' + index) ? '*' + errors.first('textareaRows' + index) : '')"
										v-model="fieldList[index].textareaRows"
										v-validate="'required'"
										data-vv-as="TextArea Rows"
										min="1"
										max="20"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '03'">
									Radio Cols
									<input
										type="number"
										:name="'radioCols' + index"
										class="input-crud"
										:class="errors.has('radioCols' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('radioCols' + index) ? '*' + errors.first('radioCols' + index) : '')"
										v-model="fieldList[index].radioCols"
										v-validate="'required'"
										data-vv-as="Radio Cols"
										min="1"
										max="10"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '03'"></label>
								<div v-if="field.fieldTypeCd == '03'" class="field-option-area">
									<div class="field-option-top">
										Radio Options
									</div>
									<div
										v-for="(optionList, jndex) in fieldList[index].radioOptionList"
										:key="jndex"
										class="input-options"
									>
										<div>
											<label :for="'radioLabel' + jndex">
												Label
											</label>
											<input
												type="text"
												:id="'radioLabel' + jndex"
												:name="'radioLabel' + jndex"
												class="input-crud options"
												:class="errors.has('radioLabel' + jndex + '.radioValid' + index) ? 'input-crud-error' : ''"
												v-model="optionList.radioOptionsLabel"
												v-validate="'required'"
												:data-vv-name="'radioValid' + index"
												:data-vv-scope="'radioLabel' + jndex"
												:placeholder="errors.has('radioLabel' + jndex + '.radioValid' + index) ? 'Please enter the label.' : 'label'"
												
											/>
										</div>
										<div>
											<label :for="'radioValue' + jndex">
												Value
											</label>
											<input
												type="text"
												:name="index + 'radioValue' + jndex"
												class="input-crud options options-last"
												:class="errors.has(index + 'radioValue' + jndex) ? 'input-crud-error' : ''"
												v-model="optionList.radioOptionsValue"
												v-validate="getValidate(index)"
												:placeholder="errors.has(index + 'radioValue' + jndex) ? errors.first(index + 'radioValue' + jndex) : 'value'"
												data-vv-as="Radio Value"
												@input="(fieldList[index].dataTypeCd == '2' || fieldList[index].dataTypeCd == '8' ? optionList.radioOptionsValue = $event.target.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1') : (fieldList[index].dataTypeCd == '6' || fieldList[index].dataTypeCd == '9' ? optionList.radioOptionsValue = $event.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1') : ''))"
												:disabled="existFlag"
											/>
											<button
												v-if="jndex == 0"
												type="button"
												class="btn-add-option"
												title="New Options"
												@click.stop="radioAddRow(index)"
											>
												<img src="@/assets/images/common/btn_new.png" style="width: 10px; height: 10px;"/>
											</button>
											<button
												v-if="jndex != 0"
												type="button"
												class="btn-remove-options"
												title="Remove Row"
												@click.stop="radioDeleteRow(index, jndex)"
											>
												<img src="@/assets/images/common/minus-sign.png" style="width: 10px; height: 10px;"/>
											</button>
										</div>
									</div>
								</div>
								<div v-if="field.fieldTypeCd == '12'" class="field-option-area">
									<div class="field-option-top">
										Value Options
									</div>
									<div
										v-for="(optionList, jndex) in fieldList[index].defaultValueList"
										:key="jndex"
										class="input-options"
										style="display: block;"
									>
										<div>
											<label :for="'radioValue' + jndex">
												Default Value {{ (jndex + 1) }}
											</label>
											<input
												type="text"
												:name="index + 'defaultValue' + jndex"
												class="input-crud options options-last"
												:class="errors.has(index + 'defaultValue' + jndex) ? 'input-crud-error' : ''"
												v-model="optionList.defaultValue"
												v-validate="getValidate(index)"
												:placeholder="errors.has(index + 'defaultValue' + jndex) ? errors.first(index + 'defaultValue' + jndex) : 'default value.'"
												data-vv-as="Default Value"
												@input="(fieldList[index].dataTypeCd == '2' || fieldList[index].dataTypeCd == '8' ? optionList.defaultValue = $event.target.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1') : (fieldList[index].dataTypeCd == '6' || fieldList[index].dataTypeCd == '9' ? optionList.defaultValue = $event.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1') : ''))"
												:disabled="existFlag"
											/>
											<button
												v-if="jndex == 0"
												type="button"
												class="btn-add-option"
												title="New Options"
												@click.stop="defaultAddRow(index)"
											>
												<img src="@/assets/images/common/btn_new.png" style="width: 10px; height: 10px;"/>
											</button>
											<button
												v-if="jndex != 0"
												type="button"
												class="btn-remove-options"
												title="Remove Row"
												@click.stop="defaultDeleteRow(index, jndex)"
												:disabled="existFlag"
											>
												<img src="@/assets/images/common/minus-sign.png" style="width: 10px; height: 10px;"/>
											</button>
										</div>
									</div>
								</div>
								<label v-if="field.fieldTypeCd == '04'">
									CheckBox Cols
									<input
										type="number"
										:name="'checkboxCols' + index"
										class="input-crud"
										:class="errors.has('checkboxCols' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('checkboxCols' + index) ? '*' + errors.first('checkboxCols' + index) : '')"
										v-model="fieldList[index].checkboxCols"
										v-validate="'required'"
										data-vv-as="CheckBox Cols"
										min="1"
										max="10"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '04'"></label>
								<div v-if="field.fieldTypeCd == '04'" class="field-option-area">
									<div class="field-option-top">
										Check Box Options
									</div>
									<div
										v-for="(optionList, jndex) in fieldList[index].checkOptionList"
										:key="jndex"
										class="input-options"
									>
										<div>
											<label :for="'checkboxLabel' + jndex">
												Label
											</label>
											<input
												type="text"
												:id="'checkboxLabel' + jndex"
												:name="'checkboxLabel' + jndex"
												class="input-crud options"
												:class="errors.has('checkboxLabel' + jndex + '.checkboxValid' + index) ? 'input-crud-error' : ''"
												v-model="optionList.checkboxOptionsLabel"
												v-validate="'required'"
												:data-vv-name="'checkboxValid' + index"
												:data-vv-scope="'checkboxLabel' + jndex"
												:placeholder="errors.has('checkboxLabel' + jndex) ? 'Please enter the label.' : 'label'"
											/>
										</div>
										<div>
											<label :for="'checkboxValue' + jndex">
												Value
											</label>
											<input
												type="text" 
												:name="index + 'checkboxValue' + jndex"
												class="input-crud options options-last"
												:class="errors.has(index + 'checkboxValue' + jndex) ? 'input-crud-error' : ''"
												v-model="optionList.checkboxOptionsValue"
												v-validate="getValidate(index)"
												:placeholder="errors.has(index + 'checkboxValue' + jndex) ? errors.first(index + 'checkboxValue' + jndex) : 'value'"
												data-vv-as="CheckBox Value"
												@input="(fieldList[index].dataTypeCd == '2' || fieldList[index].dataTypeCd == '8' ? optionList.checkboxOptionsValue = $event.target.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1') : (fieldList[index].dataTypeCd == '6' || fieldList[index].dataTypeCd == '9' ? optionList.checkboxOptionsValue = $event.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1') : ''))"
												:disabled="existFlag"
											/>
											<button
												v-if="jndex == 0"
												type="button"
												class="btn-add-option"
												title="New Options"
												@click.stop="checkAddRow(index)"
											>
												<img src="@/assets/images/common/btn_new.png"/>
											</button>
											<button
												v-if="jndex != 0"
												type="button"
												class="btn-remove-options"
												title="Remove Row"
												@click.stop="checkDeleteRow(index, jndex)"
											>
												<img src="@/assets/images/common/minus-sign.png" style="width: 10px; height: 10px;"/>
											</button>
										</div>
									</div>
								</div>
								<label v-if="field.fieldTypeCd == '05'">
									Convert Radio Cols
									<input
										type="number"
										:name="'radioCols' + index"
										class="input-crud"
										:class="errors.has('radioCols' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('radioCols' + index) ? '*' + errors.first('radioCols' + index) : '')"
										v-model="fieldList[index].radioCols"
										v-validate="'required'"
										data-vv-as="Convert Radio Cols"
										min="1"
										max="10"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '05'"></label>
								<div v-if="field.fieldTypeCd == '05'" class="field-option-area">
									<div class="field-option-top">
										Select Box Options
									</div>
									<div
										v-for="(optionList, jndex) in fieldList[index].selectOptionList"
										:key="jndex"
										class="input-options"
									>
										<div>
											<label :for="'selectboxLabel' + jndex">
												Label
											</label>
											<input
												type="text"
												:id="'selectboxLabel' + jndex"
												:name="'selectboxLabel' + jndex"
												class="input-crud options"
												:class="errors.has('selectboxLabel' + jndex + '.selectboxValid' + index) ? 'input-crud-error' : ''"
												v-model="optionList.selectOptionsLabel"
												v-validate="'required'"
												:data-vv-name="'selectboxValid' + index"
												:data-vv-scope="'selectboxLabel' + jndex"
												:placeholder="errors.has('selectboxLabel' + jndex + '.selectboxValid' + index) ? 'Please enter the label.' : 'label'"
											/>
										</div>
										<div>
											<label :for="'selectboxValue' + jndex">
												Value
											</label>
											<input
												type="text" 
												:name="index + 'selectboxValue' + jndex"
												class="input-crud options options-last"
												:class="errors.has(index + 'selectboxValue' + jndex) ? 'input-crud-error' : ''"
												v-model="optionList.selectOptionsValue"
												v-validate="getValidate(index)"
												:placeholder="errors.has(index + 'selectboxValue' + jndex) ? errors.first(index + 'selectboxValue' + jndex) : 'value'"
												data-vv-as="SelectBox Value"
												@input="(fieldList[index].dataTypeCd == '2' || fieldList[index].dataTypeCd == '8' ? optionList.selectOptionsValue = $event.target.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1') : (fieldList[index].dataTypeCd == '6' || fieldList[index].dataTypeCd == '9' ? optionList.selectOptionsValue = $event.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1') : ''))"
												:disabled="existFlag"
											/>
											<button
												v-if="jndex == 0"
												type="button"
												class="btn-add-option"
												title="New Options"
												@click.stop="selectAddRow(index)"
											>
												<img src="@/assets/images/common/btn_new.png" />
											</button>
											<button
												v-if="jndex != 0"
												type="button"
												class="btn-remove-options"
												title="Remove Row"
												@click.stop="selectDeleteRow(index, jndex)"
											>
												<img src="@/assets/images/common/minus-sign.png" style="width: 10px; height: 10px;"/>
											</button>
										</div>
									</div>
								</div>
								<div v-if="field.fieldTypeCd == '06'" class="expand">
									Image File
									<common-file
										:ref="'fileList' + index"
										:name="'fileList' + index"
										:class="errors.has('fileList' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].fileList"
										:fieldTypeCd = field.fieldTypeCd
										v-validate="'required'"
										data-vv-as="Image File"
										fileExtension="PNG, JPEG"
										fileMaxSize="MAX 100"
									/>
									<p class="txt-validation" v-if="errors.has('fileList' + index)" style="margin: 0;">
										* {{ errors.first("fileList" + index) }}
									</p>
								</div>
								<label v-if="field.fieldTypeCd == '06'">
									Image Width (Pixel)
									<input
										type="number"
										:name="'imageWidth' + index"
										class="input-crud"
										:class="errors.has('imageWidth' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('imageWidth' + index) ? '*' + errors.first('imageWidth' + index) : '')"
										v-model="fieldList[index].imageWidth"
										v-validate="'required'"
										data-vv-as="Image Width"
										min="1"
										max="1000"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '06'">
									Image Height (Pixel)
									<input
										type="number"
										:name="'imageHeight' + index"
										class="input-crud"
										:class="errors.has('imageHeight' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('imageHeight' + index) ? '*' + errors.first('imageHeight' + index) : '')"
										v-model="fieldList[index].imageHeight"
										v-validate="'required'"
										data-vv-as="Image Height"
										min="1"
										max="1000"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '07'">
									File Max Size
									<input
										type="number"
										:name="'fileMaxSize' + index"
										class="input-crud"
										:class="errors.has('fileMaxSize' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('fileMaxSize' + index) ? '*' + errors.first('fileMaxSize' + index) : '')"
										v-model="fieldList[index].fileMaxSize"
										v-validate="'required'"
										data-vv-as="File Max Size"
										min="1"
										max="1024"
									/>
								</label>
								<label v-if="field.fieldTypeCd == '07'">
									File Extension (Ex. jpeg;png;pdf)
									<input
										type="text"
										:name="'fileExtension' + index"
										class="input-crud"
										:class="errors.has('fileExtension' + index) ? 'input-crud-error' : ''"
										:placeholder="(errors.has('fileExtension' + index) ? '*' + errors.first('fileExtension' + index) : '')"
										v-model="fieldList[index].fileExtension"
										v-validate="'required'"
										data-vv-as="File Extension"
										:disabled="existFlag"
									/>
								</label>
								<div v-if="field.fieldTypeCd == '08'">
									Date Format
									<div v-if="field.dataTypeCd == '3' || field.dataTypeCd == '5' || field.dataTypeCd == '11' || field.dataTypeCd == '13'" class="date-format">
										<label>
											<input type="checkbox" v-model="fieldList[index].isYear" @change="changeDateFormat(index)" :disabled="existFlag"/>
											Year
										</label>
										<label>
											<input type="checkbox" v-model="fieldList[index].isMonth" @change="changeDateFormat(index)" :disabled="existFlag"/>
											Month
										</label>
										<label>
											<input type="checkbox" v-model="fieldList[index].isDay" @change="changeDateFormat(index)" :disabled="existFlag"/>
											Day
										</label>
										<label>
											<input type="text" v-model="fieldList[index].dateSeperator" maxlength="1" @input="changeDateFormat(index)" :disabled="existFlag"/>
											Separator
										</label>
									</div>
									<div v-if="field.dataTypeCd == '4' || field.dataTypeCd == '5' || field.dataTypeCd == '12' || field.dataTypeCd == '13'" class="date-format"> 
										<label>
											<input type="checkbox" v-model="fieldList[index].isHour" @change="changeDateFormat(index)"/>
											Hour
										</label>
										<label>
											<input type="checkbox" v-model="fieldList[index].isMinute" @change="changeDateFormat(index)"/>
											Minute
										</label>
										<label>
											<input type="checkbox" v-model="fieldList[index].isSecond" @change="changeDateFormat(index)"/>
											Second
										</label>
										<label>
											<input type="text" v-model="fieldList[index].timeSeperator" maxlength="1" @input="changeDateFormat(index)"/>
											Separator
										</label>
									</div>
								</div>
								<div v-if="field.fieldTypeCd == '10'" class="expand">
									Upload File
									<common-file
										:ref="'fileList' + index"
										:name="'fileList' + index"
										:class="errors.has('fileList' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].fileList"
										:fieldTypeCd = field.fieldTypeCd
										v-validate="'required'"
										data-vv-as="Upload File"
										fileExtension="All Extension"
										fileMaxSize="MAX 100"
									/>
									<p class="txt-validation" v-if="errors.has('fileList' + index)" style="margin: 0;">
										* {{ errors.first("fileList" + index) }}
									</p>
								</div>

								<div v-if="field.fieldTypeCd == '11'" class="expand">
									Upload File
									<common-file
										:ref="'fileList' + index"
										:name="'fileList' + index"
										:class="errors.has('fileList' + index) ? 'input-crud-error' : ''"
										v-model="fieldList[index].fileList"
										:fieldTypeCd = field.fieldTypeCd
										v-validate="'required'"
										data-vv-as="Upload File"
										fileExtension="MP4"
										fileMaxSize="MAX 500"
									/>
									<p class="txt-validation" v-if="errors.has('fileList' + index)" style="margin: 0;">
										* {{ errors.first("fileList" + index) }}
									</p>
								</div>
							</div>
						</div>
					</template>
					<!-- Preview Template -->
					<div
						v-if="preview.isPreview && preview.index == fieldList.length && preview.index != 0"
						class="field-preview"
					>
						<div class="field-preview-question" :class="'type_' + dragData.fieldData.comCode">
							<span>{{ dragData.fieldData.comCodeName }}</span>
						</div>
					</div>
				</div>
			</form>
			<div class="field-right">
				<div class="title">
					PREVIEW
				</div>
				<div class="preview scroll">
					<p v-if="item.questionFieldName" class="field">
						{{ item.questionFieldName }}
					</p>
					<p v-if="item.questionName" class="question">
						<span>ㆍ</span>
						{{ item.questionName }}
					</p>
					<div class="item-wrap no-drag">
						<div
							v-for="(field, index) in fieldList"
							:key="index"
							class="item"
						>
							{{ field.prefixName }}
							<div
								:class="field.fieldLayoutCd == '1' ? 'field-vtype' : 'field-htype'"
								:style="getMargin(field)"
								style="font-size: inherit; color: inherit;"
							>
								<!-- Text Start -->
								<template v-if="field.fieldTypeCd == '01'">
									<input
										type="text"
										:style="'width:' + (field.dataSize) + 'em;'"
										style="max-width: 100%;"
										disabled
									/>
								</template>
								<!-- Textarea Start -->
								<template v-if="field.fieldTypeCd == '02'">
									<textarea
										:rows="field.textareaRows"
										style="width: 100%;"
										disabled
									/>
								</template>
								<!-- Radio Start -->
								<template v-if="field.fieldTypeCd == '03'">
									<div
										class="grid"
										:style="getGridRowCol(field.radioOptionList, field.radioCols)"
									>
										<label
											v-for="(option, radioIndex) in field.radioOptionList"
											:key="radioIndex"
											class="radio-crud"
										>
											<input
												type="radio"
												:value="option"
												disabled
											/><span class="radio"></span>
											{{ option.radioOptionsLabel }}
										</label>
									</div>
								</template>
								<!-- Checkbox Start -->
								<template v-if="field.fieldTypeCd == '04'">
									<div
										class="grid"
										:style="getGridRowCol(field.checkOptionList, field.checkboxCols)"
									>
										<label
											v-for="(option, checkboxIndex) in field.checkOptionList"
											:key="checkboxIndex"
											class="checkbox-crud"
										>
											<input
												type="checkbox"
												:value="option"
												disabled
											/><span class="checkbox"></span>
											{{ option.checkboxOptionsLabel }}
										</label>
									</div>
								</template>
								<!-- Select Start -->
								<template v-if="field.fieldTypeCd == '05'">
									<div
										class="select-grid"
										:style="getGridRowCol(field.selectOptionList, 1)"
									>
										<select
											style="max-width: 100%;"
											disabled
										>
										</select>
										<p
											v-for="(option, selectIndex) in field.selectOptionList"
											:key="selectIndex"
										>
											{{ option.selectOptionsLabel }}
										</p>
									</div>
								</template>
								<!-- Image Start -->
								<template v-if="field.fieldTypeCd == '06'">
									<div
										:class="field.fileList.length == 0 ? 'img-wrap-no' : 'img-wrap'"
										:style="'width: ' + (field.imageWidth) + 'px; height: ' + (field.imageHeight) + 'px;'"
									>
										<template v-if="field.fileList.length > 0">
											<img
												v-if="
													field.fileList[0].fileExtensionName == 'png' ||
													field.fileList[0].fileExtensionName == 'jpeg' ||
													field.fileList[0].fileExtensionName == 'jpg'
												"
												:src="field.fileList[0].imgUrl"
												style="width: 100%; height: 100%;"
											/>
										</template>
										<template v-if="field.fileList.length == 0">
											<span>Image</span>
										</template>
									</div>
								</template>
								<!-- File Start -->
								<template v-if="field.fieldTypeCd == '07'">
									<common-file
										:fileExtension="field.fileExtension"
										:fileMaxSize="field.fileMaxSize"
									/>
									<!-- <p class="print-file" style="font-size: inherit;">→ Please attach files separately</p> -->
								</template>
								<!-- Date Start -->
								<template v-if="field.fieldTypeCd == '08'">
									<input
										type="text"
										:placeholder="field.dateFormat"
										style="width: 100%; padding: 0 10px; text-align: center; background-color: #fff;"
										disabled
									/>
								</template>
								<!-- FileDownload Start -->
								<template v-if="field.fieldTypeCd == '10'">
									<common-file
										:ref="'fileList' + index"
										v-model="fieldList[index].fileList"
										:fieldTypeCd = field.fieldTypeCd
										:downloadOnly="true"
									/>
								</template>
								<!-- videoUpload Start -->
								<template v-if="field.fieldTypeCd == '11'">
									<common-file
										:ref="'fileList' + index"
										v-model="fieldList[index].fileList"
										:fieldTypeCd = field.fieldTypeCd
										:downloadOnly="true"
									/>
								</template>
							</div>
							{{ field.suffixName }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="full-bottom-wrap">
			<button v-if="workAccessCheck('EDIT')" type="button" class="btn-save fright" title="Modify" @click.stop="doValidation()">
				Modify
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" style="margin-right: 10px;" @click.stop="doCancel()">
				Cancel
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";

export default {
	name: "FieldEdit",
	created() {
		this.logger.debug(this, "created()");
		this.doSelect();
		this.selectComCodeList();
		this.getAccessList('FIELD');
		this.selectExistFlag();
	},
	components: {
		CommonFile
	},
	data() {
		return {
			item: {},
			fieldTypeList: [],
			fieldLayoutList: [],
			fieldCharTypeLsit: [],
			fieldDataTypeList: [],
			fieldTimeTypeList: [],
			fieldCalcTypeList: [],
			fieldList: [],
			dragData: {
				isDrag: false,
				position: {
					top: "",
					left: ""
				},
				mouseX: "",
				offsetY: "",
				fieldData: {}
			},
			preview: {
				isPreview: false,
				index: -1
			},
			isTyping: false,
			fieldGroupName: {},
			selectOptionList: [
				{
					selectOptionsLabel:"",
					selectOptionsValue:""
				}
			],
			checkOptionList: [
				{
					checboxkOptionsLabel:"",
					checkboxOptionsValue:""
				}
			],
			radioOptionList: [	
				{
					radioOptionsLabel:"",
					radioOptionsValue:""
				}
			],
			defaultValueList: [
				{
					defaultValue:""
				}
			],
			duplicatedFlag: false,
			nameList: [],
			initNameList: [],
			accessList: [],
			existFlag: "",
		};
	},
	computed: {
		usedFieldType: function() {
			let usedFieldType = [];
			if(this.fieldList.length > 0)
			{
				usedFieldType = this.fieldList.map(
					(item) => {
						return item.fieldTypeCd;
					}
				);
			}
			return usedFieldType;
		}
	},
	methods: {
		doSelect() {
			this.logger.debug(this, "doSelect()");
			axios
				.post(this.config.contextRoot + "/field/select.do", {questionNo: this.$route.params.questionNo})
				.then((response) => {
					this.item = response.data.item;
					this.fieldList = response.data.item.fieldList;
					for(let field of this.fieldList)
					{
						this.initNameList.push({fieldNo : field.fieldNo, fieldChildName: field.fieldChildName});
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},  
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["FIELD_TYPE_CD", "FIELD_LAYOUT_CD", "FIELD_CHART_TYPE_CD", "DATA_TYPE_CD"]})
				.then((response) => {
					let codeList = response.data.codeList;
					for (let index = 0 ; index < codeList.length ; index++)
					{
						if(codeList[index].classCode == "FIELD_TYPE_CD")
						{
							this.fieldTypeList.push(codeList[index]);
						}
						if(codeList[index].classCode == "FIELD_LAYOUT_CD")
						{
							this.fieldLayoutList.push(codeList[index]);
						}
						if(codeList[index].classCode == "FIELD_CHART_TYPE_CD")
						{
							this.fieldCharTypeLsit.push(codeList[index]);
						}
						if(codeList[index].classCode == "DATA_TYPE_CD")
						{
							if(codeList[index].comCode == '2' || codeList[index].comCode == '6' || codeList[index].comCode == '3' ||  codeList[index].comCode == '4' || codeList[index].comCode == '5' || codeList[index].comCode == '8' || codeList[index].comCode == '9' || codeList[index].comCode == '11' || codeList[index].comCode == '12' || codeList[index].comCode == '13')
							{
								this.fieldCalcTypeList.push(codeList[index]);
							}
							if(codeList[index].comCode == '1' || codeList[index].comCode == '2' || codeList[index].comCode == '6' ||  codeList[index].comCode == '7' || codeList[index].comCode == '8' || codeList[index].comCode == '9')
							{
								this.fieldDataTypeList.push(codeList[index]);
							}
							else if(codeList[index].comCode == '3' || codeList[index].comCode == '4' || codeList[index].comCode == '5' || codeList[index].comCode == '11' || codeList[index].comCode == '12' || codeList[index].comCode == '13')
							{
								this.fieldTimeTypeList.push(codeList[index]);
							}
						}
					}
					this.selectFieldGroup();

					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("field-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldGroup() {
			this.logger.debug(this, "selectFieldGroup()");

			axios
				.post(this.config.contextRoot + "/field/selectFieldGroup.do", {questionGroupNo: this.$route.params.questionGroupNo})
				.then((response) => {
					console.log(response);

					this.fieldGroupName = response.data.fieldGroupName;
				})
		},
		getFieldTypeClass(item) {
			return 'type_' + item.comCode + " " + (this.usedFieldType.indexOf(item.comCode) >= 0 ? 'used-category' : '');
		},
		dragstart(event, item) {
			this.logger.debug(this, "dragstart(event, item)");
			var positionX = event.target.getBoundingClientRect().left + window.pageXOffset;
			var positionY = event.target.getBoundingClientRect().top + window.pageYOffset;

			this.dragData.position.top = positionY + "px";
			this.dragData.position.left = positionX + "px";
			this.dragData.position.width = event.target.offsetWidth + "px";

			this.dragData.offsetX = event.offsetX;
			this.dragData.offsetY = event.offsetY;

			this.dragData.fieldData = item;
			this.dragData.isDrag = true;
		},
		dragging(event) {
			if (this.dragData.isDrag) {
				this.dragData.position.top = event.clientY - this.dragData.offsetY + "px";
				this.dragData.position.left = event.clientX - this.dragData.offsetX + "px";
			}
		},
		draggend() {
			this.logger.debug(this, "draggend()");
			if(this.fieldList.length == 1)
			{
				this.$alert("You can't insert more than one field.", "Warn", "warning");
			}
			else if(this.preview.isPreview)
			{
				let addField = {
					fieldNo: "new",
					questionNo: this.item.questionNo,
					fieldTypeCd: this.dragData.fieldData.comCode,
					fieldTypeName: this.dragData.fieldData.comCodeName,
					dataTypeCd: "1",
					fieldLayoutCd: "2",
					isRequired: "N",
					fieldChartTypeCd: "1",
					fieldChildSortOrder: this.preview.index,
					dataSize: "1",
					decimalDigit: undefined
				};
				if(this.dragData.fieldData.comCode == "02")
				{
					addField.textareaCols = "1";
					addField.textareaRows = "1";
				}
				else if(this.dragData.fieldData.comCode == "03")
				{
					addField.radioCols = "1";

					addField.radioOptionList = [
						{
						radioOptionsValue: "",
						radioOptionsLabel: "",
					}];
				}
				else if(this.dragData.fieldData.comCode == "04")
				{
					addField.checkboxCols = "1";
										
					addField.checkOptionList = [
						{
						checkboxOptionsValue: "",
						checkboxOptionsLabel: "",
					}];
				}
				else if(this.dragData.fieldData.comCode == "05")
				{
					addField.radioCols = "1";

					addField.selectOptionList = [
						{
						selectOptionsValue: "" ,
						selectOptionsLabel: "" ,
					}];
				}
				else if(this.dragData.fieldData.comCode == "06")
				{
					addField.fileList = [];
					addField.imageWidth = "100";
					addField.imageHeight = "100";
					addField.dataTypeCd = "10";
				}
				else if(this.dragData.fieldData.comCode == "07")
				{
					addField.fileMaxSize = "";
					addField.fileExtension = "";
					addField.dataTypeCd = "10";
				}
				else if(this.dragData.fieldData.comCode == "08")
				{
					addField.dataTypeCd = "3";
					addField.isYear = true;
					addField.isMonth = true;
					addField.isDay = true;
					addField.dateSeperator = "-";
					addField.dateFormat = "YYYY-MM-DD";
					addField.dataSize = "10";
					addField.isAllowUk = "N";
				}
				else if(this.dragData.fieldData.comCode == "09")
				{
					addField.dataTypeCd = "2";
				}
				else if(this.dragData.fieldData.comCode == "11")
				{
					addField.dataTypeCd = "10";
				}
				else if(this.dragData.fieldData.comCode == "12")
				{
					addField.defaultValueList = [
						{
						defaultValue: "",
					}];
				}
				this.fieldList.splice(Number(this.preview.index), 0, addField);
				this.resetSortOrder();
			}
			this.dragData = {
				isDrag: false,
				position: {
					top: "",
					left: ""
				},
				mouseX: "",
				offsetY: "",
				fieldData: {}
			};
			this.preview = {
				isPreview: false,
				index: -1
			};
		},
		typeClick(item){
			if(this.fieldList.length == 1)
			{
				this.$alert("You can't insert more than one field.", "Warn", "warning");
			}
			else
			{
				this.dragData.fieldData = item;
				let addField = {
					fieldNo: "new",
					questionNo: this.item.questionNo,
					fieldTypeCd: this.dragData.fieldData.comCode,
					fieldTypeName: this.dragData.fieldData.comCodeName,
					dataTypeCd: "1",
					fieldLayoutCd: "2",
					isRequired: "N",
					fieldChartTypeCd: "1",
					fieldChildSortOrder: this.fieldList.length,
					dataSize: "1",
					decimalDigit: undefined
				};
				if(this.dragData.fieldData.comCode == "02")
				{
					addField.textareaCols = "1";
					addField.textareaRows = "1";
				}
				else if(this.dragData.fieldData.comCode == "03")
				{
					addField.radioCols = "1";

					addField.radioOptionList = [
						{
						radioOptionsValue: "",
						radioOptionsLabel: "",
					}];
				}
				else if(this.dragData.fieldData.comCode == "04")
				{
					addField.checkboxCols = "1";
										
					addField.checkOptionList = [
						{
						checkboxOptionsValue: "",
						checkboxOptionsLabel: "",
					}];
				}
				else if(this.dragData.fieldData.comCode == "05")
				{
					addField.radioCols = "1";

					addField.selectOptionList = [
						{
						selectOptionsValue: "" ,
						selectOptionsLabel: "" ,
					}];
				}
				else if(this.dragData.fieldData.comCode == "06")
				{
					addField.fileList = [];
					addField.imageWidth = "100";
					addField.imageHeight = "100";
					addField.dataTypeCd = "10";
				}
				else if(this.dragData.fieldData.comCode == "07")
				{
					addField.fileMaxSize = "";
					addField.fileExtension = "";
					addField.dataTypeCd = "10";
				}
				else if(this.dragData.fieldData.comCode == "08")
				{
					addField.dataTypeCd = "3";
					addField.isYear = true;
					addField.isMonth = true;
					addField.isDay = true;
					addField.dateSeperator = "-";
					addField.dateFormat = "YYYY-MM-DD";
					addField.dataSize = "10";
					addField.isAllowUk = "N";
				}
				else if(this.dragData.fieldData.comCode == "09")
				{
					addField.dataTypeCd = "2";
				}
				else if(this.dragData.fieldData.comCode == "11")
				{
					addField.dataTypeCd = "10";
				}
				else if(this.dragData.fieldData.comCode == "12")
				{
					addField.defaultValueList = [
						{
						defaultValue: "",
					}];
				}
				this.fieldList.splice(Number(this.fieldList.length), 0, addField);
				this.resetSortOrder();
			}
			this.dragData = {
				isDrag: false,
				position: {
					top: "",
					left: ""
				},
				mouseX: "",
				offsetY: "",
				fieldData: {}
			};
			this.preview = {
				isPreview: false,
				index: -1
			};
		},
		showPreview(event) {
			var targetClass = event.target.className;
			if(targetClass.indexOf("field-contents-container") >= 0)
			{
				if(this.fieldList.length == 0)
				{
					this.preview = {
						isPreview: true,
						index: 0
					};
				}
				else
				{
					if(!this.preview.isPreview)
					{
						let childrenList = event.target.children;
						console.log(childrenList);
						let cursorY = event.offsetY + event.target.scrollTop;
						console.log(cursorY);
						
						for(let index = 1 ; index < childrenList.length ; index++)
						{
							let childrenY = childrenList[index].offsetTop;
							console.log("dd : " + cursorY);
							if(cursorY <=  childrenY)
							{
								this.preview = {
									isPreview: true,
									index: index - 1
								};
								break;
							}
							else
							{
								if(index == (childrenList.length - 1) && cursorY > childrenY)
								{
									this.preview = {
										isPreview: true,
										index: index
									};
								}
							}
						}
					}
				}
			}
			else if(targetClass.indexOf("field-wrap") >= 0)
			{
				this.preview = {
					isPreview: false,
					index: -1
				};
			}
			return ;
		},
		hidePreview() {
			this.logger.debug(this, "hidePreview()");
			if (this.preview.isPreview == true)
			{
				this.preview = {
					isPreview: false,
					index: -1
				};
			}
		},
		resetSortOrder() {
			for(let i = 0 ; i < this.fieldList.length ; i++)
			{
				this.fieldList[i].fieldChildSortOrder = i;
			}
		},
		removeFieldItem(index, fieldNo) {
			if(!this.existFlag){
				this.logger.debug(this, "removeFieldItem(index)");
				this.item.fieldNo = fieldNo;
				axios
					.post(this.config.contextRoot + "/field/possibleDeleteFlag.do", this.item)
					.then((response) => {
						if (response.data.deleteFlag)
						{
							this.$alert("The referenced item exists.", "Warn", "warning");
							return;
						}
						else
						{
							this.fieldList.splice(index, 1);
							this.item.questionName = "";
							this.resetSortOrder();
						}
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					})
			}
			else{
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
		},
		infoBalloon(event) {
			this.logger.debug(this, "infoBalloon(event)");
			if(event.type == "focus")
			{
				this.isTyping = true;
			}
			else
			{
				this.$refs.typingBalloon.style.animation = "hideBalloon 0.3s ease-out both";
				setTimeout(function() {
					this.$refs.typingBalloon.style.animation = "";
					this.isTyping = false;
				}.bind(this), 310);
			}
		},
		getMargin(field) {
			var prefixName = field.prefixName;
			var suffixName = field.suffixName;
			var fieldLayoutCd = field.fieldLayoutCd;

			var topMargin = 0;
			var bottomMargin = 0;
			var leftMargin = 0;
			var rightMargin = 0;

			if (fieldLayoutCd == "1")
			{
				if (prefixName != null) topMargin = 3;
				if (suffixName != null) bottomMargin = 3;
			}
			else
			{
				if (prefixName != null) leftMargin = 5;
				if (suffixName != null) rightMargin = 5;
			}

			return {
				marginTop: topMargin + "px",
				marginBottom: bottomMargin + "px",
				marginLeft: leftMargin + "px",
				marginRight: rightMargin + "px",
			};
		},
		getGridRowCol(length, cols) {
			var value = parseInt(Number(length) / Number(cols));
			var etc = Number(length) % Number(cols);

			var row = 0;
			var col = cols;
			if (etc == 0) row = value;
			else row = value + 1;

			return {
				gridTemplateRows: "repeat(" + row + ", minmax(20px, auto))",
				gridTemplateColumns: "repeat(" + col + ", auto)",
			};
		},
		selectExistFlag(){
			var items = {questionNo : this.$route.params.questionNo};
			axios
				.post(this.config.contextRoot + "/field/deleteFieldChildFlag.do", items)
				.then((response) => {
					console.log(response);
					this.existFlag = response.data.flag;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			console.log(this.item);
			if(this.duplicatedFlag)
			{
				this.$alert("Field Name is duplicate.", "Warn", "warning");
				return;
			}
			if(this.item.questionName.length > 500)
			{
				this.$alert("Field Label may not be longer than 500 characters.", "Error", "error");
				this.item.questionName = "";
			}

			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					if (result)
					{
						
						//Field가 최소 하나 이상인지 검사
						if (this.fieldList.length < 1)
						{
							this.$alert("At least one item is required to create a field.", "Warn", "warning");
							return false;
						}
						else
						{
							this.$confirm("Do you want to register the current field?", "Question", "question").then(() => {
								EventBus.$emit("field-loading", true);
								this.doUpdate();
							});
						}
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			this.item.fieldList = this.fieldList;
			axios
				.post(this.config.contextRoot + "/field/update.do", this.item)
				.then((response) => {
					if (response.status == 200)
					{
						var url = this.config.contextRoot + "/field";
						if (this.$route.path !== url)
						{
							EventBus.$emit("page-move", this.$route.query.current_page);
							this.$router.push(url);
						}
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the field modification?", "Question", "question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.fieldEdit.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(function() {
				let url = this.config.contextRoot + "/field";
				if (this.$route.path !== url)
				{
					EventBus.$emit("field-loading", true);
					this.$router.push(url);
				}
			}.bind(this), 410);
		},
		selectAddRow(index) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "selectAddRow()");
	
				let addRow = {
					selectOptionsValue: "",
					selectOptionsLabel: "",
				};
				this.fieldList[index].selectOptionList.push(addRow);
			}
		},
		checkAddRow(index) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "checkAddRow()");
	
				let addRow = {
					checkboxOptionsValue: "",
					checkboxOptionsLabel: "",
				};
				this.fieldList[index].checkOptionList.push(addRow);
			}
		},
		defaultAddRow(index) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "defaultAddRow()");
	
				let addRow = {
					defaultValue: "",
				};
				this.fieldList[index].defaultValueList.push(addRow);
			}
		},
		defaultDeleteRow(index, jndex) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "defaultDeleteRow()");
				this.fieldList[index].defaultValueList.splice(jndex, 1);
			}
		},
		radioAddRow(index) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "radioAddRow()");
				
				let addRow = {
					radioOptionsValue: "",
					radioOptionsLabel: "",
				};
				this.fieldList[index].radioOptionList.push(addRow);
			}
			
		},
		radioDeleteRow(index, jndex) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "radioDeleteRow()");
				this.fieldList[index].radioOptionList.splice(jndex, 1);
			}
		},
		checkDeleteRow(index, jndex) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "checkDeleteRow()");
				this.fieldList[index].checkOptionList.splice(jndex, 1);
			}
		},
		selectDeleteRow(index, jndex) {
			if(this.existFlag){
				this.$alert("The referenced item exists.", "Warn", "warning");
			}
			else{
				this.logger.debug(this, "selectDeleteRow()");
				this.fieldList[index].selectOptionList.splice(jndex, 1);
			}
		},
		fieldChildNameDuplicatedCheck() {
			this.duplicatedFlag = false;
			this.nameList = [];
			for(let fieldChildName of document.getElementsByClassName('fieldChildName'))
			{
				this.nameList.push(fieldChildName.value);
			}
			axios
				.post(this.config.contextRoot + '/field/selectDuplicatedCheck.do', {nameList:this.nameList})
				.then((response) => {
					this.nameList = response.data.checkList;
					for(let fieldChildName of document.getElementsByClassName('fieldChildName'))
					{
						var duplicateName = this.nameList.filter((element) => {
							return element == fieldChildName.value;
						})
						if(duplicateName.length > 1 && fieldChildName.value != '' )
						{
							var flag = false;
							for(let index = 0; this.initNameList.length > index; index++)
							{
								if(this.initNameList[index].fieldChildName == fieldChildName.value)
								{
									flag = true;
								}
							}
							if(flag) {
								fieldChildName.classList.remove('input-crud-error');
								document.getElementById("fieldName").style.height = "52px";
							}
							else {
								this.duplicatedFlag = true
								fieldChildName.classList.add('input-crud-error');
								document.getElementById("fieldName").style.height = "76px";
							}
						}
						else if(duplicateName.length < 2 || fieldChildName.value != '')
						{	
							fieldChildName.classList.remove('input-crud-error');
							document.getElementById("fieldName").style.height = "52px";
						}

						if(fieldChildName.value == '')
						{
							fieldChildName.classList.add('input-crud-error');
							document.getElementById("fieldName").style.height = "52px";
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				})
		},
		openPopup() {
			this.logger.debug(this, "openPopup()");
			var no = this.$route.params.questionGroupNo;

			let routeData = this.$router.resolve({
				name: 'test',
				params:{questionGroupNo: no},
			});

			localStorage.previewData = JSON.stringify({
				fieldList: this.fieldList,
				questionName: this.item.questionName,
				questionFieldName: this.item.questionFieldName
			});

			let popupX = (window.screen.width / 2) - (460 / 2);
			popupX+= window.screenLeft;
			let popupY = (window.screen.height / 2) - (620 / 2);
			window.open(routeData.href,'test','_black, width=460, height=620, left=' + popupX + ', top=' + popupY);
		},
		setDateFormat(index, type)
		{
			if(Number(type) == 3 || Number(type) == 11)
			{
				this.$set(this.fieldList[index], "isYear", true);
				this.$set(this.fieldList[index], "isMonth", true);
				this.$set(this.fieldList[index], "isDay", true);
				this.$set(this.fieldList[index], "isHour", false);
				this.$set(this.fieldList[index], "isMinute", false);
				this.$set(this.fieldList[index], "isSecond", false);
				this.$set(this.fieldList[index], "dateSeperator", "-");
				this.$set(this.fieldList[index], "timeSeperator", "");
				this.$set(this.fieldList[index], "dateFormat", "YYYY-MM-DD");
				this.fieldList[index].dataSize = "10";
			}
			else if(Number(type) == 4 || Number(type) == 12)
			{
				this.$set(this.fieldList[index], "isYear", false);
				this.$set(this.fieldList[index], "isMonth", false);
				this.$set(this.fieldList[index], "isDay", false);
				this.$set(this.fieldList[index], "isHour", true);
				this.$set(this.fieldList[index], "isMinute", true);
				this.$set(this.fieldList[index], "isSecond", true);
				this.$set(this.fieldList[index], "dateSeperator", "");
				this.$set(this.fieldList[index], "timeSeperator", ":");
				this.$set(this.fieldList[index], "dateFormat", "hh:mm:ss");
				this.fieldList[index].dataSize = "8";
			}
			else if(Number(type) == 5 || Number(type) == 13)
			{
				this.$set(this.fieldList[index], "isYear", true);
				this.$set(this.fieldList[index], "isMonth", true);
				this.$set(this.fieldList[index], "isDay", true);
				this.$set(this.fieldList[index], "isHour", true);
				this.$set(this.fieldList[index], "isMinute", true);
				this.$set(this.fieldList[index], "isSecond", true);
				this.$set(this.fieldList[index], "dateSeperator", "-");
				this.$set(this.fieldList[index], "timeSeperator", ":");
				this.$set(this.fieldList[index], "dateFormat", "YYYY-MM-DD hh:mm:ss");
				this.fieldList[index].dataSize = "19";
			}
		},
		changeDateFormat(index)
		{
			let dataTypeCd = Number(this.fieldList[index].dataTypeCd);
			let dateLength = (dataTypeCd == 3 || dataTypeCd == 4 || dataTypeCd == 11 || dataTypeCd == 12 ? 3 : 6);
			let dateFormatArray = [];
			let dateSeperator = (this.fieldList[index].dateSeperator == '' ? ' ' : this.fieldList[index].dateSeperator);
			let timeSeperator = (this.fieldList[index].timeSeperator == '' ? ' ' : this.fieldList[index].timeSeperator);

			// validaiton
			if(
				([3, 5, 11, 13].includes(dataTypeCd) && !this.fieldList[index].isYear && !this.fieldList[index].isMonth && !this.fieldList[index].isDay) ||
				([4, 5, 12, 13].includes(dataTypeCd) && !this.fieldList[index].isHour && !this.fieldList[index].isMinute && !this.fieldList[index].isSecond)
			)
			{
				this.$alert("At least one item is required to create a date field.", "Warn", "warning").then(() => {
					this.setDateFormat(index, dataTypeCd);
				});
				return;
			}

			let dataSize = 0;

			for(let i = 0 ; i < dateLength ; i++)
			{
				// 연도, 시
				if(i == 0 && [3, 5, 11, 13].includes(dataTypeCd) && this.fieldList[index].isYear)
				{
					dataSize += 4

					if(dateSeperator == ' ') {
						dateFormatArray.push('YYYY년');
						dataSize += 2
					}
					else dateFormatArray.push('YYYY');
				}
				else if(i == 0 && (dataTypeCd == 4 || dataTypeCd == 12) && this.fieldList[index].isHour)
				{
					dataSize += 2

					if(timeSeperator == ' ') {
						dateFormatArray.push('hh시');
						dataSize += 2
					}
					else dateFormatArray.push('hh');
				}
				// 월, 분
				if(i == 1 && [3, 5, 11, 13].includes(dataTypeCd) && this.fieldList[index].isMonth)
				{
					dataSize += 2

					if(dateFormatArray.some(i => ['YYYY', 'YYYY년'].includes(i))) {
						dateFormatArray.push(dateSeperator);
						dataSize += 1
					}

					if(dateSeperator == ' ') {
						dateFormatArray.push('MM월');
						dataSize += 2
					}
					else dateFormatArray.push('MM');
				}
				else if(i == 1 && (dataTypeCd == 4 || dataTypeCd == 12) && this.fieldList[index].isMinute)
				{
					dataSize += 2

					if(dateFormatArray.some(i => ['hh', 'hh시'].includes(i))) {
						dateFormatArray.push(timeSeperator);
						dataSize += 1
					}

					if(timeSeperator == ' ') {
						dateFormatArray.push('mm분');
						dataSize += 2
					}
					else dateFormatArray.push('mm');
				}
				// 일, 초
				if(i == 2 && [3, 5, 11, 13].includes(dataTypeCd) && this.fieldList[index].isDay)
				{
					dataSize += 2

					if(dateFormatArray.some(i => ['YYYY', 'YYYY년', 'MM', 'MM월'].includes(i))) {
						dateFormatArray.push(dateSeperator);
						dataSize += 1
					}
					if(dateSeperator == ' ') {
						dateFormatArray.push('DD일');
						dataSize += 2
					}
					else dateFormatArray.push('DD');
				}
				else if(i == 2 && (dataTypeCd == 4 || dataTypeCd == 12) && this.fieldList[index].isSecond)
				{
					dataSize += 2

					if(dateFormatArray.some(i => ['hh', 'hh시', 'mm', 'mm분'].includes(i))) {
						dateFormatArray.push(timeSeperator);
						dataSize += 1
					}

					if(timeSeperator == ' ') {
						dateFormatArray.push('ss초');
						dataSize += 2
					}
					else dateFormatArray.push('ss');
				}
				// Date Time 인 경우
				if(i > 2 && dateLength == 6)
				{
					if(i == 3 && this.fieldList[index].isHour)
					{
						dataSize += 2

						if(dateFormatArray.some(i => ['YYYY', 'MM', 'DD', 'YYYY년', 'MM월', 'DD일'].includes(i))) {
							dateFormatArray.push(' ');
							dataSize += 1
						}
						if(timeSeperator == ' ') {
							dateFormatArray.push('hh시');
							dataSize += 2
						}
						else dateFormatArray.push('hh');
					}
					if(i == 4 && this.fieldList[index].isMinute)
					{
						dataSize += 2

						if(dateFormatArray.some(i => ['hh', 'hh시'].includes(i))) {
							dateFormatArray.push(timeSeperator);
							dataSize += 1
						}
						if(timeSeperator == ' ') {
							dateFormatArray.push('mm분');
							dataSize += 2
						}
						else dateFormatArray.push('mm');
					}
					if(i == 5 && this.fieldList[index].isSecond)
					{
						dataSize += 2

						if(dateFormatArray.some(i => ['hh', 'hh시', 'mm', 'mm분'].includes(i))) {
							dateFormatArray.push(timeSeperator);
							dataSize += 1
						}
						
						if(timeSeperator == ' ') {
							dateFormatArray.push('ss초');
							dataSize += 2
						}
						else dateFormatArray.push('ss');
					}
				}
			}

			let dateFormat = "";
			for(let i = 0 ; i < dateFormatArray.length ; i++)
			{
				dateFormat = dateFormat + dateFormatArray[i];
			}
			this.$set(this.fieldList[index], "dateFormat", dateFormat);
			this.fieldList[index].dataSize = dataSize.toString();
		},
		getValidate(index) {
			let dataTypeCd = this.fieldList[index].dataTypeCd;
			let dataSize = this.fieldList[index].dataSize;
			let decimal = this.fieldList[index].decimalDigit;
			
			if(dataTypeCd == '1' || dataTypeCd == '7')
			{
				let number = Number(dataSize);
				if(number < 1 || number === undefined || number == null)
				{
					return "required|max:0";
				}
				else{
					return "required|max:" + number;
				}
			}
			else if(dataTypeCd == '2' || dataTypeCd == '8')
			{
				let number = Number(dataSize);
				if(number < 1 || number === undefined || number == null)
				{
					return "required|max_value:0|min_value:0";
				}
				else
				{
					let maxValue = "";
					for(let i = 0 ; i < number ; i++)
					{
						maxValue += "9";
					}
					return "required|max_value:" + maxValue + "|min_value:-" + Number(maxValue);
				}
			}
			else if(dataTypeCd == '6' || dataTypeCd == '9')
			{
				if(decimal == "" || decimal === undefined || decimal == null)
				{
					return "required|decimal:0|max_value:0|min_value:0";
				}
				else
				{
					let sep = decimal.split(".");
					if(sep.length == 1)
					{
						let num = Number(sep[0]);
						let maxValue = "";
						for(let i = 0 ; i < num ; i++)
						{
							maxValue += "9";
						}

						return "required|decimal:0|max_value:" + maxValue + "|min_value:-" + maxValue;
					}
					else if(sep.length == 2)
					{
						let num = Number(sep[0]);
						let digit = Number(sep[1]);
						let maxValue = "";
						for(let i = 0 ; i < num ; i++)
						{
							maxValue += "9";
						}
						maxValue += ".";
						for(let i = 0 ; i < digit ; i++)
						{
							maxValue += "9";
						}

						return "required|decimal:" + digit + "|max_value:" + maxValue + "|min_value:-" + maxValue;
					}
					else
					{
						return "required|decimal:0|max_value:0|min_value:0";
					}
				}
			}
		},
		calcDataSize(index) {
			let decimal = this.fieldList[index].decimalDigit;
			if(decimal == "" || decimal === undefined || decimal == null)
			{
				this.$set(this.fieldList[index], 'dataSize', "0");
			}
			else
			{
				let sep = decimal.split(".");
				if(sep.length == 1)
				{
					this.$set(this.fieldList[index], 'dataSize', sep[0] + "");
					
				}
				else if(sep.length != 2)
				{
					this.$set(this.fieldList[index], 'dataSize', "0");
				}
				else
				{
					let dataSize = Number(sep[0]) + Number(sep[1]);
					this.$set(this.fieldList[index], 'dataSize', dataSize + "");
				}
			}
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		}
	}
};
</script>

<style scoped>
.input-options {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 40px;
	margin: 10px 0;
}

.input-options > div {
	position: relative;
}

.options {
	margin-top: 3px;
	border-radius: 5px;
}

.options-last {
	width: calc(100% - 31px);
}

.btn-origin-size {
	display: inline-flex;
	position: absolute;
	right: 15px;
	padding: 3px 10px;
	border: 1px solid #999;
	border-radius: 5px;
	background-color: #fff;
	font-weight: 600;
	color: #999;
}

.btn-origin-size:hover {
	border: 1px solid #2bade0;
	color: #2bade0;
}

.date-format {
	display:flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 32px;
}

.date-format > label {
	min-width: 20%;
	margin: 0 10px 0 0;
	font-weight: 400;
	font-size: 10pt;
	color: #999;
}

.date-format > label:last-child {
	margin: 0;
}

.date-format > label > input[type=checkbox] {
	margin: 0 5px 0 0;
}

.date-format > label > input[type=text] {
	width: 30px;
	height: 18px;
	margin: 0 5px 0 0;
	font-weight: 400;
	font-size: 10pt;
	text-align: center;
}

.input-crud-error-height{
	height: 96px !important;
}
</style>