<template>
	<div ref="RequestEdit" class="dialog-container">
        <div class="dialog" style="max-height: 650px; transform: translate(0, -350px); max-width: 1000px;">
			<div class="top">
				<p class="txt-crud-title">
					Monitoring Request
				</p>
			</div>
			<p class="dynamic-warn">*Add fields to query request list</p>
			<div class="center">
				<table class="tb-list-vertical">
                        <colgroup>
                            <col width="3%" />
                            <!-- <col width="10%" /> -->
                            <col width="20%" />
                            <col width="40%" />
                            <col width="auto" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
									<label>
										<input type="checkbox" name="checkAll" @click="checkAll($event)"/>
										<span class="checkbox"></span>
									</label>
                                </th>
                                <!-- <th>
                                    Cell Name
                                </th> -->
                                <th>
                                    Procedure
                                </th>
                                <th>
                                    Question
                                </th>
                                <th>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
							<tr v-for="(field, index) in procFieldList" :key="index">
								<td>
									<label>
										<input
											type="checkbox"
											name="fieldNo"
											v-model="checkedList"
											:value="field.procFieldNo + '_' + field.repeatSortOrder"
											:checked="checkedList.indexOf(field.procFieldNo + '_' + field.repeatSortOrder) >= 0 ? true : false"
											@click="check(field.procFieldNo, field.repeatSortOrder)"
										/>
										<span class="checkbox"></span>
									</label>
								</td>
								<!-- <td>
									{{ field.cellName }}
								</td> -->
								<td>
									{{ field.procName }}
								</td>
								<td>
									{{ field.questionName + "(" + field.questionFieldName + ")" }}
								</td>
								<td v-if="field.fieldTypeCd != '03' && field.fieldTypeCd != '04' && field.fieldTypeCd != '05'">
									{{ field.fieldValue == '' || field.fieldValue== '[]' ? '-' : field.fieldValue}}
								</td>
								<td v-if="field.fieldTypeCd == '03' || field.fieldTypeCd == '05'">
									{{ field.fieldLabel == null || field.fieldLabel== '[]' ? '-' : field.fieldLabel}}
								</td>
								<td v-if="field.fieldTypeCd == '04'">
									{{ field.fieldLabel == null || field.fieldLabel == '[]' ? '-' : arrayDataGet(field.fieldLabel)}}
								</td>
							</tr>
                            <tr v-if="procFieldList.length == 0">
                                <td colspan="4" class="no-list">
                                    Error.
                                </td>
                            </tr>
                        </tbody>
                    </table>
			</div>
			<div class="bottom">
                <button type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
					Add
				</button>
                <button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doClose()">
                    Close
                </button>
            </div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "TestRequestEdit",
	created() {
        this.logger.debug(this, "created()");
		this.firstRegistUserId = this.userInfo.userId;
		this.procFieldList.sort(function (a, b) {
			const valueOfKeyA = a['cellName'].toUpperCase();
			const valueOfKeyB = b['cellName'].toUpperCase();
			return valueOfKeyA.localeCompare(valueOfKeyB, undefined, {
			numeric: true,
			sensitivity: 'base',
			});
		});
    },
    props: {
		procFieldList: {
			type: Array,
			required: true
		},
	},
	data() {
		return {
			item: {},
			checkedList: [],
		};
    },
    watch: {
	},
	methods: {
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					console.log(result);
					if (result)
					{
						if(this.checkedList.length <= 0){
							this.$alert("Not Checked!", "Warning", "warning");	
						}
						else{
							this.doInsert();
						}
					}
					else{
						this.$alert("ERROR!", "Warning", "warning");
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					this.$alert("ERROR!", "Warning", "warning");
					return false;
				});
		},
		doInsert() {
			let fieldList = [];

			this.procFieldList.forEach((item) => {
				if(this.checkedList.includes(item.procFieldNo + '_' + item.repeatSortOrder)){
					fieldList.push(item);
				}
			});

			this.item = {
				procFieldList : fieldList
			}
			axios
				.post(this.config.contextRoot + "/testScreening/requestEdit.do", this.item)
				.then((response) => {
					if (response.status == 200) {
						this.$alert("Request has been saved successfully.", "Info", "info").then(() => {
							this.$parent.doUpdateStatus('4');
							this.doClose();
							location.reload();
						});
					} else {
						alert(response.status);
						console.log(response);
						this.$parent.doCancel();
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.RequestEdit.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.RequestEdit.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("status-close", "isRequestEdit");
			}.bind(this), 410);
		},
		check(fieldNo, repeatSortOrder) {
			this.logger.debug(this, "check(fieldNo)");

			let index = this.checkedList.indexOf(fieldNo + '_' + repeatSortOrder);
			if (index >= 0) {
				this.checkedList.splice(index, 1);
			} else {
				this.checkedList.push(fieldNo + '_' + repeatSortOrder);
			}
		},
		checkAll(event) {
			this.logger.debug(this, "checkAll(event)");
			if (event.target.checked) {
				this.checkedList = this.procFieldList.map((item) => {
					return item.procFieldNo + '_' + item.repeatSortOrder;
				});
			} else {
				this.checkedList = [];
			}
		},
		arrayDataGet(data){
			let result = ""

			data.forEach((item) => {
				result += item +', ';
			})

			return result.slice(0, result.length-2);
		}
	}
};
</script>
<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 0;
	font-weight: 600;
	font-size: 11pt;
	color: #999;
}
.dynamic-warn {
    font-size: 9pt;
    color: rgb(224, 91, 91);
	font-weight: 600;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
.user-check{
	font-weight: 400;
    font-size: 10pt;
    color: #999;
    text-align: left;
}
label {
    display: inline-flex;
    align-items: center;
    min-height: 36px;
    font-size: 10pt;
    color: inherit;
}
input[type=checkbox] {
    display: none;
}
label>.checkbox {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
}
input[type=checkbox]:checked+.checkbox {
    border: 2px solid #2bade0;
}
input[type=checkbox]:checked+.checkbox::before {
    content: "\2713";
    position: absolute;
    top: -11px;
    left: -0.5px;
    width: 16px;
    height: 16px;
    font-weight: 600;
    font-size: 13pt;
    color: #2bade0;
}
</style>