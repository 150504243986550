<template>
	<div ref="ScreeningSingleCreate" class="dialog-container">
		<div ref="dialog" class="dialog" style="max-height: 800px; transform: translate(0, -400px)">
			<form>
				<!-- 팝업창 상단 제목 -->
				<div class="top">
					<button type="button" class="btn-cancel fright btn-history" title="History" @click="openStatusHistory()">Status History</button>
					<button type="button" class="btn-cancel fright btn-history" title="History" @click="openHistory()">Data History</button>
					<!-- <button type="button" class="btn-cancel fright btn-history" title="History">History</button> -->
					<p class="txt-crud-title">
						Test Data Input
					</p>
				</div>
				<!-- 팝업창 내용 -->
				<div class="center">
					<!-- 스크리닝 정보 -->
					<div class="dinput-info">
						<div class="item">
							<p class="title">Subject Number</p>
							<p class="content">
								{{ param.selectedScreeningName }}
							</p>
						</div>
						<div class="item">
							<p class="title">Page Name</p>
							<p class="content">
								{{ param.pageName }}
							</p>
						</div>
						<div class="item">
							<p class="title">Register</p>
							<p class="content">
								{{ items.lastModifyUserId ? items.lastModifyUserId : "-"  }}
							</p>
						</div>
						<div class="item">
							<p class="title">Regist Date</p>
							<p class="content">
								{{ items.lastModifyDate ? items.lastModifyDate : "-" }}
							</p>
						</div>
					</div>
					<div class="valid-event-sub">
						<div class="valid-event-part">
							<div class="open-query" style="cursor: auto;">1</div>
							<span>Open Query</span>
						</div>
						<div class="valid-event-part">
							<div class="valid-goto" style="cursor: auto;"></div>
							<span>Page Link</span>
						</div>
						<div class="valid-event-part">
							<div class="valid-msg" style="cursor: auto;"></div>
							<span>Message</span>
						</div>
						<div class="valid-event-part" style="margin-left: auto;">
							<div class="valid-msg" style="cursor: pointer; background-color: white; border: 1px solid #ccc; padding: 0 10px;" @click="validReset()">Validation reset</div>
						</div>
					</div>
					<div class="screening-contents-container">
						<table
							v-for="(table, index) in surveyItemList"
							:key="index"
							class="procedure-table"
							tabindex="0"
						>
							<colgroup>
								<col
									v-for="col in Number(table.tableCol)"
									:key="col"
									:width="setColumnStyle(index, String.fromCharCode(64 + col))"
								/>
							</colgroup>
							<thead>
								<tr>
									<th 
										:colspan="Number(table.tableCol + 1)"
										class="th-col"
									>
										{{ table.procName }}
									</th> 
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="row in Number(table.tableRow)"
									:key="row"
									:style="setRowStyle(index, row, table)"
								>
									<td
										v-for="col in Number(table.tableCol)"
										:key="col"
										:id="'td_' + index + '_' + (String.fromCharCode(64 + col) + row)"
										class="th-col"
										:data-id="String.fromCharCode(64 + col)"
										:colspan="tableDataList[index][String.fromCharCode(64 + col) + row].expandCol"
										:rowspan="tableDataList[index][String.fromCharCode(64 + col) + row].expandRow"
										:style="cellStyle(index, String.fromCharCode(64 + col)+ row)"
									>
										<!-- repeat button -->
										<!-- <div
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot == (String.fromCharCode(64 + col) + row)"
											:id="'repeat-zone-'+(String.fromCharCode(64 + col) + row)+'-'+table.procSortOrder"
											:style="makeRepeatDiv((String.fromCharCode(64 + col) + row), table.procSortOrder)"
										>
										</div> -->
										<button
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot == tableDataList[index][String.fromCharCode(64 + col) + row].cellName
													&& !tableDataList[index][String.fromCharCode(64 + col) + row].isCopy"
											class="repeat-Btn"
											type="button"
											@click="repeat(tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot, table.procSortOrder)"
										>
											+
										</button>
										<button
											v-else-if="tableDataList[index][String.fromCharCode(64 + col) + row].isCopy && col==1 
											&& tableDataList[index][tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot].repeatRow == tableDataList[index][String.fromCharCode(64 + col) + row].repeatRow
											&& tableDataList[index][tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot].repeatCol == tableDataList[index][String.fromCharCode(64 + col) + row].repeatCol"
											class="repeat-Btn"
											style="backgroundColor: #dd574f;"
											type="button"
											@click="repeatDelete(tableDataList[index][String.fromCharCode(64 + col) + row].repeatRoot, tableDataList[index][String.fromCharCode(64 + col) + row].cellName, table.procSortOrder)"
										>
											-
										</button>

										<!-- cell Text -->
										<div
											v-if="tableDataList[index][String.fromCharCode(64 + col) + row].isText"
											class="unfocus-text"
										>
											{{ tableDataList[index][String.fromCharCode(64 + col) + row].text }}
										</div>
										<!-- cell Data -->
										<div
											v-if="!tableDataList[index][String.fromCharCode(64 + col) + row].isText"
											:id="'tableHD_' + index + '_' + (String.fromCharCode(64 + col) + row)"
											:class="
												tableDataList[index][String.fromCharCode(64 + col) + row].isHeader ?
												'table-head' :
												'table-data'
											"
										>
											<!-- cell Header -->
											<template
												v-if="
													tableDataList[index][String.fromCharCode(64 + col) + row].isHeader &&
													(
														Object.keys(tableDataList[index][String.fromCharCode(64 + col) + row].field).length !== 0
													)
												"
											>
												
												{{ tableDataList[index][String.fromCharCode(64 + col) + row].field.questionName }}
												
											</template>
											<!-- cell Field -->
											<template
												v-if="
													(!tableDataList[index][String.fromCharCode(64 + col) + row].isHeader &&
													(
														Object.keys(tableDataList[index][String.fromCharCode(64 + col) + row].field).length !== 0
													))
												"
											>
												<ul style="display: block; width: 100%;">
													<li
														v-for="(field, fieldIndex) in getFieldList(index, String.fromCharCode(64 + col) + row)"
														:key="fieldIndex"
														:id="'surveyFieldList_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex"
														class="survey-field-list"
														:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'hide'? 'hidden' : ''"
													>
														{{ field.prefixName }}
														<div class="valid-event-zone">
															<!-- Open Query -->
															<template v-if="field.queryConfirmList.length != 0 
															&& validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) != 'disabled'
															&& validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) != 'hide'">
																<div 
																	class="open-query blinking"
																	@click="openQuery(field)"
																	:title="field.queryConfirmList.length + ' OPEN QUERY'"
																>
																	{{field.queryConfirmList.length}}
																</div>
															</template>
															<!-- gotoPage -->
															<div
																v-for="(gotoVo, gotoIndex) in validAction('GOTO', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field)"
																:key="'goto'+gotoIndex"
																class="valid-goto blinking"
																@click.stop="gotoPageAction(gotoVo)"
																:title="gotoVo.printMsg"
															>
															</div>
															<!-- dynamincPage -->
															<div
																v-for="(dynamicVo, dynamicIndex) in validAction('DYNAMIC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field)"
																:key="'dynamic'+dynamicIndex"
																class="valid-goto blinking"
																@click.stop="gotoPageAction(dynamicVo)"
																:title="dynamicVo.printMsg"
															>
															</div>
															<!-- printMsg -->
															<div
																v-if="validAction('MSG', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field).length > 0"
																class="valid-msg"
																@click.stop="msgPopup(field, $event)"
															>
																<div v-if="field.openMsgYn == 'Y'" class="entry-balloon"
																	@mouseenter="msgHover($event, 'over')"
																	@mouseleave="msgHover($event, 'leave')"
																>
																	<p>
																		<span
																			v-for="(msgVo, msgIndex) in validAction('MSG', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field)"
																			:key="'msg'+msgIndex"
																			:title="msgVo.printMsg"
																		>
																			#{{msgVo.printMsg }}
																		</span>
																	</p>
																</div>
															</div>
														</div>
														<div
															:class="field.fieldLayoutCd == '1' ? 'survey-field-vtype' : 'survey-field-htype'"
															:style="getMargin(field)"
														>
															<!-- Text type -->
															<template v-if="field.fieldTypeCd == '01'">
																<input
																	type='text'
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																	style="max-width: 100%;"
																	:name="'procFieldNo1' + field.fieldNo + row + col"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo1' + field.fieldNo) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex) ? '' : 'input-crud-error')"
																	:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) || entryStatus == '8'"
																	@input="validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex)"
																	@change="doValidCalc(true)"
																	:maxlength="setMaxLength(tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex])"
																/>
															</template>
															<!-- TextArea type -->
															<template v-if="field.fieldTypeCd == '02'">
																<textarea
																	:rows="field.textareaRows"
																	:cols="field.textareaCols"
																	:name="'procFieldNo2' + field.fieldNo + row + col"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	style="max-width: 100%; width: 41rem;"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo2' + field.fieldNo) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex) ? '' : 'input-crud-error')"
																	:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) || entryStatus == '8'"
																	@input="validateInput(index, String.fromCharCode(64 + col) + row, fieldIndex)"
																	@change="doValidCalc(true)"
																	:maxlength="setMaxLength(tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex])"
																/>
															</template>
															<!-- Radio type -->
															<template v-if="field.fieldTypeCd == '03'">
																<div
																	class="grid"
																	:style="getGridRowCol(field.radioOptionsLabel.split(';').length, field.radioCols)"
																>
																	<label
																		v-for="(text, radioIndex) in field.radioOptionsLabel.split(';')"
																		:key="radioIndex"
																		@dblclick="resetRadio(index, (String.fromCharCode(64 + col) + row), fieldIndex, validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field), entryStatus)"
																	>
																		<input
																			type="radio"
																			:name="'procFieldNo3' + field.fieldNo + row + col"
																			v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																			:value="field.radioOptionsValue.split(';')[radioIndex]"
																			:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo3' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																			:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) || entryStatus == '8'"
																			@change="doValidCalc(true)"
																		/><span class="radio" :class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo3' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"></span>
																		{{ text }}
																	</label>
																</div>
															</template>
															<!-- Checkbox type -->
															<template v-if="field.fieldTypeCd == '04'">
																<div
																	class="grid"
																	:style="getGridRowCol(field.checkboxOptionsLabel.split(';').length, field.checkboxCols)"
																>
																	<label
																		v-for="(text, checkboxIndex) in field.checkboxOptionsLabel.split(';')"
																		:key="checkboxIndex"
																	>
																		<input
																			type="checkbox"
																			:name="'procFieldNo4' + field.fieldNo + row + col"
																			v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																			:value="field.checkboxOptionsValue.split(';')[checkboxIndex]"
																			:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo4' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																			:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) || entryStatus == '8'"
																			@change="doValidCalc(true)"
																		/><span class="checkbox" :class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo4' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"></span>
																		{{ text }}
																	</label>
																</div>
															</template>
															<!-- Select type -->
															<template v-if="field.fieldTypeCd == '05'">
																<select
																	class="select-crud"
																	:name="'procFieldNo5' + field.fieldNo + row + col"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8'? '' : (errors.has('procFieldNo5' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																	:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) || entryStatus == '8'"
																	@change="doValidCalc(true)"
																>
																	<option value="">-- Select --</option>
																	<option
																		v-for="(selectItem, selectIndex) in field.selectOptionsLabel.split(';')"
																		:key="selectIndex"
																		:value="field.selectOptionsValue.split(';')[selectIndex]"
																	>
																			{{ selectItem }}
																	</option>
																</select>
															</template>
															<!-- Image type -->
															<template v-if="field.fieldTypeCd == '06'">
																<div v-show="field.isLoad">
																	<img v-if="field.fileList && field.fileList.length > 0"
																		:src="
																			config.contextRoot +
																				'/common/file/printImage.do?fileNo=' +
																				field.fileList[0].fileNo +
																				'&fileGroupNo=' +
																				field.fileList[0].fileGroupNo +
																				'&serverFileSaveName=' +
																				field.fileList[0].serverFileSaveName
																		"
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																		@load="loadedImage(field)"
																	/>
																</div>
																<div v-if="!field.isLoad">
																	<svg 
																		v-if="field.fileList && field.imageWidth"
																		xmlns="http://www.w3.org/2000/svg"
																		:width="field.imageWidth"
																		:height="field.imageHeight"
																		:viewBox="'0 0 ' + field.imageWidth + ' ' + field.imageHeight">
																		<rect fill="#ddd" 
																			:width="field.imageWidth"
																			:height="field.imageHeight"
																		/>
																		<text fill="#666" font-family="sans-serif" font-size="20" dy="10.5" font-weight="normal" x="50%" y="50%" text-anchor="middle">
																			{{field.imageWidth}}x{{field.imageHeight}}
																		</text>
																	</svg>
																	<p v-else-if="!field.fileList">
																		There is no image.
																	</p>
																</div>
															</template>
															<!-- File Type -->
															<template v-if="field.fieldTypeCd == '07'">
																<!-- <div class="file-wrap">
																	<input
																		type="file"
																		hidden
																	/>
																	<input
																		type="text"
																		class="file"
																		:value="
																			'(' + field.fileMaxSize + 'MB, 확장자: ' + field.fileExtension + ')'
																		"
																		disabled
																	/>
																	<button type="button" class="upload">Upload</button>
																</div> -->
																<site-file
																	:fileExtension="table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)].fieldList[fieldIndex].fileExtension"
																	:fileMaxSize="table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)].fieldList[fieldIndex].fileMaxSize"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].siteFileList"
																/>
															</template>
															<!-- Date Start -->
															<template v-if="field.fieldTypeCd == '08'">
																<label 
																	class="label-date-picker"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? '' : (errors.has('procFieldNo8' + field.fieldNo + row + col) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																	:style="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? 'background-color : #eee; color: #ccc;' : utils.isEmpty(tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'color: #ccc' : 'color: #999'"
																>
																	{{
																		(
																			getDateValue(index, String.fromCharCode(64 + col) + row, fieldIndex, 'date-picker_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex)
																		) 
																	}}
																	<i class="mx-icon">
																		<i class="mx-icon-calendar">
																			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
																				<path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z">
																				</path>
																			</svg>
																		</i>
																		<i class="mx-icon-clear"
																			@click="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? '' : clearDate(index, String.fromCharCode(64 + col) + row, fieldIndex, 'date-picker_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex)"
																		>
																			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em">
																				<path d="M810.005333 274.005333l-237.994667 237.994667 237.994667 237.994667-60.010667 60.010667-237.994667-237.994667-237.994667 237.994667-60.010667-60.010667 237.994667-237.994667-237.994667-237.994667 60.010667-60.010667 237.994667 237.994667 237.994667-237.994667z">
																				</path>
																			</svg>
																		</i>
																	</i>
																	<date-picker
																		:ref="'date-picker_' + index + '_' + (String.fromCharCode(64 + col) + row) + '_' + fieldIndex"
																		:name="'procFieldNo8' + field.fieldNo + row + col"
																		:type="getViewOption(tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dataTypeCd, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat)"
																		v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValueTemp"
																		value-type="format"
																		:format="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.replace('hh', 'HH')"
																		:lang="lang"
																		style="width: 0px; height: 0px; overflow: hidden;"
																		confirm
																		:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? 'hidden-important' : ''"
																		@change="doValidCalc(true)"
																	>
																		<template 
																			v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isAllowUk == 'Y'" 
																			v-slot:sidebar="{ }"
																		> 
																			<div class="sidebar-select-uk" style="padding: 8px">
																				<p>Select UK</p>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('YYYY') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="YYYY" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkYYYY"> 
																					<span class="checkbox"></span>
																					YYYY
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('MM') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="MM" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkMM">
																					<span class="checkbox"></span>
																					MM
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('DD') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="DD" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkDD">
																					<span class="checkbox"></span>
																					DD
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('hh') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="hh" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkhh">
																					<span class="checkbox"></span>
																					hh
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('mm') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="mm" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkmm">
																					<span class="checkbox"></span>
																					mm
																				</label>
																				<label v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].dateFormat.indexOf('ss') >= 0" class="checkbox-crud">
																					<input type="checkbox" name="checkedUk" value="ss" v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].isUkss">
																					<span class="checkbox"></span>
																					ss
																				</label>
																			</div>
																		</template>
																	</date-picker>
																</label>
															</template>
															<!-- Calc Type -->
															<template v-if="field.fieldTypeCd == '09'">
																<input
																	type="text"
																	class="calc"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? '' : (errors.has('calculate' + field.fieldNo) ? 'input-crud-error' : missingQueryCheck(field, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue) ? 'input-crud-error' : '')"
																	:name="'calculate' + field.fieldNo"
																	v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldValue"
																	:style="'width:' + Number(field.dataSize * 2) + 'rem;'"
																	style="max-width: 100%;"
																	readonly
																	:disabled="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) || entryStatus == '8'"
																	@change="doValidCalc(true)"
																/>
																<button
																	type="button"
																	class="btn-calc"
																	:class="validAction('PROC', table.procNo, field.procQuestionNo, index, String.fromCharCode(64 + col) + row, fieldIndex, field.fieldTypeCd, field) == 'disabled' || entryStatus == '8' ? 'hidden-important' : ''"
																	title="calculate"
																	@click.stop="doCalculate(table.procQuestionList[getProcQuestionIndex(index, String.fromCharCode(64 + col) + row)], fieldIndex, tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList, index, (String.fromCharCode(64 + col) + row))"
																>
																	Calculate
																</button>
															</template>
															<!-- FileDownload Type -->
															<template v-if="field.fieldTypeCd == '10' && field.fileList.length >= 1">
																<common-file
																	v-model="field.fileList"
																	:fieldTypeCd = field.fieldTypeCd
																	:downloadOnly="true"
																	style="display: inline-block; margin: 0 10px 0 0;"
																/>
																<button
																	v-if="['pdf', 'PDF'].includes(field.fileList[0].fileExtensionName)"
																	type="button"
																	class="download-preview"
																	title="Preview"
																	@click="showPreview(field.fileList)"
																>
																	Preview
																</button>
															</template>
															<template v-if="field.fieldTypeCd == '10' && field.fileList.length < 1">
																<p class="txt-validation">
																	File is not exist!
																</p>
															</template>
															<!-- video Type -->
															<template v-if="field.fieldTypeCd == '11' && field.fileList.length >= 1">
																<common-file
																	v-model="field.fileList"
																	:fieldTypeCd = field.fieldTypeCd
																	:downloadOnly="true"
																/>
															</template>
															<template v-if="field.fieldTypeCd == '11' && field.fileList.length < 1">
																<p class="txt-validation">
																	Video is not exist!
																</p>
															</template>
															<!-- Text type -->
															<template v-if="field.fieldTypeCd == '12'">
																{{tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].selectDefault}}
															</template>
														</div>
														{{ field.suffixName }}
														<!-- update Reason -->
														<template v-if="workAccessCheck('EDIT REASON') && (entryStatus == '3') && tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].fieldTypeCd != '12'">
															<select
																class="select-crud"
																style="position: absolute; min-width: 96%; max-width: 96% !important; height: 30px; margin-top: 10px; left: 2%; bottom: 5px;"
																:style="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].updateReason=='4'? 'min-width: 38%; max-width:38% !important' :''"
																:name="'procFieldNo5' + field.fieldNo + row + col"
																v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].updateReason"
																v-validate="'required'"
																:class="errors.has('procFieldNo5' + field.fieldNo + row + col) ? 'input-crud-error' : ''"
															>
																<option value="">-- Select Entry Update Reason --</option>
																<option
																	v-for="(comcode, index) in codeList"
																	:key="index"
																	:value="comcode.comCode"
																>
																	{{  comcode.comCodeName }}
																</option>
															</select>
															<input 
																v-if="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].updateReason == '4'"
																type="text"
																placeholder="Please enter other reasons"
																style="position: absolute; min-width: 57%; max-width: 57% !important; height: 30px; margin-top: 10px; right: 2%; bottom: 5px;"
																v-model="tableDataList[index][String.fromCharCode(64 + col) + row].field.fieldList[fieldIndex].etcReason"
															>
														</template>
													</li>
												</ul>
											</template>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="bottom">
					<button v-if="workAccessCheck('MONITORING REQUEST') && entryStatusAuth('4') && !(projectStateCd == '4')" type="button" class="btn-cancel btn-temp" title="Monitoring Request" @click.stop="requestEdit()">Monitoring Request</button>
					<button v-if="workAccessCheck('END MONITORING') && entryStatusAuth('6') && !(projectStateCd == '4')" type="button" class="btn-cancel btn-temp" title="End Monitoring" @click.stop="doUpdateAlert('6')">End Monitoring</button>
					<button v-if="workAccessCheck('PI SIGNATURE') && entryStatusAuth('7') && !(projectStateCd == '4')" type="button" class="btn-cancel btn-temp" title="PI Signature" @click.stop="piSignature()">PI Signature</button>
					<button v-if="workAccessCheck('DATA FREEZING') && entryStatusAuth('8') && !(projectStateCd == '4')" type="button" class="btn-cancel btn-temp" title="Data Freezing" @click.stop="doUpdateAlert('8')">Data Freezing</button>
					<button v-if="workAccessCheck('DATA FREEZING') && entryStatusAuth('9') && !(projectStateCd == '4')" type="button" class="btn-cancel btn-temp" title="UnFreezing" @click.stop="doUpdateAlert('6')">UnFreezing</button>
					
					<button v-if="workAccessCheck('REQUEST EDIT') && entryStatusAuth('5') && !(projectStateCd == '4')" type="button" class="btn-cancel fright btn-temp" title="Request Edit" @click.stop="doValidation('5')">Request Edit</button>
					<button v-if="workAccessCheck('DATA SAVE') && entryStatusAuth('3') && !(projectStateCd == '4')" type="button" class="btn-save fright" :title="entryStatus != '3' ? 'Save' : 'Edit'" @click.stop="doValidation('3')">{{ entryStatus != '3' ? 'Save' : 'Edit'}}</button>
					<button
						v-if="workAccessCheck('DATA TEMP SAVE') && entryStatusAuth('2') && !(projectStateCd == '4')"
						type="button"
						class="btn-cancel fright btn-temp"
						title="Temp Save"
						@click.stop="doValidation('2')"
					>
						Temp Save
					</button>
					<button type="button" class="btn-cancel fright" title="Close" @click="doCancel()">Close</button>
				</div>
			</form>
		</div>
		<button
			v-if="isViewer"
			type="button" class="btn-preview-close" title="close" @click="closePreview()">Close</button>
		<PDFViewer
			v-if="isViewer"
			:source=viewerUrl
			:controls=viewerControl
			style="position:fixed; top:0; left:0; width: 50vw; height: 100vh; border-radius: 10px;"
		>
		</PDFViewer>
		<ScreeningHistory v-if="isScreeningHistory" :param="items" />
		<ScreeningStatusHistory v-if="isScreeningStatusHistory" :param="items" />
		<ConfirmTestQuery v-if="isOpenQuery" :selectQuery="selectQuery" :selectField="selectField"/>
		<PiSignature v-if="isOpenSign" :param="items"/>
		<TestRequestEdit v-if="isRequestEdit" :procFieldList = "procFieldList"/>
		<RepeatField v-if="isRepeatField" :calcRepeatList="calcRepeatList"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';
import SiteFile from "@/components/common/SiteFile";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ScreeningHistory from "@/components/project/screening/ScreeningHistory";
import ScreeningStatusHistory from "@/components/project/screening/ScreeningStatusHistory";
import ConfirmTestQuery from "@/components/project/testQueryResolution/ConfirmTestQuery";
import PiSignature from "@/components/project/screening/PiSignature";
import TestRequestEdit from "@/components/project/testScreening/TestRequestEdit";
import RepeatField from "@/components/project/screening/RepeatField.vue";

export default {
	name: "TestScreeningSingleCreate",
	created() {
		this.logger.debug(this, "created()");
		
		this.items.testScreeningNo = this.param.selectedScreeningNo;
		this.items.visitNo = this.param.visitNo;
		this.items.pageNo = this.param.pageNo;
		this.items.userId = this.userInfo.userId;
		this.items.userName = this.userInfo.userName;
		this.items.screeningName = this.param.selectedScreeningName;
		this.items.visitName = this.param.visitName;
		this.items.pageName = this.param.pageName;
		this.entryStatus = this.param.entryStatus;

		this.selectComCodeList();
		this.select();
		this.getAccessList('TEST ENTRY');
		this.getProjectStateCd();

		// validation이 걸려있는지 CHeck
		// this.validCountCheck();

		EventBus.$on("status-close", () => {
			this.logger.debug(this, "[EventBus] status-cancel");
			this.isOpenSign = false;
			this.isRequestEdit = false;
		});

		EventBus.$on("query-close", () => {
			this.logger.debug(this, "[EventBus] query-cancel");
			this.isOpenQuery = false;
		});

		EventBus.$on("history-close", () => {
			this.logger.debug(this, "[EventBus] history-cancel");
			this.isScreeningHistory = false;
			this.isScreeningStatusHistory = false;
		});
	},
	components: {
		CommonFile,
		PDFViewer,
		SiteFile,
		DatePicker,
		ScreeningHistory,
		ScreeningStatusHistory,
		ConfirmTestQuery,
		PiSignature,
		TestRequestEdit,
		RepeatField
	},
	props: {
		param: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: {},
			accessList: [],
			procFieldList: [],
			surveyItemList: [],
			repeatList: [],
			tableRow: 0,
			tableCol: 0,
			tableDataList: [],
			columnInfoList: [],
			rowInfoList: [],
			isViewer : false,
			viewerUrl : "",
			viewerControl : ['print','rotate','zoom','catalog','switchPage'],
			deleteRepeatList: [],
			validCount : 0,
			editDateInfo: {
				index: "",
				cell: "",
				fieldIndex: ""
			},
			lang: {
				formatLocale: {
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
					weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토']
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
			isScreeningHistory: false,
			isScreeningStatusHistory: false,
			isOpenQuery: false,
			isOpenSign: false,
			isRequestEdit: false,
			selectQuery: [],
			codeList: [],
			entryStatus: "",
			selectField: "",
			//Valid Result List
			applyValidVoList: [],
			disableList: [],
			msgList: [],
			gotoList: [],
			hideList: [],
			isSingleCreate: false,
			paramSigleCreate: {},
			isRepeatField: false,
			calcRepeatList: [],
			calcParam: {
				fieldList: [],
				calcGpLst: [],
				index: "",
				cellId: "",
				fieldIndex: ""
			},
			calcVo: {},
			calculateVoList: [],
			projectStateCd: ""
		};
	},
	methods: {
		selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["UPDATE_REASON"]})
				.then((response) => {
					this.codeList = response.data.codeList;

					this.$nextTick(function () {
						setTimeout(function() {
							// 로딩바 닫기 요청
							EventBus.$emit("field-loading", false);
						}.bind(this), 500);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getViewOption(type, format) {
			var result;
			if(type == 3 || type == 11){
				if(format.indexOf("YYYY") >= 0 && format.indexOf("MM") < 0 && format.indexOf("DD") < 0) 
					result = "year";
				else if(format.indexOf("YYYY") >= 0 && format.indexOf("MM") >= 0 && format.indexOf("DD") < 0 || format.indexOf("YYYY") < 0 && format.indexOf("MM") >= 0 && format.indexOf("DD") < 0) 
					result = "month";
				else result = "day";
			} else if (type == 4 || type == 12){
				result = "time";
			} else {
				result = "datetime";
			}
			return result;
		},
		getDateValue(index, cellName, fieldIndex, refName) {
			var fieldValueTemp = this.tableDataList[index][cellName].field.fieldList[fieldIndex].fieldValueTemp;
			var fieldValue = this.tableDataList[index][cellName].field.fieldList[fieldIndex].fieldValue;

			if(this.utils.isEmpty(fieldValueTemp))
			{
				if(this.utils.isEmpty(fieldValue))
				{
					return this.tableDataList[index][cellName].field.fieldList[fieldIndex].dateFormat;
				}
				else
				{
					return fieldValue;
				}
			}
			else
			{
				var isUkYYYY = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkYYYY;
				var isUkMM = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkMM;
				var isUkDD = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkDD;
				var isUkhh = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkhh;
				var isUkmm = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkmm;
				var isUkss = this.tableDataList[index][cellName].field.fieldList[fieldIndex].isUkss;

				if(isUkYYYY || isUkMM || isUkDD || isUkhh || isUkmm || isUkss)
				{
					var date = new Date(this.$refs[refName][0].$data.currentValue);
					var dateFormat = this.tableDataList[index][cellName].field.fieldList[fieldIndex].dateFormat;
					
					if(isUkYYYY) dateFormat = dateFormat.replace('YYYY', 'UKUK');
					else dateFormat = dateFormat.replace('YYYY', date.getFullYear());
					if(isUkMM) dateFormat = dateFormat.replace('MM', 'UK');
					else dateFormat = dateFormat.replace('MM', ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)));
					if(isUkDD) dateFormat = dateFormat.replace('DD', 'UK');
					else dateFormat = dateFormat.replace('DD', (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()));
					if(isUkhh) dateFormat = dateFormat.replace('hh', 'UK');
					else dateFormat = dateFormat.replace('hh', (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()));
					if(isUkmm) dateFormat = dateFormat.replace('mm', 'UK');
					else dateFormat = dateFormat.replace('mm', (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()));
					if(isUkss) dateFormat = dateFormat.replace('ss', 'UK');
					else dateFormat = dateFormat.replace('ss', (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()));

					this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", dateFormat);
				}
				else
				{
					this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", fieldValueTemp);
				}
				return this.tableDataList[index][cellName].field.fieldList[fieldIndex].fieldValue;
			}
		},
		select() {
			EventBus.$emit("project-loading", true);
			this.logger.debug(this, "select()");
			axios
				.post(this.config.contextRoot + "/testScreening/selectProcList.do", this.items)
				.then((response) => {
					console.log(response);
					this.items.lastModifyUserId = response.data.registerVo.lastModifyUserId;
					this.items.lastModifyDate = response.data.registerVo.lastModifyDate;
					this.surveyItemList = response.data.itemList;
					this.tableDataList = response.data.tableDataList;
					this.columnInfoList = response.data.columnInfoList;
					this.rowInfoList = response.data.rowInfoList;

					for(let i = 0; i < this.tableDataList.length; i++) {
						let tableDataKeys = Object.keys(this.tableDataList[i]);
						for(let j = 0 ; j < tableDataKeys.length ; j++)
						{
							this.$set(this.tableDataList[i][tableDataKeys[j]], "isCopy", false);
							if(this.tableDataList[i][tableDataKeys[j]].field == null)
							{
								this.$set(this.tableDataList[i][tableDataKeys[j]], "field", {});
							}
							else{
								for(let k = 0; k < this.tableDataList[i][tableDataKeys[j]].field.fieldList.length; k++){
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldTypeCd == "04" && this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue == ""){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].fieldValue = [];
									}
									if(this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].updateReason == ''){
										this.tableDataList[i][tableDataKeys[j]].field.fieldList[k].updateReason = '5';
									}
								}
							}
						}
					}
					for(let i = 0; i < this.surveyItemList.length; i++)
					{
						for(let j = 0; j < this.surveyItemList[i].procQuestionList.length; j++)
						{
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldList[k].fieldValue = [];
									}
								}
							}
							for(let k = 0; k < this.surveyItemList[i].procQuestionList[j].fieldRepeatList.length; k++)
							{
								if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldTypeCd == "04") {
									if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.indexOf(";") >= 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.split(";");
									} else if (this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue.length > 0) {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue];
									} else {
										this.surveyItemList[i].procQuestionList[j].fieldRepeatList[k].fieldValue = [];
									}
								}
							}
						}
					}
					this.$nextTick(function(){
						this.setRepeat();
						this.setData();
						this.validApply();
						EventBus.$emit("project-loading", false);
					});
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
				
		},
		validCountCheck(){
			for(let i = 0; i < this.param.disableProcList.length; i++)
			{
				if(this.param.visitNo == this.param.disableProcList[i].visitNo && this.param.pageNo == this.param.disableProcList[i].pageNo)
				{
					this.validCount++;
				}
			}
			for(let i = 0; i < this.param.disableQuestionList.length; i++)
			{
				if(this.param.visitNo == this.param.disableQuestionList[i].visitNo && this.param.pageNo == this.param.disableProcList[i].pageNo)
				{
					this.validCount++;
				}
			}
			for(let i = 0; i < this.param.validResultMsg.length; i++){
				if(this.param.visitNo == this.param.validResultMsg[i].visitNo && this.param.pageNo == this.param.validResultMsg[i].pageNo)
				{
					this.validCount++;
				}
			}
		},
		setRepeat(){
			this.logger.debug(this, "setRepeat()");
			this.repeatList = JSON.parse(JSON.stringify(this.surveyItemList));
			let repeatArr = [];
			// let value = [];
			// let repeatCount = 0; 
			for(let i = 0; i < this.tableDataList.length; i++){
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++){
					if(Object.keys(this.tableDataList[i][keys[j]].field).length > 0 && this.tableDataList[i][keys[j]].repeatRoot != "" && !this.tableDataList[i][keys[j]].isHeader){
						for(let k = 0; k < this.repeatList[i].procQuestionList.length; k++){
							for(let l = 0; l < this.repeatList[i].procQuestionList[k].fieldRepeatList.length; l++){
								for(let t = 0; t < this.tableDataList[i][keys[j]].field.fieldList.length; t++){
									if(this.tableDataList[i][keys[j]].field.fieldList[t].procFieldNo == this.repeatList[i].procQuestionList[k].fieldRepeatList[l].procFieldNo && this.repeatList[i].procQuestionList[k].fieldRepeatList[l].repeatSortOrder != 1){
										// this.repeat(this.tableDataList[i][keys[j]].cellName, i+1, this.repeatList[i].procQuestionList[k].fieldRepeatList[0].fieldValue);
										repeatArr.push({cellName : this.tableDataList[i][keys[j]].repeatRoot, value : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].fieldValue, procFieldNo : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].procFieldNo, screeningInputNo : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].screeningInputNo, screeningEntryNo : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].screeningEntryNo, sortOrder : this.repeatList[i].procQuestionList[k].fieldRepeatList[l].repeatSortOrder, row : i});
										// value.push(this.repeatList[i].procQuestionList[k].fieldRepeatList[0].fieldValue);
									}
								}
							}
						}
					}
				}
			}
			console.log(repeatArr);
			// repeatArr.sort(function(a,b){
			// 	if(a.row == b.row){
			// 		var x = a.cellName.toLowerCase(), y = b.cellName.toLowerCase();
			// 		if(x == y){
			// 			var k = a.sortOrder.toLowerCase(), l = b.sortOrder.toLowerCase();

			// 			return k < l ? -1 : k > l ? 1 : 0;
			// 		}
			// 		else
			// 		{
			// 			var xCol = x.slice(0,1), yCol = y.slice(0,1);
			// 			console.log(xCol, yCol);
			// 			if(xCol < yCol) return -1;
			// 			if(xCol > yCol) return 1;
			// 			else
			// 			{
			// 				var xRow = x.slice(1), yRow = y.slice(1);
			// 				console.log(xRow, yRow);
			// 				return xRow - yRow;
			// 			}
			// 		}
			// 	}
			// 	return a.row - b.row;
			// });
			repeatArr.sort((a, b) => a.row - b.row);
			// console.log(repeatArr);
			let repeatRow = 0;
			let repeatCount = 0;
			let endName = "";
			for(let i = 0; i < repeatArr.length; i++){
				if(repeatArr[i+1] != null && typeof repeatArr[i+1] != "undefined")
				{
					if(repeatArr[i].cellName != repeatArr[i+1].cellName || repeatArr[i].row != repeatArr[i+1].row)
					{
						for(let j = 1; j < repeatArr[i].sortOrder; j++)
						{
							if(j==1){
								let colName = repeatArr[i].cellName.slice(0,1);
								let rowName = repeatArr[i].cellName.slice(1,10);
								let addRowName = Number(rowName) + Number(repeatRow);
								endName = colName + addRowName;
							}
							this.repeat(endName, repeatArr[i].row+1);
							let keys = Object.keys(this.tableDataList[repeatArr[i].row]);

							for(let k =0; k < keys.length; k++){
								if(this.tableDataList[repeatArr[i].row][keys[k]].repeatRoot == endName && !this.tableDataList[repeatArr[i].row][keys[k]].isCopy){
									repeatCount += 1;
								}
							}
							repeatRow += repeatCount/this.surveyItemList[repeatArr[i].row].tableCol;
							repeatCount = 0;
						}
					}
					if(repeatArr[i].row != repeatArr[i+1].row)
					{
						endName = "";
						repeatRow = 0;
					}
				}
				else 
				{
					for(let j = 1; j < repeatArr[i].sortOrder; j++)
					{
						if(j==1){
								let colName = repeatArr[i].cellName.slice(0,1);
								let rowName = repeatArr[i].cellName.slice(1,10);
								let addRowName = Number(rowName) + Number(repeatRow);
								endName = colName + addRowName;
						}
						console.log("repeatName : " + endName);
						console.log("row : " + (Number(repeatArr[i].row)+1));
						this.repeat(endName, Number(repeatArr[i].row)+1);
					}
				}
			}
		},
		setData(){
			this.logger.debug(this, "setData()");
			let dataList = [];
			console.log(this.repeatList);
			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(Object.keys(this.tableDataList[i][keys[j]].field).length > 0 && !this.tableDataList[i][keys[j]].isHeader)
					{
						for(let k = 0; k < this.tableDataList[i][keys[j]].field.fieldList.length; k++)
						{
							if(this.tableDataList[i][keys[j]].field.fieldList[k].repeatSortOrder == 1)
							{
								for(let l = 0; l < this.repeatList[i].procQuestionList.length; l++)
								{
									for(let t = 0; t < this.repeatList[i].procQuestionList[l].fieldList.length; t++)
									{
										if(this.tableDataList[i][keys[j]].field.fieldList[k].repeatSortOrder == this.repeatList[i].procQuestionList[l].fieldList[t].repeatSortOrder)
										{
											if(this.tableDataList[i][keys[j]].field.fieldList[k].procFieldNo == this.repeatList[i].procQuestionList[l].fieldList[t].procFieldNo)
											{
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "beforeFieldValue", this.repeatList[i].procQuestionList[l].fieldList[t].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "fieldValue", this.repeatList[i].procQuestionList[l].fieldList[t].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningInputNo", this.repeatList[i].procQuestionList[l].fieldList[t].testScreeningInputNo);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningEntryNo", this.repeatList[i].procQuestionList[l].fieldList[t].testScreeningEntryNo);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "fileList", this.repeatList[i].procQuestionList[l].fieldList[t].fileList);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "siteFileList", this.repeatList[i].procQuestionList[l].fieldList[t].siteFileList);
											}
										}
									}

								}
							}
							else{
								for(let a = 0; a < this.repeatList[i].procQuestionList.length; a++)
								{
									for(let s = 0; s < this.repeatList[i].procQuestionList[a].fieldRepeatList.length; s++)
									{
										if(this.tableDataList[i][keys[j]].field.fieldList[k].repeatSortOrder == this.repeatList[i].procQuestionList[a].fieldRepeatList[s].repeatSortOrder)
										{
											if(this.tableDataList[i][keys[j]].field.fieldList[k].procFieldNo == this.repeatList[i].procQuestionList[a].fieldRepeatList[s].procFieldNo)
											{
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "beforeFieldValue", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "fieldValue", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].fieldValue);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningInputNo", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].testScreeningInputNo);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "testScreeningEntryNo", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].testScreeningEntryNo);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "fileList", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].fileList);
												this.$set(this.tableDataList[i][keys[j]].field.fieldList[k], "siteFileList", this.repeatList[i].procQuestionList[a].fieldRepeatList[s].siteFileList);
											}
										}
									}

								}
							}
						}
					}
				}
			}
			console.log(dataList);
		},
		setRowStyle(index, row, table){
			let rowInfo = this.rowInfoList[index];
			let height = rowInfo[row].height;
			let rowName = rowInfo[row].rowName;
			let count = 0;
			let resultHeight = height;

			if(this.workAccessCheck('EDIT REASON') && (this.entryStatus == '3')){
				let keys = Object.keys(this.tableDataList[index]);
	
				for(let j = 0; j < keys.length; j++){
					if(this.tableDataList[index][keys[j]].field != null){
						if(this.tableDataList[index][keys[j]].field.procNo == table.procNo && this.tableDataList[index][keys[j]].isHeader == false){
							let keyRow = keys[j].slice(1, 10);
							if(parseInt(keyRow) == parseInt(row)){
								count += 1;	
							}
						}
					}
				}
				if(count > 0){
					let splitHeight = height.split('px');
					
					resultHeight = parseInt(splitHeight) + 100;
					resultHeight += 'px';
				}
			}

			return {
				height: resultHeight,
				rowName : rowName
			};
		},
		setColumnStyle(index, col){
			let columnInfo = this.columnInfoList[index];
			let width = columnInfo[col].width;
			return width;
		},
		makeRepeatDiv(targetId, sortOrder){
			let keys = Object.keys(this.tableDataList[sortOrder-1]);
			let minRow = 0;
			let maxRow = 0;
			let countArr = [];
			let style = {};

			for(let i = 0 ; i < keys.length ; i++)
			{
				if(this.tableDataList[sortOrder-1][keys[i]].isRepeat && this.tableDataList[sortOrder-1][keys[i]].repeatRoot == targetId)
				{
					let rootId = keys[i].slice(1, 10);
					countArr.push(rootId);
				}
			}
			minRow = Math.min(...countArr);
			maxRow = Math.max(...countArr);
			console.log("minRow, maxRow");
			console.log(minRow, maxRow);
			// 세로
			let totalHeight = 0;
			if(minRow == maxRow)
			{
				totalHeight = Number(this.rowInfoList[sortOrder-1][minRow].height.replace('px', ''));
			} 
			else
			{
				for(let i = minRow ; i <= maxRow; i++)
				{
					totalHeight += Number(this.rowInfoList[sortOrder-1][i].height.replace('px', ''));
				}
			}
			style.height = totalHeight + "px";
			style.width = "1208.340px";
			style.border = "2px solid #2bade0";
			style.backgroundColor = "transparent";
			style.position = "absolute";
			style.top = "0";
			style.zIndex = "15";

			return style;
		},
		repeatDelete(repeatRoot, cellName, row){
			console.log(repeatRoot, cellName, row, this.surveyItemList[row-1].tableCol);
			let keys = Object.keys(this.tableDataList[row-1]);
			// let fieldKeys = Object.keys(this.surveyItemList[row-1].procQuestionList);
			let repeatCount = 0;
			let fieldCount = 0;
			let fieldItems = [];
			let sortOrder = 0;

			for(let i =0; i < keys.length; i++){
				if(this.tableDataList[row-1][keys[i]].repeatRoot == repeatRoot && !this.tableDataList[row-1][keys[i]].isCopy){
					repeatCount += 1;
					// repeatItems.push(JSON.parse(JSON.stringify(this.tableDataList[row-1][keys[i]])));
				}
				if(this.tableDataList[row-1][keys[i]].repeatRoot == repeatRoot && !this.tableDataList[row-1][keys[i]].isCopy && Object.keys(this.tableDataList[row-1][keys[i]].field).length > 0 && !this.tableDataList[row-1][keys[i]].isHeader){
					fieldCount += 1;
					fieldItems.push(JSON.parse(JSON.stringify(this.tableDataList[row-1][keys[i]])));
				}
			}
			let repeatRow = repeatCount/this.surveyItemList[row-1].tableCol;
			console.log(fieldItems);
			console.log("fieldCount : " + fieldCount);
			console.log("repeatRow : " + repeatRow);

			let backTableList = [];
			let cellNum = cellName.slice(1, 10);			
			let lastCellNum = Number(cellNum) + Number(repeatRow) - 1;

			for(let i =0; i < keys.length; i++){
				for(let j = cellNum; j <= lastCellNum; j++){
					if(keys[i].slice(1,10) == j && !this.tableDataList[row-1][keys[i]].isHeader && Object.keys(this.tableDataList[row-1][keys[i]].field).length > 0){
						sortOrder = this.tableDataList[row-1][keys[i]].field.fieldList[0].repeatSortOrder;
					}
				}
			}

			console.log(cellNum);
			for(let i=0; i < keys.length; i++){
				if(this.tableDataList[row-1][keys[i]].cellName.slice(1,10) > lastCellNum){
					backTableList.push(JSON.parse(JSON.stringify(this.tableDataList[row-1][keys[i]])));
				}
			}
			console.log(backTableList);

			// let startRow = repeatRoot.slice(1,10);
			// let endRow = Number(startRow) + Number(repeatCount/this.surveyItemList[row-1].tableCol);

			let rowInfo = this.rowInfoList[row-1];
			let rowKeys = Object.keys(rowInfo);
			for(let i = cellNum; i < Number(cellNum) + Number(repeatRow); i++){
				console.log("delete row : " + i);
			}
			// let backRowList = [];
			console.log(rowInfo);
			for(let i = 1; i <= rowKeys.length; i++){
				if(i >= Number(cellNum) + Number(repeatRow)){
					console.log(i);
					this.$set(rowInfo, i-repeatRow, rowInfo[i]);
				}
			}
			for(let i = rowKeys.length; i > rowKeys.length - repeatRow; i--){
				console.log(i);
				this.$delete(rowInfo, i);
			}
			console.log(rowInfo);

			console.log("sortOrder : " + sortOrder);
			for(let i = 0; i < keys.length; i++)
			{
				if(!this.tableDataList[row-1][keys[i]].isHeader && this.tableDataList[row-1][keys[i]].isCopy && Object.keys(this.tableDataList[row-1][keys[i]].field).length > 0)
				{
					for(let j = 0; j < this.tableDataList[row-1][keys[i]].field.fieldList.length; j++)
					{
						if(this.tableDataList[row-1][keys[i]].field.fieldList[j].repeatSortOrder == sortOrder)
						{
							for(let k = 0; k < fieldItems.length; k++)
							{
								for(let l = 0; l < fieldItems[k].field.fieldList.length; l++)
								{
									if(this.tableDataList[row-1][keys[i]].field.fieldList[j].procFieldNo == fieldItems[k].field.fieldList[l].procFieldNo)
									{
										console.log("deleteRepeatList: " + this.tableDataList[row-1][keys[i]].field.fieldList[j].screeningEntryNo);
										this.deleteRepeatList.push(this.tableDataList[row-1][keys[i]].field.fieldList[j].screeningEntryNo);
									}
								}
							}
						}
					}
				}
			}

			let deleteCellName = [];
			for(let i=0; i < keys.length; i++){
				for(let j=0; j < backTableList.length; j++){
					let firstEng = backTableList[j].cellName.slice(0,1);
					let backRow = backTableList[j].cellName.slice(1,10);
					let copyRow = Number(backRow) - Number(repeatRow);
					let endName = firstEng +copyRow;
					if(keys[i] == endName){
						// console.log(this.tableDataList[row-1][keys[i]].cellName, endName);
						let backObject = JSON.parse(JSON.stringify(backTableList[j]));
						backObject.cellName = endName;
						if(backObject.repeatRoot != "" && backObject.repeatRoot != repeatRoot){
							let firstRoot = backObject.repeatRoot.slice(0,1);
							let backRoot =  backObject.repeatRoot.slice(1,10);
							let copyRoot = Number(backRoot) - Number(repeatRow);
							let endRoot = firstRoot + copyRoot;
							backObject.repeatRoot = endRoot;
						}
						this.$set(this.tableDataList[row-1], keys[i], backObject);
					}
				}
			}
			for(let i=0; i < keys.length; i++){
				let rowNum = this.tableDataList[row-1][keys[i]].cellName.slice(1,10);
				for(let k = Number(repeatRow)-1 ; k >= 0; k--){
					// console.log(this.tableDataList[row-1][keys[i]].cellName, rowNum);
					// console.log(Number(rowKeys.length)-k);
					if(rowNum == Number(rowKeys.length)-k){
						// console.log("deleteCellName : " + this.tableDataList[row-1][keys[i]].cellName);
						deleteCellName.push(this.tableDataList[row-1][keys[i]].cellName);
						// this.$delete(this.tableDataList[row-1], this.tableDataList[row-1][keys[i]].cellName);
					}
				}
			}
			for(let i=0; i < keys.length; i++){
				for(let j=0; j < deleteCellName.length; j++){
					if(keys[i] == deleteCellName[j]){
						this.$delete(this.tableDataList[row-1], keys[i]);
					}
				}
			}
			
			let keys2 = Object.keys(this.tableDataList[row-1]);
			for(let i = 0; i < keys2.length; i++){
				if(this.tableDataList[row-1][keys2[i]].isCopy && Object.keys(this.tableDataList[row-1][keys2[i]].field).length > 0 && !this.tableDataList[row-1][keys2[i]].isHeader){
					for(let j = 0; j < this.tableDataList[row-1][keys2[i]].field.fieldList.length; j++){
						if(this.tableDataList[row-1][keys2[i]].field.fieldList[j].repeatSortOrder > sortOrder){
							for(let k = 0; k < fieldItems.length; k++){
								for(let l = 0; l < fieldItems[k].field.fieldList.length; l++){
									if(fieldItems[k].field.fieldList[l].procFieldNo == this.tableDataList[row-1][keys2[i]].field.fieldList[j].procFieldNo){
										this.$set(this.tableDataList[row-1][keys2[i]].field.fieldList[j], "repeatSortOrder", this.tableDataList[row-1][keys2[i]].field.fieldList[j].repeatSortOrder - 1);
									}
								}
							}
						}
					}
				}
			}

			let endtableRow = Number(this.surveyItemList[row-1].tableRow)-Number(repeatRow);
			console.log("endtableRow : " + endtableRow);
			this.$set(this.surveyItemList[row-1], "tableRow", endtableRow);
		},
		repeat(repeatRoot, row){
			console.log(repeatRoot, row, this.surveyItemList[row-1].tableCol);
			let keys = Object.keys(this.tableDataList[row-1]);
			let repeatCount = 0;
			let fieldCount = 0;
			let repeatItems = [];
			let fieldItems = [];

			for(let i =0; i < keys.length; i++){
				if(this.tableDataList[row-1][keys[i]].repeatRoot == repeatRoot && !this.tableDataList[row-1][keys[i]].isCopy){
					repeatCount += 1;
					repeatItems.push(JSON.parse(JSON.stringify(this.tableDataList[row-1][keys[i]])));
				}
				if(this.tableDataList[row-1][keys[i]].repeatRoot == repeatRoot && !this.tableDataList[row-1][keys[i]].isCopy && Object.keys(this.tableDataList[row-1][keys[i]].field).length > 0 && !this.tableDataList[row-1][keys[i]].isHeader){
					fieldCount += 1;
					fieldItems.push(JSON.parse(JSON.stringify(this.tableDataList[row-1][keys[i]].field)));
				}
			}
			
			console.log(fieldItems);
			console.log("fieldCount : " + fieldCount);

			console.log("repeatCOunt : " + repeatCount);
			console.log(repeatItems);
			let startRow = repeatRoot.slice(1,10);
			let repeatRow = repeatCount/this.surveyItemList[row-1].tableCol;
			let endRow = Number(startRow) + Number(repeatCount/this.surveyItemList[row-1].tableCol);
			console.log("tableCol : " + this.surveyItemList[row-1].tableCol);
			console.log("start num : " + startRow);
			console.log("repeatRow : " +repeatRow);
			console.log("endRow : " + endRow);
			console.log("tableRow : " + this.surveyItemList[row-1].tableRow);

			const backNameList = [];
			for(let i = 0; i < keys.length; i++){
				for(let j = endRow; j <= this.surveyItemList[row-1].tableRow ; j++){
					if(this.tableDataList[row-1][keys[i]].cellName.slice(1,10) == j){
						backNameList.push(JSON.parse(JSON.stringify(this.tableDataList[row-1][keys[i]])));
					}
				}
			}

			// if(backNameList.length <= 0){
			if(backNameList.length <= repeatCount){
				for(let i = 0; i < keys.length; i++){
					// if(this.tableDataList[row-1][keys[i]].cellName.slice(1) == this.surveyItemList[row-1].tableRow){
					// 	let colName = this.tableDataList[row-1][keys[i]].cellName.slice(0,1);
					// 	let rowName = this.tableDataList[row-1][keys[i]].cellName.slice(1,10);
					// 	let addRowName = Number(rowName) + Number(repeatRow);
					// 	let endName = colName + addRowName;
					// 	let object = {};
					// 	this.$set(this.tableDataList[row-1], endName, object);
					// }
					if(this.tableDataList[row-1][keys[i]].cellName.slice(1) == this.surveyItemList[row-1].tableRow){
						let colName = this.tableDataList[row-1][keys[i]].cellName.slice(0,1);
						let rowName = this.tableDataList[row-1][keys[i]].cellName.slice(1,10);
						console.log("eq: " + repeatRow + " " + backNameList.length/this.surveyItemList[row-1].tableCol)
						for(let j = 1; j <= repeatRow - backNameList.length/this.surveyItemList[row-1].tableCol; j++){
							console.log("j: " + j)
							let addRowName = Number(rowName) + Number(j);
							let endName = colName + addRowName;
							let object = {};
							this.$set(this.tableDataList[row-1], endName, object);
						}
					}
				}
			}

			let rowInfo = this.rowInfoList[row-1];
			let rowbackList = []; 
			for(let j = endRow; j <= this.surveyItemList[row-1].tableRow ; j++){
				rowbackList.push(JSON.parse(JSON.stringify(rowInfo[j])));
				// let rowNum = Number(j) + Number(repeatRow);
				// this.$set(rowInfo, rowNum, rowInfo[j]);
			}
			for(let j = endRow; j <= this.surveyItemList[row-1].tableRow ; j++){
				let rowNum = Number(j) + Number(repeatRow);
				this.$set(rowInfo, rowNum, rowbackList[j-endRow]);
			}
			for(let j = startRow; j < endRow; j++){
				let rowNum = Number(j) + Number(repeatRow);
				this.$set(rowInfo, rowNum, rowInfo[j]);
			}

			if(backNameList.length > 0){
				for(let i = 0; i < backNameList.length; i++){
					let colName = backNameList[i].cellName.slice(0,1);
					let rowName = backNameList[i].cellName.slice(1,10);
					let addRowName = Number(rowName) + Number(repeatRow);
					let endName = colName + addRowName;
					
					backNameList[i].cellName = endName;
					if(backNameList[i].repeatRoot != "" && backNameList[i].repeatRoot != repeatRoot){
						let firstRoot = backNameList[i].repeatRoot.slice(0,1);
						let lastRoot = backNameList[i].repeatRoot.slice(1,10);
						let plusRoot = Number(lastRoot) + Number(repeatRow);
						let endRoot = firstRoot + plusRoot;
						backNameList[i].repeatRoot = endRoot;
					}
					if(Object.keys(backNameList[i].field).length > 0 && !backNameList[i].isHeader){
						for(let j = 0; j < fieldItems.length; j++){
							for(let l = 0; l < fieldItems[j].fieldList.length; l++){
								for(let k =0 ; k < backNameList[i].field.fieldList.length; k++){
									if(Number(backNameList[i].field.fieldList[k].repeatSortOrder) != 1 && backNameList[i].field.fieldList[k].procFieldNo == fieldItems[j].fieldList[l].procFieldNo){
										let afterSortOrder = Number(backNameList[i].field.fieldList[k].repeatSortOrder) + 1;
										console.log("after sort order >" + afterSortOrder);
										backNameList[i].field.fieldList[k].repeatSortOrder = Number(backNameList[i].field.fieldList[k].repeatSortOrder) + 1;
										this.$set(backNameList[i].field.fieldList[k], "repeatSortOrder", afterSortOrder);
									}
								}
							}
						}
					}
					console.log("backNameList[i]");
					console.log(backNameList[i]);
					console.log("endname  : " + endName);
					this.$set(this.tableDataList[row-1], endName, backNameList[i]);
				}
			}

			for(let i = 0; i < repeatItems.length; i++){
				let colName = repeatItems[i].cellName.slice(0,1);
				let rowName = repeatItems[i].cellName.slice(1,10);
				let addRowName = Number(rowName) + Number(repeatRow);
				let endName = colName + addRowName;
				repeatItems[i].cellName = endName;
				repeatItems[i].isCopy = true;
				for(let f = 0; f < Object.keys(repeatItems[i]).length; f++){
					if(Object.keys(repeatItems[i])[f] != "field"){
						// if(Object.keys(repeatItems[i])[f] == "cellName"){
						// 	repeatItems[i][Object.keys(repeatItems[i])[f]] = endName;
						// }
						// if(Object.keys(repeatItems[i])[f] == "isCopy"){
						// 	repeatItems[i][Object.keys(repeatItems[i])[f]] = true;
						// }
						this.$set(this.tableDataList[row-1][endName], Object.keys(repeatItems[i])[f], repeatItems[i][Object.keys(repeatItems[i])[f]]);
					}
					else{
						this.$set(this.tableDataList[row-1][endName], Object.keys(repeatItems[i])[f], repeatItems[i][Object.keys(repeatItems[i])[f]]);
						if(Object.keys(repeatItems[i].field).length > 0 && !repeatItems[i].isHeader){
							for(let j = 0; j < repeatItems[i].field.fieldList.length; j++){
								let afterSortOrder = Number(repeatItems[i].field.fieldList[j].repeatSortOrder) + 1;
								repeatItems[i].field.fieldList[j].repeatSortOrder = Number(repeatItems[i].field.fieldList[j].repeatSortOrder) + 1;
								this.$set(repeatItems[i].field.fieldList[j], "repeatSortOrder", afterSortOrder);
								console.log(endName ,repeatItems[i].field.fieldList[j].repeatSortOrder);
							}
						}
						if(Object.keys(repeatItems[i].field).length > 0){
							for(let p = 0; p < Object.keys(repeatItems[i].field).length; p++){
								if(Object.keys(repeatItems[i].field)[p] != "fieldList"){
									this.$set(this.tableDataList[row-1][endName].field, Object.keys(repeatItems[i].field)[p], repeatItems[i].field[Object.keys(repeatItems[i].field)[p]]);
								}
								else{
									for(let k = 0; k < repeatItems[i].field.fieldList.length; k++){
										for(let t = 0; t < Object.keys(repeatItems[i].field.fieldList[k]).length; t++){
											// console.log(Object.keys(repeatItems[i].field.fieldList[k])[t]);
											if(Object.keys(repeatItems[i].field.fieldList[k])[t] == "fieldValue"){
												console.log("VALUE : " + this.tableDataList[row-1][endName].field.fieldList[k][Object.keys(repeatItems[i].field.fieldList[k])[t]] + endName);
												if(repeatItems[i].field.fieldList[k].fieldTypeCd == "04"){
													let arr = [];
													this.$set(this.tableDataList[row-1][endName].field.fieldList[k], Object.keys(repeatItems[i].field.fieldList[k])[t], arr);
												}
												else{
													console.log(repeatItems[i].field.fieldList[k].fieldTypeCd);
													this.$set(this.tableDataList[row-1][endName].field.fieldList[k], Object.keys(repeatItems[i].field.fieldList[k])[t], "");
												}
											}
											if(Object.keys(repeatItems[i].field.fieldList[k])[t] == "fieldValueTemp"){
												this.$set(this.tableDataList[row-1][endName].field.fieldList[k], Object.keys(repeatItems[i].field.fieldList[k])[t], "");
											}

											this.$set(this.tableDataList[row-1][endName].field.fieldList[k], Object.keys(repeatItems[i].field.fieldList[k])[t], repeatItems[i].field.fieldList[k][Object.keys(repeatItems[i].field.fieldList[k])[t]]);
										}
									}
								}
							}
						}
						else{
							this.$set(this.tableDataList[row-1][endName], "field", repeatItems[i].field);
						}
					}
				}
			}
			let endtableRow = Number(this.surveyItemList[row-1].tableRow)+Number(repeatRow);
			console.log("endtableRow : " + endtableRow);
			this.$set(this.surveyItemList[row-1], "tableRow", endtableRow);

			// this.setDownCopy(repeatRoot, row, fieldItems, repeatRow, fieldCount);
		},
		setDownCopy(repeatRoot, row, fieldItems, repeatRow, fieldCount){
			console.log("repeatrow : " + repeatRow);
			let copyTable = JSON.parse(JSON.stringify(this.tableDataList[row-1]));
			let keys = Object.keys(copyTable);
			let sortCount = 0;
			for(let i =0; i < keys.length; i++){
				console.log("keys : " + keys[i]);
				if(this.tableDataList[row-1][keys[i]].repeatRoot == repeatRoot && this.tableDataList[row-1][keys[i]].isCopy && Object.keys(this.tableDataList[row-1][keys[i]].field).length > 0 && !this.tableDataList[row-1][keys[i]].isHeader){
					sortCount += 1;
					for(let j = 0; j < fieldItems.length; j++){
						for(let k = 0; k < fieldItems[j].fieldList.length; k++){
							for(let l = 0; l < this.tableDataList[row-1][keys[i]].field.fieldList.length; l++){
								if(fieldItems[j].fieldList[k].procFieldNo == this.tableDataList[row-1][keys[i]].field.fieldList[l].procFieldNo && this.tableDataList[row-1][keys[i]].field.fieldList[l].repeatSortOrder > 2){
									let front = keys[i].slice(0, 1);
									let back = Number(keys[i].slice(1, 10)) - repeatRow;
									let endName = front + back;
									console.log("endName : " + endName);
									this.$set(this.tableDataList[row-1][endName].field.fieldList[l], "fieldValue", copyTable[keys[i]].field.fieldList[l].fieldValue);
									// this.$set(this.tableDataList[row-1][endName].field.fieldList[l].fieldValueDate, "0", copyTable[keys[i]].field.fieldList[l].fieldValueDate[0]);
									// this.$set(this.tableDataList[row-1][endName].field.fieldList[l].fieldValueDate, "1", copyTable[keys[i]].field.fieldList[l].fieldValueDate[1]);
									// this.$set(this.tableDataList[row-1][endName].field.fieldList[l].fieldValueDate, "2", copyTable[keys[i]].field.fieldList[l].fieldValueDate[2]);
									// this.$set(this.tableDataList[row-1][endName].field.fieldList[l].fieldValueTime, "0", copyTable[keys[i]].field.fieldList[l].fieldValueTime[0]);
									// this.$set(this.tableDataList[row-1][endName].field.fieldList[l].fieldValueTime, "1", copyTable[keys[i]].field.fieldList[l].fieldValueTime[1]);
									// this.$set(this.tableDataList[row-1][endName].field.fieldList[l].fieldValueTime, "2", copyTable[keys[i]].field.fieldList[l].fieldValueTime[2]);
									this.$set(this.tableDataList[row-1][endName].field.fieldList[l], "repeatSortOrder", this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValue)
								}

							}
						}
					}
				}
			}
			console.log("sortCount : " + sortCount);
			console.log("scount/fieldcount : " + (sortCount/fieldCount+1));
			console.log(fieldItems);
			keys = Object.keys(this.tableDataList[row-1]);
			for(let i =0; i < keys.length; i++){
				if(this.tableDataList[row-1][keys[i]].repeatRoot == repeatRoot && this.tableDataList[row-1][keys[i]].isCopy && Object.keys(this.tableDataList[row-1][keys[i]].field).length > 0 && !this.tableDataList[row-1][keys[i]].isHeader){
					for(let j = 0; j < fieldItems.length; j++){
						for(let k = 0; k < fieldItems[j].fieldList.length; k++){
							for(let l = 0; l < this.tableDataList[row-1][keys[i]].field.fieldList.length; l++){
								if(fieldItems[j].fieldList[k].procFieldNo == this.tableDataList[row-1][keys[i]].field.fieldList[l].procFieldNo && this.tableDataList[row-1][keys[i]].field.fieldList[l].repeatSortOrder == (sortCount/fieldCount+1)){
									this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l], "fieldValue", "");
									// this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValueDate, "0", null);
									// this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValueDate, "1", null);
									// this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValueDate, "2", null);
									// this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValueTime, "0", null);
									// this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValueTime, "1", null);
									// this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValueTime, "2", null);
									this.$set(this.tableDataList[row-1][keys[i]].field.fieldList[l], "repeatSortOrder", this.tableDataList[row-1][keys[i]].field.fieldList[l].fieldValue)
								}
							}
						}
					}
				}
			}
		},
		validAction(type, procNo, procQuestionNo, index, cellName, fieldIndex, fieldTypeCd, field){
			if(type == 'PROC'){
				// proc 단위 있을시 disable
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultDsaList.length; j++){
						let dsaVo = detailVo.validResultDsaList[j];
						if(dsaVo.disabledTypeCd == 'PROC'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
								
								if(this.param.visitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == procNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
									return 'disabled'
								}
								if(dsaDetailVo.visitNo == 0){
									if(dsaDetailVo.procNo == procNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
										return 'disabled'
									}
								}
							}
						}
					}
				}
				// question 단위 있을시 disable
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultDsaList.length; j++){
						let dsaVo = detailVo.validResultDsaList[j];
						if(dsaVo.disabledTypeCd == 'QUESTION'){
							for(let k = 0; k < dsaVo.validResultDsaDetailList.length; k++){
								let dsaDetailVo = dsaVo.validResultDsaDetailList[k];
	
								if(this.param.visitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == procNo && dsaDetailVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
									return 'disabled'
								}
								if(dsaDetailVo.visitNo == 0){
									if(dsaDetailVo.procNo == procNo && dsaDetailVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
										return 'disabled'
									}
								}
							}
						}
					}
				}
				// hide question 단위 있을시 disable
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultHideList.length; j++){
						let hideVo = detailVo.validResultHideList[j];
						if(hideVo.hideTypeCd == 'QUESTION'){
							for(let k = 0; k < hideVo.validResultHideDetailList.length; k++){
								let hideDetailVo = hideVo.validResultHideDetailList[k];
	
								if(this.param.visitNo == hideDetailVo.visitNo && hideDetailVo.procNo == procNo && hideDetailVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
									return 'hide'
								}
								if(hideDetailVo.visitNo == 0){
									if(hideDetailVo.procNo == procNo && hideDetailVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
										return 'hide'
									}
								}
							}
						}
					}
				}
			}
			else if(type == 'MSG'){
				// message type일시 출력 데이터 리턴
				let msgList = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;

					for(let j = 0;  j < detailVo.validResultMsgList.length; j++){
						let msgVo = detailVo.validResultMsgList[j];
						if(msgVo.printTypeCd == 'MESSAGE'){
							if(this.param.visitNo == msgVo.visitNo && msgVo.procNo == procNo && msgVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
								msgList.push(msgVo);
							}
							if(msgVo.visitNo == 0){
								if(msgVo.procNo == procNo && msgVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
									msgList.push(msgVo);
								}
							}
						}
					}
				}
				if(field.queryConfirmList.length > 0){
					return msgList;
				}
				else{
					if(this.validAction('PROC', procNo, procQuestionNo, index, cellName, fieldIndex, fieldTypeCd, field) != 'disabled'){
						msgList = [];
						return msgList;
					}
					else{
						return msgList;
					}
				}
			}
			else if(type == 'POPUP'){
				// popup type일시 출력 데이터 리턴
				let cnt = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultMsgList.length; j++){
						let msgVo = detailVo.validResultMsgList[j];
						if(msgVo.printTypeCd == 'POPUP' && msgVo.visitNo == this.param.visitNo && msgVo.pageNo == this.param.pageNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
							cnt.push(msgVo.printMsg);
						}
						if(msgVo.visitNo == 0){
							if(msgVo.printTypeCd == 'POPUP' && msgVo.pageNo == this.param.pageNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
								cnt.push(msgVo.printMsg);
							}
						}
					}
				}
				for(let i = 0 ; i < cnt.length; i++){
					alert(cnt[i], "Warning", "warning");
				}
			}
			else if(type == 'GOTO'){
				let gotoList = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultGotoList.length; j++){
						let gotoVo = detailVo.validResultGotoList[j];
						if(gotoVo.pageTypeCd == 'GOTO'){
							if(this.param.visitNo == gotoVo.visitNo && gotoVo.procNo == procNo && gotoVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
								gotoList.push(gotoVo)
							}
							if(gotoVo.visitNo == 0){
								if(gotoVo.procNo == procNo && gotoVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
									gotoList.push(gotoVo)
								}
							}
						}
					}
				}
				return gotoList;
			}
			else if(type == 'DYNAMIC'){
				let dynamicList = [];
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validResultGotoList.length; j++){
						let dynamicVo = detailVo.validResultGotoList[j];
						if(dynamicVo.pageTypeCd == 'DYNAMIC'){
							if(this.param.visitNo == dynamicVo.visitNo && dynamicVo.procNo == procNo && dynamicVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
								dynamicList.push(dynamicVo)
							}
							if(dynamicVo.visitNo == 0){
								if(dynamicVo.procNo == procNo && dynamicVo.procQuestionNo == procQuestionNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
									dynamicList.push(dynamicVo)
								}
							}
						}
					}
				}
				return dynamicList;
			}
			else if(type == 'LINK'){
				for(let i = 0; i < this.applyValidVoList.length; i++){
					let detailVo = this.applyValidVoList[i].validDetailVo;
	
					for(let j = 0;  j < detailVo.validDataLinkList.length; j++){
						let linkVo = detailVo.validDataLinkList[j];
						
						for(let l = 0; l < this.tableDataList.length; l++)
						{
							let keys = Object.keys(this.tableDataList[l]);
							for(let t = 0; t < keys.length; t++)
							{
								if(!this.tableDataList[l][keys[t]].isHeader && Object.keys(this.tableDataList[l][keys[t]].field).length > 0)
								{
									let fieldNewList = this.tableDataList[l][keys[t]].field.fieldList;
									for(let k = 0; k < fieldNewList.length; k++)
									{
										if(this.param.pageNo == linkVo.pageNo && this.param.visitNo == linkVo.visitNo){
											if(linkVo.procQuestionNo == fieldNewList[k].procQuestionNo && linkVo.procFieldNo == fieldNewList[k].procFieldNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
												if(linkVo.targetTypeCd == 'VALUE'){
													if(linkVo.ogFieldTypeCd == '03' || linkVo.ogFieldTypeCd == '04' || linkVo.ogFieldTypeCd == '05'){
														var value = [];
														value.push(linkVo.targetValue);
														fieldNewList[k].fieldValue = value;
													}else{
														fieldNewList[k].fieldValue = linkVo.targetValue;
													}
												}
												else{
													fieldNewList[k].fieldValue = linkVo.fieldValue;
												}
											}
										}
										if(linkVo.visitNo == 0){
											if(this.param.pageNo == linkVo.pageNo){
												if(linkVo.procQuestionNo == fieldNewList[k].procQuestionNo && linkVo.procFieldNo == fieldNewList[k].procFieldNo && this.applyValidVoList[i].repeatSortOrder == field.repeatSortOrder){
													if(linkVo.targetTypeCd == 'VALUE'){
														if(linkVo.ogFieldTypeCd == '03' || linkVo.ogFieldTypeCd == '04' || linkVo.ogFieldTypeCd == '05'){
															value = [];
															value.push(linkVo.targetValue);
															fieldNewList[k].fieldValue = value;
														}else{
															fieldNewList[k].fieldValue = linkVo.targetValue;
														}
													}
													else{
														fieldNewList[k].fieldValue = linkVo.fieldValue;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		cellStyle(index, id) {
			let style = {};
			let cell = this.tableDataList[index][id];

			if(cell.isExpand && cell.expandCol < 2 && cell.expandRow < 2)
			{
				style.display = "none";
			}
			if(cell.isHeader)
			{
				style.backgroundColor = "#f5f5f5";
				style.fontWeight = "600";
			}

			style.textAlign = cell.align;
			style.justifyContent = cell.align;

			return style;
		},
		getFieldList(index, id) {
			if(Object.keys(this.tableDataList[index][id].field).length !== 0)
			{
				return this.tableDataList[index][id].field.fieldList;
			}
			else
			{
				return [];
			}
		},
		getMargin(field) {
			var prefixName = field.prefixName;
			var suffixName = field.suffixName;
			var fieldLayout = field.fieldLayoutCd;

			var topMargin = 0;
			var bottomMargin = 0;
			var leftMargin = 0;
			var rightMargin = 0;

			if (fieldLayout == "1")
			{
				if (prefixName != null && prefixName != "") topMargin = 5;
				if (suffixName != null && suffixName != "") bottomMargin = 5;
			}
			else
			{
				if (prefixName != null && prefixName != "") leftMargin = 10;
				if (suffixName != null && suffixName != "") rightMargin = 10;
			}

			return {
				marginTop: topMargin + "px",
				marginBottom: bottomMargin + "px",
				marginLeft: leftMargin + "px",
				marginRight: rightMargin + "px",
			};
		},
		getGridRowCol(length, cols) {
			var value = parseInt(Number(length) / Number(cols));
			var etc = Number(length) % Number(cols);

			var row = 0;
			var col = cols;
			if (etc == 0) row = value;
			else row = value + 1;

			return {
				gridTemplateRows: "repeat(" + row + ", minmax(36px, auto))",
				gridTemplateColumns: "repeat(" + col + ", auto)",
			};
		},
		loadedImage(field) {
			this.$set(field, 'isLoad', true);
		},
		isNumeric(v) {
			return /^\d+$/.test(v);
		},
		dateCheck(event, length) {
			console.log(event);
			let value = event.target.value;
			if(!(value == "UK") && !this.isNumeric(value))
			{
				event.target.value = "";
			}
			if(value != "UK" && value.length != length)
			{
				event.target.value = "";
			}
		},
		getProcQuestionIndex(index, cellId) {
			let tableData = this.tableDataList[index];
			let procQuestionNo = tableData[cellId].field.procQuestionNo;
			// let repeatSortOrder = tableData[cellId].field.fieldList[0].repeatSortOrder;
			let procQuestionList = this.surveyItemList[index].procQuestionList.map((obj) => {
				let endname = obj.procQuestionNo;
				return endname;
			});
			let getIndex = procQuestionList.indexOf(procQuestionNo);
			console.log("cellId = " + cellId + ", getIndex : " + getIndex);
			return Number(getIndex);
		},
		// getProcQuestionIndex(index, cellId) {
		// 	let tableData = this.tableDataList[index];
		// 	let procQuestionNo = tableData[cellId].field.procQuestionNo;
		// 	let repeatSortOrder = tableData[cellId].field.fieldList[0].repeatSortOrder;
		// 	let procQuestionList = this.surveyItemList[index].procQuestionList.map((obj) => {
		// 		let endname = obj.procQuestionNo + obj.fieldList[0].repeatSortOrder;
		// 		return endname;
		// 	});
		// 	let getIndex = procQuestionList.indexOf(procQuestionNo+repeatSortOrder);
		// 	console.log("cellId = " + cellId + ", getIndex : " + getIndex);
		// 	return Number(getIndex);
		// },
		doValidation(statusCd) {
			this.logger.debug(this, "doValidation()");

			// this.dataRequiredCheck();
			// this.validAction("POPUP");

			if(statusCd == '2')
			{
				this.$confirm("Do you want to temp save the data?", "Question", "question").then(() => {
					this.doInsert(statusCd);
				});
			}
			else
			{
				this.$validator
					.validateAll()
					.then((result) => {
						this.logger.debug(this, "doValidationResult:" + result);
						if (statusCd == '3') {
							if(!result){
								return false;
							}
							else{
								this.$confirm("Do you want to save the data?", "Question", "question").then(() => {
									this.doInsert(statusCd);
								});
							}
						}
						if (statusCd == '5') {
							if(!result){
								return false;
							}
							else{
								if(this.queryConfirmCheck()){
									this.$alert("Not all queries answered.", "Warn", "warning");
								}
								else{
									this.$confirm("Do you want to edit the data?", "Question", "question").then(() => {
										this.doInsert(statusCd);
									});
								}
							}
						}
					})
					.catch(() => {
						this.logger.debug(this, "-->" + this.errors.all());
						alert(this.errors.all());
						return false;
					});
			}
		},
		queryConfirmCheck(){
			let result = false;
			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
					{
						let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
						for(let k = 0; k < fieldNewList.length; k++)
						{
							if(fieldNewList[k].queryConfirmList.length > 0){
								result = true;
							}
						}
					}
				}
			}
			return result;
		},
		doValidCheck(calculateResultList){
			this.logger.debug(this, "doValidCheck()");
			this.procFieldList = [];
			let param = {
				testScreeningNo : this.param.selectedScreeningNo,
				projectNo : this.$route.params.projectNo,
				pageNo : this.param.pageNo,
				visitNo : this.param.visitNo,
				procFieldList : this.procFieldList,
				deleteRepeatList : this.deleteRepeatList,
				calculateResultList : calculateResultList
			};

			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
					{
						let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
						for(let k = 0; k < fieldNewList.length; k++)
						{
							//disabel,hide 여부 확인후 fieldList 작업
							for(let l = 0; l < this.applyValidVoList.length; l++){
								let detailVo = this.applyValidVoList[l].validDetailVo;

								for(let m = 0;  m < detailVo.validResultDsaList.length; m++){
									let dsaVo = detailVo.validResultDsaList[m];
									if(dsaVo.disabledTypeCd == 'PROC'){
										for(let n = 0; n < dsaVo.validResultDsaDetailList.length; n++){
											let dsaDetailVo = dsaVo.validResultDsaDetailList[n];

											if(this.param.visitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo){
												if(dsaVo.dataRemoveYn == 'Y'){
													switch(fieldNewList[k].fieldTypeCd) {
														case "04":
															this.$set(fieldNewList[k], "fieldValue", []);
															break;
														case "08":
															this.$set(fieldNewList[k], "fieldValueTemp", "");
															// eslint-disable-next-line no-fallthrough
														default:
															this.$set(fieldNewList[k], "fieldValue", "");
													}
												}
												this.$set(fieldNewList[k], "disableYn", "Y");
											}
											if(dsaDetailVo.visitNo == 0){
												if(dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo){
													if(dsaVo.dataRemoveYn == 'Y'){
														switch(fieldNewList[k].fieldTypeCd) {
															case "04":
																this.$set(fieldNewList[k], "fieldValue", []);
																break;
															case "08":
																this.$set(fieldNewList[k], "fieldValueTemp", "");
																// eslint-disable-next-line no-fallthrough
															default:
																this.$set(fieldNewList[k], "fieldValue", "");
														}
													}
													this.$set(fieldNewList[k], "disableYn", "Y");
												}
											}
										}
									}
								}
							}
							// question 단위 있을시 disable
							for(let l = 0; l < this.applyValidVoList.length; l++){
								let detailVo = this.applyValidVoList[l].validDetailVo;

								for(let m = 0;  m < detailVo.validResultDsaList.length; m++){
									let dsaVo = detailVo.validResultDsaList[m];
									if(dsaVo.disabledTypeCd == 'QUESTION'){
										for(let n = 0; n < dsaVo.validResultDsaDetailList.length; n++){
											let dsaDetailVo = dsaVo.validResultDsaDetailList[n];

											if(this.param.visitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && dsaDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
												if(dsaVo.dataRemoveYn == 'Y'){
													switch(fieldNewList[k].fieldTypeCd) {
														case "04":
															this.$set(fieldNewList[k], "fieldValue", []);
															break;
														case "08":
															this.$set(fieldNewList[k], "fieldValueTemp", "");
															// eslint-disable-next-line no-fallthrough
														default:
															this.$set(fieldNewList[k], "fieldValue", "");
													}
												}
												this.$set(fieldNewList[k], "disableYn", "Y");
											}
											if(dsaDetailVo.visitNo == 0){
												if(dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && dsaDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
													if(dsaVo.dataRemoveYn == 'Y'){
														switch(fieldNewList[k].fieldTypeCd) {
															case "04":
																this.$set(fieldNewList[k], "fieldValue", []);
																break;
															case "08":
																this.$set(fieldNewList[k], "fieldValueTemp", "");
																// eslint-disable-next-line no-fallthrough
															default:
																this.$set(fieldNewList[k], "fieldValue", "");
														}
													}
													this.$set(fieldNewList[k], "disableYn", "Y");
												}

											}
										}
									}
								}
							}
							// hide question 단위 있을시 disable
							for(let l = 0; l < this.applyValidVoList.length; l++){
								let detailVo = this.applyValidVoList[l].validDetailVo;

								for(let m = 0;  m < detailVo.validResultHideList.length; m++){
									let hideVo = detailVo.validResultHideList[m];
									if(hideVo.hideTypeCd == 'QUESTION'){
										for(let n = 0; n < hideVo.validResultHideDetailList.length; n++){
											let hideDetailVo = hideVo.validResultHideDetailList[n];

											if(this.param.visitNo == hideDetailVo.visitNo && hideDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && hideDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
												this.$set(fieldNewList[k], "disableYn", "Y");
											}
											if(hideDetailVo.visitNo == 0){
												if(hideDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && hideDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
													this.$set(fieldNewList[k], "disableYn", "Y");
												}
											}
										}
									}
								}
							}

							let item = {
								pageNo: this.param.pageNo,
								visitNo:this.param.visitNo,
								procFieldNo:fieldNewList[k].procFieldNo,
								procQuestionNo:fieldNewList[k].procQuestionNo,
								repeatSortOrder: fieldNewList[k].repeatSortOrder,
								testScreeningEntryNo: fieldNewList[k].testScreeningEntryNo,
								fieldValue: fieldNewList[k].fieldValue,
								selectDefault: fieldNewList[k].selectDefault,
								// fieldValueDate: fieldNewList[k].fieldValueDate,
								// fieldValueTime: fieldNewList[k].fieldValueTime,
								procNo : this.tableDataList[i][keys[j]].field.procNo,
								testScreeningNo : this.param.selectedScreeningNo,
								userId : this.userInfo.userId,
								fieldTypeCd : fieldNewList[k].fieldTypeCd,
								testScreeningInputNo : fieldNewList[k].testScreeningInputNo,
								fileList : fieldNewList[k].fileList,
								siteFileList : fieldNewList[k].siteFileList,
								dateSeperator : fieldNewList[k].dateSeperator,
								timeSeperator : fieldNewList[k].timeSeperator,
								dataTypeCd : fieldNewList[k].dataTypeCd,
								disableYn : fieldNewList[k].disableYn,
							};
							this.procFieldList.push(item);
						}
					}
				}
			}

			axios
				.post(this.config.contextRoot + "/testScreening/doValidIn.do", param)
				.then((response) => {
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);
						// this.disableList = response.data.disableList;
						// this.msgList = response.data.msgList;
						// this.gotoList = response.data.gotoList;
						// this.hideList = response.data.hideList;
						// this.validCount = (this.disableList.validCnt + this.msgList.validCnt + this.gotoList.validCnt + this.hideList.validCnt);
						this.doClose();
						location.reload();
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		instantValid(calculateResultList){
			this.logger.debug(this, "instantValid()");
			this.procFieldList = [];
			let param = {
				testScreeningNo : this.param.selectedScreeningNo,
				projectNo : this.$route.params.projectNo,
				pageNo : this.param.pageNo,
				visitNo : this.param.visitNo,
				procFieldList : this.procFieldList,
				deleteRepeatList : this.deleteRepeatList,
				calculateResultList : calculateResultList
			};

			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
					{
						let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
						for(let k = 0; k < fieldNewList.length; k++)
						{
							let item = {
								pageNo: this.param.pageNo,
								visitNo:this.param.visitNo,
								procFieldNo:fieldNewList[k].procFieldNo,
								procQuestionNo:fieldNewList[k].procQuestionNo,
								repeatSortOrder: fieldNewList[k].repeatSortOrder,
								testScreeningEntryNo: fieldNewList[k].testScreeningEntryNo,
								fieldValue: fieldNewList[k].fieldValue,
								selectDefault: fieldNewList[k].selectDefault,
								// fieldValueDate: fieldNewList[k].fieldValueDate,
								// fieldValueTime: fieldNewList[k].fieldValueTime,
								procNo : this.tableDataList[i][keys[j]].field.procNo,
								testScreeningNo : this.param.selectedScreeningNo,
								userId : this.userInfo.userId,
								fieldTypeCd : fieldNewList[k].fieldTypeCd,
								testScreeningInputNo : fieldNewList[k].testScreeningInputNo,
								fileList : fieldNewList[k].fileList,
								siteFileList : fieldNewList[k].siteFileList,
								dateSeperator : fieldNewList[k].dateSeperator,
								timeSeperator : fieldNewList[k].timeSeperator,
								dataTypeCd : fieldNewList[k].dataTypeCd,
							};
							this.procFieldList.push(item);
						}
					}
				}
			}

			axios
				.post(this.config.contextRoot + "/testScreening/instantValid.do", param)
				.then((response) => {
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);
						this.applyValidVoList = response.data.applyValidVoList;
						this.validAction('LINK');
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		validApply(){
			this.logger.debug(this, "validApply()");
			
			let param = {
				testScreeningNo : this.param.selectedScreeningNo,
				projectNo : this.$route.params.projectNo,
				pageNo : this.param.pageNo,
				visitNo : this.param.visitNo,
			};

			axios
				.post(this.config.contextRoot + "/testScreening/validApply.do", param)
				.then((response) => {
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);
						// this.validCount = (this.disableList.validCnt + this.msgList.validCnt + this.gotoList.validCnt + this.hideList.validCnt);
						if(response.data.applyValidVoList.length > 0)
						{
							this.applyValidVoList = response.data.applyValidVoList;
							this.$nextTick(function(){
								this.validAction('LINK');
								this.validAction('POPUP');
							});
						}
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doValidCalc(instant){
			this.logger.debug(this, "doValidCalc()");
			let param = {
				testScreeningNo : this.param.selectedScreeningNo,
				projectNo : this.$route.params.projectNo,
				pageNo : this.param.pageNo,
				visitNo : this.param.visitNo,
			};

			axios
				.post(this.config.contextRoot + "/testScreening/validCalc.do", param)
				.then((response) => {
					if(response.data.calculateVoList.length > 0){
						this.calculateVoList = response.data.calculateVoList;
						var calcResultObjList = [];
						var repeatCount = this.findValidCalcRepeat();
						
						setTimeout(function() {
							this.setValidCalcValue(param, calcResultObjList, repeatCount);
							if(instant)	{
								this.instantValid(calcResultObjList);
							}
							else {
								this.doValidCheck(calcResultObjList);
							}
						}.bind(this), 0);
					}
					else{
						if(instant)	{
							this.instantValid(calcResultObjList);
						}
						else {
							this.doValidCheck(calcResultObjList);
						}
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		setValidCalcValue(param, calcResultObjList) {
			for(let CIndex = 0; CIndex < this.calculateVoList.length; CIndex++)
			{
				let calcVo = this.calculateVoList[CIndex];
				let calcGpLst = calcVo.calculateGroupList;
				let calcStr = "";

				for(let i = 0 ; i < calcGpLst.length ; i++) {
					calcStr += "(";

					let calcDtLst = calcGpLst[i].calculateDetailList;
					for(let j = 0 ; j < calcDtLst.length ; j++) {
						let fdTypCd = calcDtLst[j].fieldTypeCd;
						let fieldValue = "";
						let fieldValueParse = "";

						switch (fdTypCd) {
							case "VALUE":
								fieldValue = calcDtLst[j].fieldValue;
								break;
							case "FIELD":
								//Field가 같은 Visit 내에 있는 경우
								if(calcDtLst[j].visitNo == param.visitNo || calcDtLst[j].visitNo == '0' && calcDtLst[j].pageNo == param.pageNo)
								{
									outerFor: for(let k = 0; k < this.tableDataList.length; k++)
									{
										let keys = Object.keys(this.tableDataList[k]);
										for(let l = 0; l < keys.length; l++)
										{
											if(!this.tableDataList[k][keys[l]].isHeader && !this.tableDataList[k][keys[l]].isRepeat 
												&& Object.keys(this.tableDataList[k][keys[l]].field).length > 0 && calcDtLst[j].procNo == this.tableDataList[k][keys[l]].field.procNo 
												&& calcDtLst[j].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo)
											{
												fieldValue = this.tableDataList[k][keys[l]].field.fieldList[0].fieldValue;
											}
											else if(!this.tableDataList[k][keys[l]].isHeader && this.tableDataList[k][keys[l]].isRepeat 
												&& Object.keys(this.tableDataList[k][keys[l]].field).length > 0 && calcDtLst[j].procNo == this.tableDataList[k][keys[l]].field.procNo 
												&& calcDtLst[j].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo
												&& this.tableDataList[k][keys[l]].field.fieldList[0].isCalcRepeat)
											{
												fieldValue = this.tableDataList[k][keys[l]].field.fieldList[0].fieldValue;
												this.$set(this.tableDataList[k][keys[l]].field.fieldList[0], "isCalcRepeat", false);
												break outerFor;
											}
										}
									}
								}
								break;
							case "FUNCTION":
								// for(let FIndex = 0; FIndex < calcDtLst[j].funcParamList.length; FIndex++)
								// {
								// 	if(calcDtLst[j].funcParamList[FIndex].visitNo == this.param.visitNo && calcDtLst[j].funcParamList[FIndex].pageNo == this.param.pageNo)
								// 	{
								// 		outerFor: for(let k = 0; k < this.tableDataList.length; k++)
								// 		{
								// 			let keys = Object.keys(this.tableDataList[k]);
								// 			for(let l = 0; l < keys.length; l++)
								// 			{
								// 				if(!this.tableDataList[k][keys[l]].isHeader && !this.tableDataList[k][keys[l]].isRepeat 
								// 					&& Object.keys(this.tableDataList[k][keys[l]].field).length > 0 
								// 					&& calcDtLst[j].funcParamList[FIndex].procNo == this.tableDataList[k][keys[l]].field.procNo 
								// 					&& calcDtLst[j].funcParamList[FIndex].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo)
								// 				{
								// 					fieldValue = this.executeFunction(calcDtLst[j].funcParamList, true);
								// 				}
								// 				else if(!this.tableDataList[k][keys[l]].isHeader && this.tableDataList[k][keys[l]].isRepeat 
								// 					&& Object.keys(this.tableDataList[k][keys[l]].field).length > 0 
								// 					&& calcDtLst[j].funcParamList[FIndex].procNo == this.tableDataList[k][keys[l]].field.procNo 
								// 					&& calcDtLst[j].funcParamList[FIndex].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo
								// 					&& this.tableDataList[k][keys[l]].field.fieldList[0].isCalcRepeat)
								// 				{
								// 					fieldValue = this.executeFunction(calcDtLst[j].funcParamList, true);
								// 					this.$set(this.tableDataList[k][keys[l]].field.fieldList[0], "isCalcRepeat", false);
								// 					break outerFor;
								// 				}
								// 			}
								// 		}
								// 	}
								// }
								fieldValue = this.executeFunction(calcDtLst[j].funcParamList, true);
								// if(!fieldValue)
								// {
								// 	// this.$alert("[Can't be calculated]\nOne or more values of the equation are missing or invalid.", "Warn", "warning");
								// 	return false;
								// }
								break;
							default:
								// this.$alert("[Can't be calculated]\nOne or more values of the equation are missing or invalid.", "Warn", "warning");
								// return false;
						}


						fieldValueParse = Number(fieldValue);
						// if(isNaN(fieldValueParse))
						// {
						// 	this.$alert("[Can't be calculated]\nOne or more values of the equation are missing or invalid.", "Warn", "warning");
						// 	return false;
						// }

						calcStr += fieldValueParse;
						calcStr += calcDtLst[j].fieldOperator;
					}
					calcStr += ")" + calcGpLst[i].groupOperator;
				}

				let calcResult = this.evalInContext(calcStr, this, true);
				let calcResultObj = {
					calculateNo: calcVo.calculateNo,
					projectNo: param.projectNo,
					calcResult: calcVo.calculateNo + "##" + calcResult
				}
				calcResultObjList.push(calcResultObj);
			}
		},
		findValidCalcRepeat() {
			this.calculateVoList.forEach((calcVo) =>
				{
					let calcGpLst = calcVo.calculateGroupList
					for(let i = 0 ; i < calcGpLst.length ; i++) {
						let calcDtLst = calcGpLst[i].calculateDetailList;
						for(let j = 0 ; j < calcDtLst.length ; j++) {
							let fdTypCd = calcDtLst[j].fieldTypeCd;
							if(fdTypCd == "FIELD")
							{
								if(calcDtLst[j].visitNo == this.param.visitNo || calcDtLst[j].visitNo == '0' && calcDtLst[j].pageNo == this.param.pageNo)
								{
									for(let k = 0; k < this.tableDataList.length; k++)
									{
										let keys = Object.keys(this.tableDataList[k]);
										for(let l = 0; l < keys.length; l++)
										{
											if(!this.tableDataList[k][keys[l]].isHeader && this.tableDataList[k][keys[l]].isRepeat 
												&& Object.keys(this.tableDataList[k][keys[l]].field).length > 0 
												&& calcDtLst[j].procNo == this.tableDataList[k][keys[l]].field.procNo 
												&& calcDtLst[j].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo)
											{
												this.$set(this.tableDataList[k][keys[l]].field.fieldList[0], "isCalcRepeat", true);
												if(this.tableDataList[k][keys[l]].field.fieldList[0].repeatSortOrder != 1){
													this.calculateVoList.push(calcVo);
												}
											}
										}
									}
								}
							}
							else if(fdTypCd == "FUNCTION")
							{
								let funcPrmLst = calcDtLst[j].funcParamList;
								for(let k = 0; k < funcPrmLst.length; k++)
								{
									if(funcPrmLst[k].visitNo == this.param.visitNo || funcPrmLst[k].visitNo == '0' && funcPrmLst[k].pageNo == this.param.pageNo)
									{
										for(let l = 0; l < this.tableDataList.length; l++)
										{
											let keys = Object.keys(this.tableDataList[l]);
											for(var m = 0; m < keys.length; m++)
											{
												if(!this.tableDataList[l][keys[m]].isHeader && this.tableDataList[l][keys[m]].isRepeat
													&& Object.keys(this.tableDataList[l][keys[m]].field).length > 0
													&& funcPrmLst[k].procNo == this.tableDataList[l][keys[m]].field.procNo 
													&& funcPrmLst[k].procQuestionNo == this.tableDataList[l][keys[m]].field.procQuestionNo)
												{
													this.$set(this.tableDataList[l][keys[m]].field.fieldList[0], "isCalcRepeat", true);
													if(this.tableDataList[l][keys[m]].field.fieldList[0].repeatSortOrder != 1){
														this.calculateVoList.push(calcVo);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				})
		},
		doInsert(statusCd) {
			this.logger.debug(this, "doInsert()");
			EventBus.$emit("project-loading", true);
			this.procFieldList = [];
			let param = {
				procFieldList : this.procFieldList,
				deleteRepeatList : this.deleteRepeatList,
				statusCd : statusCd
			};
			let reasonCount = 0;
			// for(let i=0; i<this.surveyItemList.length; i++)
			// {
			// 	console.log("before");
			// 	console.log(this.procNewQuestionList);
			// 	this.procNewQuestionList = this.surveyItemList[i].procQuestionList;
			// 	console.log("after");
			// 	console.log(this.procNewQuestionList);
			// 	for(let j=0; j<this.procNewQuestionList.length; j++)
			// 	{
			// 		this.fieldNewList = this.procNewQuestionList[j].fieldList;
			// 		for(let k=0; k<this.fieldNewList.length; k++)
			// 		{
			// 			let item = {
			// 				pageNo: this.param.pageNo,
			// 				visitNo:this.param.visitNo,
			// 				procFieldNo:this.fieldNewList[k].procFieldNo,
			// 				repeatSortOrder: this.fieldNewList[k].repeatSortOrder,
			// 				screeningEntryNo: this.fieldNewList[k].screeningEntryNo,
			// 				fieldValue: this.fieldNewList[k].fieldValue,
			// 				procNo : this.surveyItemList[i].procNo,
			// 				screeningNo : this.param.selectedScreeningNo,
			// 				userId : this.userInfo.userId,
			// 				fieldTypeCd : this.fieldNewList[k].fieldTypeCd,
			// 				screeningInputNo : this.fieldNewList[k].screeningInputNo,
			// 				fileList : this.fieldNewList[k].fileList,
			// 			};
			// 			this.procFieldList.push(item);
			// 		}
			// 	}
			// }

			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
					{
						let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;

						for(let k = 0; k < fieldNewList.length; k++)
						{
							//disabel,hide 여부 확인후 fieldList 작업
							for(let l = 0; l < this.applyValidVoList.length; l++){
								let detailVo = this.applyValidVoList[l].validDetailVo;

								for(let m = 0;  m < detailVo.validResultDsaList.length; m++){
									let dsaVo = detailVo.validResultDsaList[m];
									if(dsaVo.disabledTypeCd == 'PROC'){
										for(let n = 0; n < dsaVo.validResultDsaDetailList.length; n++){
											let dsaDetailVo = dsaVo.validResultDsaDetailList[n];

											if(this.param.visitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo){
												if(dsaVo.dataRemoveYn == 'Y'){
													switch(fieldNewList[k].fieldTypeCd) {
														case "04":
															this.$set(fieldNewList[k], "fieldValue", []);
															break;
														case "08":
															this.$set(fieldNewList[k], "fieldValueTemp", "");
															// eslint-disable-next-line no-fallthrough
														default:
															this.$set(fieldNewList[k], "fieldValue", "");
													}
												}
												this.$set(fieldNewList[k], "disableYn", "Y");
											}
											if(dsaDetailVo.visitNo == 0){
												if(dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo){
													if(dsaVo.dataRemoveYn == 'Y'){
														switch(fieldNewList[k].fieldTypeCd) {
															case "04":
																this.$set(fieldNewList[k], "fieldValue", []);
																break;
															case "08":
																this.$set(fieldNewList[k], "fieldValueTemp", "");
																// eslint-disable-next-line no-fallthrough
															default:
																this.$set(fieldNewList[k], "fieldValue", "");
														}
													}
													this.$set(fieldNewList[k], "disableYn", "Y");
												}
											}
										}
									}
								}
							}
							// question 단위 있을시 disable
							for(let l = 0; l < this.applyValidVoList.length; l++){
								let detailVo = this.applyValidVoList[l].validDetailVo;

								for(let m = 0;  m < detailVo.validResultDsaList.length; m++){
									let dsaVo = detailVo.validResultDsaList[m];
									if(dsaVo.disabledTypeCd == 'QUESTION'){
										for(let n = 0; n < dsaVo.validResultDsaDetailList.length; n++){
											let dsaDetailVo = dsaVo.validResultDsaDetailList[n];

											if(this.param.visitNo == dsaDetailVo.visitNo && dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && dsaDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
												if(dsaVo.dataRemoveYn == 'Y'){
													switch(fieldNewList[k].fieldTypeCd) {
														case "04":
															this.$set(fieldNewList[k], "fieldValue", []);
															break;
														case "08":
															this.$set(fieldNewList[k], "fieldValueTemp", "");
															// eslint-disable-next-line no-fallthrough
														default:
															this.$set(fieldNewList[k], "fieldValue", "");
													}
												}
												this.$set(fieldNewList[k], "disableYn", "Y");
											}
											if(dsaDetailVo.visitNo == 0){
												if(dsaDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && dsaDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
													if(dsaVo.dataRemoveYn == 'Y'){
														switch(fieldNewList[k].fieldTypeCd) {
															case "04":
																this.$set(fieldNewList[k], "fieldValue", []);
																break;
															case "08":
																this.$set(fieldNewList[k], "fieldValueTemp", "");
																// eslint-disable-next-line no-fallthrough
															default:
																this.$set(fieldNewList[k], "fieldValue", "");
														}
													}
													this.$set(fieldNewList[k], "disableYn", "Y");
												}
											}
										}
									}
								}
							}
							// hide question 단위 있을시 disable
							for(let l = 0; l < this.applyValidVoList.length; l++){
								let detailVo = this.applyValidVoList[l].validDetailVo;

								for(let m = 0;  m < detailVo.validResultHideList.length; m++){
									let hideVo = detailVo.validResultHideList[m];
									if(hideVo.hideTypeCd == 'QUESTION'){
										for(let n = 0; n < hideVo.validResultHideDetailList.length; n++){
											let hideDetailVo = hideVo.validResultHideDetailList[n];

											if(this.param.visitNo == hideDetailVo.visitNo && hideDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && hideDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
												this.$set(fieldNewList[k], "disableYn", "Y");
											}
											if(hideDetailVo.visitNo == 0){
												if(hideDetailVo.procNo == this.tableDataList[i][keys[j]].field.procNo && hideDetailVo.procQuestionNo == fieldNewList[k].procQuestionNo){
													this.$set(fieldNewList[k], "disableYn", "Y");
												}
											}
										}
									}
								}
							}

							let item = {
								pageNo: this.param.pageNo,
								visitNo:this.param.visitNo,
								procFieldNo:fieldNewList[k].procFieldNo,
								procQuestionNo:fieldNewList[k].procQuestionNo,
								repeatSortOrder: fieldNewList[k].repeatSortOrder,
								testScreeningEntryNo: fieldNewList[k].testScreeningEntryNo,
								beforeFieldValue: fieldNewList[k].beforeFieldValue,
								fieldValue: fieldNewList[k].fieldValue,
								selectDefault: fieldNewList[k].selectDefault,
								procNo : this.tableDataList[i][keys[j]].field.procNo,
								testScreeningNo : this.param.selectedScreeningNo,
								userId : this.userInfo.userId,
								fieldTypeCd : fieldNewList[k].fieldTypeCd,
								testScreeningInputNo : fieldNewList[k].testScreeningInputNo,
								fileList : fieldNewList[k].fileList,
								siteFileList : fieldNewList[k].siteFileList,
								dateSeperator : fieldNewList[k].dateSeperator,
								timeSeperator : fieldNewList[k].timeSeperator,
								dataTypeCd : fieldNewList[k].dataTypeCd,
								updateReason : fieldNewList[k].updateReason,
								etcReason : fieldNewList[k].etcReason,
								disableYn : fieldNewList[k].disableYn,
							};

							if(this.entryStatus != null && this.entryStatus != '1' && this.entryStatus != '2' && statusCd == '3' && fieldNewList[k].fieldTypeCd != '12' && fieldNewList[k].beforeFieldValue != fieldNewList[k].fieldValue && fieldNewList[k].updateReason == '5')
							{
								// console.log("qname : " + this.tableDataList[i][keys[j]].field.questionName + "---be : " + fieldNewList[k].beforeFieldValue + "---- now : " + fieldNewList[k].fieldValue);
								if(fieldNewList[k].fieldTypeCd == '04'){
									// console.log(typeof(fieldNewList[k].beforeFieldValue));
									// console.log(typeof(fieldNewList[k].fieldValue));
									if(fieldNewList[k].beforeFieldValue.length != fieldNewList[k].fieldValue.length){
										this.$alert("You are not changed update reason.","Warning","warning");
										reasonCount += 1;
									}
									else{
										const arr1 = fieldNewList[k].beforeFieldValue;
										const arr2 = fieldNewList[k].fieldValue;

										const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]); 

										arr1.sort();
										arr2.sort();

										const res = equals(arr1, arr2);
										if(res != true){
											this.$alert("You are not changed update reason.","Warning","warning");
											reasonCount += 1;
										}
									}
								}
								else{
									this.$alert("You are not changed update reason.","Warning","warning");
									reasonCount += 1;
								}
							}
							
							this.procFieldList.push(item);
						}
					}
				}
			}


				/* let row = this.procNewList[i].tableRow;
				let col = this.procNewList[i].tableCol;
				for(let k=1; k<row; k++)
				{
					for(let l=1; l<col; l++)
					{
						if(this.tableDataList[i][String.fromCharCode(64 + l) + k].field.fieldList)
						{
							let data = this.tableDataList[i][String.fromCharCode(64 + l) + k].field.fieldList[0].procFieldNo;
							this.logger.debug(this, data);
							let data2 =  this.tableDataList[i][String.fromCharCode(64 + l) + k].field.fieldList[0].fieldValue;
							this.logger.debug(this, data2);
							let item = {
								pageNo: this.param.pageNo,
								visitNo: this.param.visitNo
							}
							this.procFieldList.push(item);
						}
					}
				} */
			if(reasonCount == 0){
				axios
					.post(this.config.contextRoot + "/testScreening/insertVisitScreeningInput.do", param)
					.then((response) => {
						if (response.status == 200) {
							EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);
							if(response.data.valid == '3' || response.data.valid == '5')
							{
								this.$alert("Data has been saved successfully.", "Info", "info").then(() => {
									this.$nextTick(function(){
										this.doValidCalc(false);
										// this.doValidCheck();
										// this.doClose();
										// location.reload();
									});
								});
							}
							else
							{
								this.$alert("Data has been temp saved successfully.", "Info", "info").then(() => {
										// this.doValidCheck();
									this.doClose();
									location.reload();
								});
							}
						} else {
							alert(response.status);
							console.log(response);
						}
						EventBus.$emit("project-loading", false);
					})
					.catch((error) => {
						this.exceptionHandler(this, error);
					});
			}
			else{
				EventBus.$emit("project-loading", false);
			}
		},
		doUpdateAlert(statusCd){
			if(statusCd == '4')
			{
				this.$confirm("Do you want to update the status to Entry Mismatch?", "Question", "question").then(() => {
					this.doUpdateStatus(statusCd);
				});
			}
			else if(statusCd == '5')
			{
				this.$confirm("Do you want to update the status to Request Edit?", "Question", "question").then(() => {
					this.doUpdateStatus(statusCd);
				});
			}
			else if(statusCd == '6')
			{
				if(this.queryConfirmCheck()){
					this.$alert("Not all queries answered.", "Warn", "warning");
				}
				else{
					this.$confirm("Do you want to update the status to End Monitoring?", "Question", "question").then(() => {
						this.doUpdateStatus(statusCd);
					});
				}
			}
			else if(statusCd == '8')
			{
				this.$confirm("Do you want to update the status to Data Freezing?", "Question", "question").then(() => {
					this.doUpdateStatus(statusCd);
				});
			}
		},
		doUpdateStatus(statusCd){
			this.logger.debug(this, "doUpdateStatus()");

			let param = {
				procFieldList : this.procFieldList,
				statusCd : statusCd
			};

			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
					{
						let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
						for(let k = 0; k < fieldNewList.length; k++)
						{
							let item = {
								testScreeningInputNo : fieldNewList[k].testScreeningInputNo,
								testScreeningNo : fieldNewList[k].testScreeningNo,
								pageNo: this.param.pageNo,
								visitNo:this.param.visitNo,
							};
							
							this.procFieldList.push(item);
						}
					}
				}
			}

			axios
				.post(this.config.contextRoot + "/testScreening/updateVisitScreeningStatus.do", param)
				.then((response) => {
					if (response.status == 200) {
						EventBus.$emit("refresh-data-list", this.param.selectedScreeningNo);

						this.$alert("The status has been updated successfully.", "Info", "info").then(() => {
							this.doClose();
							location.reload();
						});
					} else {
						alert(response.status);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the data input?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.ScreeningSingleCreate.style.animation = "fadeOutUp 0.4s ease-in-out both";
			EventBus.$emit("project-loading", true);
			setTimeout(function() {
				EventBus.$emit("project-loading", false);
				EventBus.$emit("project-cancel", "isSingleCreate");	
			}.bind(this), 410);
		},
		showPreview(fileList) {
			EventBus.$emit("project-loading", true);
			this.closePreview();
			let pdfUrl = fileList[0].serverFileSavePath + "\\" +fileList[0].serverFileSaveName;
			let base64 = "";

			axios
				.post(this.config.contextRoot + "/common/file/selectPdfViewer.do", {pdfUrl : pdfUrl})
				.then((response) => {
					console.log(response);
					base64 = response.data.base64Url;
					
					this.viewerUrl = base64;
					this.isViewer = true;
					this.showDualView();
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		showDualView() {
			this.$refs.dialog.style.transform = "translate(0, 0)";
			this.$refs.dialog.style.position = "fixed";
			this.$refs.dialog.style.top = "0";
			this.$refs.dialog.style.right = "0";
			this.$refs.dialog.style.width = "50%";
			this.$refs.dialog.style.height = "100%";
			this.$refs.dialog.style.maxHeight = "100%";
			this.$refs.dialog.style.animation = "unset";
			EventBus.$emit("project-loading", false);
		},
		cancelDualView() {
			this.$refs.dialog.style.transform = "translate(0px, -400px)";
			this.$refs.dialog.style.position = "";
			this.$refs.dialog.style.top = "";
			this.$refs.dialog.style.right = "";
			this.$refs.dialog.style.width = "";
			this.$refs.dialog.style.height = "";
			this.$refs.dialog.style.maxHeight = "800px";
			this.$refs.dialog.style.animation = "";
		},
		closePreview() {
			this.isViewer = false;
			this.viewerUrl = "";
			this.cancelDualView();
		},
		resetRadio(index, cellId, fieldIndex, disabled, status) {
			if(disabled == 'disabled' || status == '8'){
				console.log(disabled);
			}
			else{
				this.$set(this.tableDataList[index][cellId].field.fieldList[fieldIndex], "fieldValue", "");
				setTimeout(function() {
					this.doValidCalc(true);
				}.bind(this), 0);
			}
			// let procQuestionIndex = this.getProcQuestionIndex(index, cellId);
			// this.$set(this.surveyItemList[index].procQuestionList[procQuestionIndex].fieldList[fieldIndex], "fieldValue", "");
		},
		getValidate(field) {
			let dataTypeCd = field.dataTypeCd;
			let dataSize = field.dataSize;
			let decimal = field.decimalDigit;
			
			if(dataTypeCd == '1')
			{
				let number = Number(dataSize);
				if(number < 1 || number === undefined || number == null)
				{
					return "max:0";
				}
				else{
					return "max:" + number;
				}
			}
			else if(dataTypeCd == '2')
			{
				let number = Number(dataSize);
				if(number < 1 || number === undefined || number == null)
				{
					return "max_value:0|min_value:0";
				}
				else
				{
					let maxValue = "";
					for(let i = 0 ; i < number ; i++)
					{
						maxValue += "9";
					}
					return "max_value:" + maxValue + "|min_value:-" + Number(maxValue);
				}
			}
			else if(dataTypeCd == '6')
			{
				if(decimal == "" || decimal === undefined || decimal == null)
				{
					return "decimal:0|max_value:0|min_value:0";
				}
				else
				{
					let sep = decimal.split(".");
					if(sep.length == 1)
					{
						let num = Number(sep[0]);
						let maxValue = "";
						for(let i = 0 ; i < num ; i++)
						{
							maxValue += "9";
						}

						return "decimal:0|max_value:" + maxValue + "|min_value:-" + maxValue;
					}
					else if(sep.length == 2)
					{
						let num = Number(sep[0]);
						let digit = Number(sep[1]);
						let maxValue = "";
						for(let i = 0 ; i < num ; i++)
						{
							maxValue += "9";
						}
						maxValue += ".";
						for(let i = 0 ; i < digit ; i++)
						{
							maxValue += "9";
						}

						return "decimal:" + digit + "|max_value:" + maxValue + "|min_value:-" + maxValue;
					}
					else
					{
						return "decimal:0|max_value:0|min_value:0";
					}
				}
			}
		},
		validateInput(index, cellId, fieldIndex) {
			let dataTypeCd = this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataTypeCd;
			let value = this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue;
			let pattern = "";
			let num = 0;
			let digit = 0;
			
			if(dataTypeCd == '1' || dataTypeCd == '7')
			{
				let dataSize = this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataSize;
				value = value.slice(0, dataSize);
				this.$set(this.tableDataList[index][cellId].field.fieldList[fieldIndex], "fieldValue", value);
				return true;
			}
			else if(dataTypeCd == '2' || dataTypeCd == '8')
			{
				let num = this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataSize;
				pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
				if(!pattern.test(value) ) return false;
				else return true;
			}
			else if(dataTypeCd == '6' || dataTypeCd == '9')
			{
				let decimalDigit = this.tableDataList[index][cellId].field.fieldList[fieldIndex].decimalDigit;
				let sep = decimalDigit.split(".");
				// let dataSize = Number(this.tableDataList[index][cellId].field.fieldList[fieldIndex].dataSize) + 1;
				if(sep.length == 1)
				{
					num = sep[0];
					pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
				}
				else if(sep.length == 2)
				{
					num = sep[0];
					digit = sep[1];
					pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
				}
				
				if(!pattern.test(value)){
					this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue = value.substring(0, value.length-1);
					if(!this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue.includes('.') && this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue.length == num && digit != 0){
						this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue = value.substring(0, value.length-1) + ".";
					}
					return false;
				}
				else{
					// if(this.tableDataList[index][cellId].field.fieldList[fieldIndex].fieldValue.length < dataSize){
					// 	return false;
					// }
					return true;
				}
			}
			else return true;
		},
		setMaxLength(field){
			let dataTypeCd = field.dataTypeCd;
			if(dataTypeCd == '6' || dataTypeCd == '9')
			{
				let decimalDigit = field.decimalDigit;
				let sep = decimalDigit.split(".");
				let dataSize = Number(field.dataSize)+1;
				let num = 0;
				let digit = 0;
				// let pattern = "";

				if(sep.length == 1)
				{
					num = sep[0];
					// pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
					return dataSize;
				}
				else if(sep.length == 2)
				{
					num = sep[0];
					digit = sep[1];
					// pattern = new RegExp(`^(?!\\.)(\\d{0,${num}})?(?:\\.\\d{0,${digit}})?${num > 0 && digit === 0 ? '(?<!\\.)' : ''}$`);
					if(digit != 0){
						return dataSize;
					}
					else{
						return num;
					}
				}
			}
			else
			{
				return field.dataSize;
			}
		},
		doCalculate(procQuestion, fieldIndex, fieldList, index, cellId) {
			let param = {
				projectNo: this.param.projectNo,
				visitNo: this.param.visitNo,
				pageNo: this.param.pageNo,
				procNo: procQuestion.procNo,
				procQuestionNo: procQuestion.procQuestionNo,
				procFieldNo: procQuestion.fieldList[fieldIndex].procFieldNo,
				testScreeningNo: this.param.selectedScreeningNo
			};

			axios
				.post(this.config.contextRoot + "/calculate/selectTestCalculate.do", param)
				.then((response) => {
					var calcVo = response.data.calculateVo;
					if(!calcVo){
						this.$alert("[Can't be calculated]\nCalculation does not exist.", "Warn", "warning");
						return false;
					}
					var calcGpLst = calcVo.calculateGroupList;

					this.calcRepeatList = [];
					this.findCalcRepeat(calcGpLst, index, cellId);

					setTimeout(function() {
						if(this.calcRepeatList.length > 0)
						{
							this.calcParam = {
								fieldList: fieldList,
								calcGpLst: calcGpLst,
								index: index,
								cellId: cellId,
								fieldIndex: fieldIndex
							}
							this.isRepeatField = true;
						}
						else
						{
							this.setCalcValue(fieldList, calcGpLst, index, cellId, fieldIndex);
						}
					}.bind(this), 0);

				});
		},
		setCalcValue(fieldList, calcGpLst, index, cellId, fieldIndex) {
			console.log()
			var dtTypCd = Number(fieldList[0].dataTypeCd);
			var calcStr = "";
			for(var i = 0 ; i < calcGpLst.length ; i++) {
				calcStr += "(";

				var calcDtLst = calcGpLst[i].calculateDetailList;
				for(var j = 0 ; j < calcDtLst.length ; j++) {
					var fdTypCd = calcDtLst[j].fieldTypeCd;
					var fieldValue = "";
					var fieldValueParse = "";
					var dataType = "";

					switch (fdTypCd) {
						case "VALUE":
							fieldValue = calcDtLst[j].fieldValue;
							break;
						case "FIELD":
							//Field가 같은 Visit 내에 있는 경우
							if(calcDtLst[j].visitNo == this.param.visitNo || calcDtLst[j].visitNo == '0' && calcDtLst[j].pageNo == this.param.pageNo)
							{
								for(var k = 0; k < this.tableDataList.length; k++)
								{
									var keys = Object.keys(this.tableDataList[k]);
									for(var l = 0; l < keys.length; l++)
									{
										if(!this.tableDataList[k][keys[l]].isHeader && !this.tableDataList[k][keys[l]].isRepeat && Object.keys(this.tableDataList[k][keys[l]].field).length > 0 
											&& calcDtLst[j].procNo == this.tableDataList[k][keys[l]].field.procNo && calcDtLst[j].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo)
										{
											fieldValue = this.tableDataList[k][keys[l]].field.fieldList[0].fieldValue;
											dataType = this.tableDataList[k][keys[l]].field.fieldList[0].dataTypeCd;
										}
										//Repeat Field인 경우
										else if(!this.tableDataList[k][keys[l]].isHeader && this.tableDataList[k][keys[l]].isRepeat && Object.keys(this.tableDataList[k][keys[l]].field).length > 0 
											&& calcDtLst[j].procNo == this.tableDataList[k][keys[l]].field.procNo && calcDtLst[j].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo)
										{
											//Procedure와 Repeat Root가 같은 경우
											if(this.tableDataList[k][keys[l]].field.procNo == this.tableDataList[index][cellId].field.procNo
												&& this.tableDataList[k][keys[l]].repeatRoot == this.tableDataList[index][cellId].repeatRoot 
												&& this.tableDataList[k][keys[l]].field.fieldList[0].repeatSortOrder == this.tableDataList[index][cellId].field.fieldList[0].repeatSortOrder)
											{
												fieldValue = this.tableDataList[k][keys[l]].field.fieldList[0].fieldValue;
												dataType = this.tableDataList[k][keys[l]].field.fieldList[0].dataTypeCd;
											}
											//Procedure가 같고 Repeat Root가 다른 경우 & Procedure가 다른 경우
											else if((this.tableDataList[k][keys[l]].field.procNo == this.tableDataList[index][cellId].field.procNo 
												&& this.tableDataList[k][keys[l]].repeatRoot != this.tableDataList[index][cellId].repeatRoot) 
												|| (this.tableDataList[k][keys[l]].field.procNo != this.tableDataList[index][cellId].field.procNo))
											{
												if(this.tableDataList[k][keys[l]].field.fieldList[0].isCalcRepeat){
													fieldValue = this.tableDataList[k][keys[l]].field.fieldList[0].fieldValue;
													dataType = this.tableDataList[k][keys[l]].field.fieldList[0].dataTypeCd;
												}
											}
										}
									}
								}
							}
							else
							{
								fieldValue = calcDtLst[j].fieldValue;
								dataType = calcDtLst[j].dataTypeCd;
							}
							break;
						case "FUNCTION":
							fieldValue = this.executeFunction(calcDtLst[j].funcParamList, false, index, cellId);
							if(!fieldValue)
							{
								// this.$alert("[Can't be calculated]\nOne or more values of the equation are missing or invalid.", "Warn", "warning");
								return false;
							}
							break;
						default:
							this.$alert("[Can't be calculated]\nOne or more values of the equation are missing or invalid.", "Warn", "warning");
							return false;
					}
					
					console.log("fieldValue : " + fieldValue);
					console.log("dataType : " + dataType);

					if(dataType == '3' || dataType == '5'){
						fieldValueParse = new Date(fieldValue);
						fieldValueParse = fieldValueParse / (1000*60*60*24);
					}
					else{
						fieldValueParse = Number(fieldValue);
					}
					console.log("fieldValue Num : " + fieldValueParse);
					console.log("isNan : " + isNaN(fieldValueParse));
					if(isNaN(fieldValueParse) || fieldValueParse == null)
					{
						this.$alert("[Can't be calculated]\nOne or more values of the equation are missing or invalid..", "Warn", "warning");
						return false;
					}

					calcStr += fieldValueParse;
					calcStr += calcDtLst[j].fieldOperator;
				}
				calcStr += ")" + calcGpLst[i].groupOperator;
			}

			var calcResultTmp = this.evalInContext(calcStr, this, false);
			var calcResult = "";

			//Integer
			if([2, 8].includes(dtTypCd))
			{
				var dataSize = fieldList[0].dataSize;
				let number = Number(dataSize);
				let maxValue = "";
				for(let i = 0 ; i < number ; i++)
				{
					maxValue += "9";
				}

				let maxValueNum = Number(maxValue);
				let calcResultRnd = Math.round(calcResultTmp);
				console.log(calcResultTmp);
				console.log(calcResultRnd + ">" + maxValueNum);
				if(calcResultRnd > maxValueNum){
					this.$alert("[Can't be calculated]\nThe data size of the calculated value is bigger\nthan the field's set data size.", "Warn", "warning");
					return false;
				}
				else
				{
					calcResult = calcResultRnd;
				}
			}
			//Decimal
			else if([6, 9].includes(dtTypCd))
			{
				let sep = fieldList[0].decimalDigit.split(".");
				if(sep.length == 1)
				{
					let num = Number(sep[0]);
					let maxValue = "";
					for(let i = 0 ; i < num ; i++)
					{
						maxValue += "9";
					}

					let maxValueNum = Number(maxValue);
					let calcResultRnd = Math.round(calcResultTmp);
					if(calcResultRnd > maxValueNum){
						this.$alert("[Can't be calculated]\nThe data size of the calculated value is bigger\nthan registered data size.", "Warn", "warning");
						return false;
					}
					else
					{
						calcResult = calcResultRnd;
					}
				}
				else if(sep.length == 2)
				{
					let num = Number(sep[0]);
					let digit = Number(sep[1]);
					let maxValue = "";
					let maxDigit = "";
					let round = "1";
					for(let i = 0 ; i < num ; i++)
					{
						maxValue += "9";
					}
					for(let i = 0 ; i < digit ; i++)
					{
						maxDigit += "9";
						round += "0";
					}

					let maxValueNum = Number(maxValue);
					let maxDigitNum = Number(maxDigit);
					let roundNum = Number(round); 
					let calcSep = calcResultTmp.toString().split(".");
					if(calcSep.length == 1)
					{
						if(calcResultTmp > maxValueNum){
							this.$alert("[Can't be calculated]\nThe data size of the calculated value is bigger\nthan registered data size.", "Warn", "warning");
							return false;
						}
						else
						{
							calcResult = calcResultTmp;
						}
					}
					else if(calcSep.length == 2)
					{
						let calcValueNum = Number(calcSep[0]);
						let calcDigitNum = Number(calcSep[1]);
						if(calcValueNum > maxValueNum)
						{
							this.$alert("[Can't be calculated]\nThe data size of the calculated value is bigger\nthan registered data size.", "Warn", "warning");
							return false;
						}
						else if(calcDigitNum > maxDigitNum)
						{
							calcResult = Math.round(calcResultTmp * roundNum) / roundNum;
						}
						else
						{
							calcResult = calcResultTmp;
						}
					}
				}
				else
				{
					calcResult = calcResultTmp;
				}
			}
			else if([3, 4, 5, 11, 12, 13].includes(dtTypCd))
			{
				var offset = new Date().getTimezoneOffset() * 60000;
				var localDate = new Date(calcResultTmp - offset);
				if(isNaN(localDate))
				{
					this.$alert("[Can't be calculated]\nThe equation is invalid.", "Warn", "warning");
					return false;
				}

				if([3, 11].includes(dtTypCd))
				{
					calcResult = localDate.toISOString().slice(0, 10);
				}
				else if([5, 13].includes(dtTypCd))
				{
					calcResult = localDate.toISOString().slice(0, 19).replace('T', ' ');
				}
				else if([4, 12].includes(dtTypCd))
				{
					calcResult = localDate.toISOString().slice(11, 19);
				}
			}
			else
			{
				this.$alert("[Can't be calculated]\nThe data type of the field is invalid.", "Warn", "warning");
				return false;
			}

			this.$set(this.tableDataList[index][cellId].field.fieldList[fieldIndex], "fieldValue", calcResult);

			setTimeout(function() {
				this.doValidCalc(true);
			}.bind(this), 0);
		},
		findCalcRepeat(calcGpLst, index, cellId) {
			for(let i = 0 ; i < calcGpLst.length ; i++) {
				let calcDtLst = calcGpLst[i].calculateDetailList;
				for(let j = 0 ; j < calcDtLst.length ; j++) {
					let fdTypCd = calcDtLst[j].fieldTypeCd;
					if(fdTypCd == "FIELD")
					{
						//Field가 같은 Visit 내에 있는 경우
						if(calcDtLst[j].visitNo == this.param.visitNo || calcDtLst[j].visitNo.visitNo == '0'  && calcDtLst[j].pageNo == this.param.pageNo)
						{
							for(let k = 0; k < this.tableDataList.length; k++)
							{
								let keys = Object.keys(this.tableDataList[k]);
								for(let l = 0; l < keys.length; l++)
								{
									//Repeat Field인 경우
									if(!this.tableDataList[k][keys[l]].isHeader && this.tableDataList[k][keys[l]].isRepeat && Object.keys(this.tableDataList[k][keys[l]].field).length > 0 
										&& calcDtLst[j].procNo == this.tableDataList[k][keys[l]].field.procNo && calcDtLst[j].procQuestionNo == this.tableDataList[k][keys[l]].field.procQuestionNo)
									{
										//Procedure가 같고 Repeat Root가 다른 경우 & Procedure가 다른 경우
										if((this.tableDataList[k][keys[l]].field.procNo == this.tableDataList[index][cellId].field.procNo 
											&& this.tableDataList[k][keys[l]].repeatRoot != this.tableDataList[index][cellId].repeatRoot) 
											|| (this.tableDataList[k][keys[l]].field.procNo != this.tableDataList[index][cellId].field.procNo))
										{
											this.$set(this.tableDataList[k][keys[l]].field.fieldList[0], "isCalcRepeat", false);
											this.calcRepeatList.push(this.tableDataList[k][keys[l]].field.fieldList[0]);
										}
									}
								}
							}
						}
						else if(calcDtLst[j].fieldValue.indexOf(",") > 0)
						{
							let fieldValueList = calcDtLst[j].fieldValue.split(',');
							for(let k = 0; k < fieldValueList.length; m++)
							{
								let calcDt = JSON.parse(JSON.stringify(calcDtLst[j]));
								calcDt.fieldValue = fieldValueList[k];
								this.calcRepeatList.push(calcDt);
							}
						}
					}
					else if(fdTypCd == "FUNCTION")
					{
						let funcPrmLst = calcDtLst[j].funcParamList;
						for(let k = 0; k < funcPrmLst.length; k++)
						{
							if(funcPrmLst[k].visitNo == this.param.visitNo|| funcPrmLst[k].visitNo == '0' && funcPrmLst[k].pageNo == this.param.pageNo)
							{
								for(let l = 0; l < this.tableDataList.length; l++)
								{
									let keys = Object.keys(this.tableDataList[l]);
									for(var m = 0; m < keys.length; m++)
									{
										if(!this.tableDataList[l][keys[m]].isHeader && this.tableDataList[l][keys[m]].isRepeat
											&& Object.keys(this.tableDataList[l][keys[m]].field).length > 0
											&& funcPrmLst[i].procNo == this.tableDataList[l][keys[m]].field.procNo 
											&& funcPrmLst[i].procQuestionNo == this.tableDataList[l][keys[m]].field.procQuestionNo)
										{
											//Procedure가 같고 Repeat Root가 다른 경우 & Procedure가 다른 경우
											if((this.tableDataList[l][keys[m]].field.procNo == this.tableDataList[index][cellId].field.procNo 
												&& this.tableDataList[l][keys[m]].repeatRoot != this.tableDataList[index][cellId].repeatRoot) 
												|| (this.tableDataList[l][keys[m]].field.procNo != this.tableDataList[index][cellId].field.procNo))
											{
												this.$set(this.tableDataList[l][keys[m]].field.fieldList[0], "isCalcRepeat", false);
												this.calcRepeatList.push(this.tableDataList[l][keys[m]].field.fieldList[0]);
											}
										}
									}
								}
							}
							else if(funcPrmLst[k].value.indexOf(",") > 0)
							{
								let fieldValueList = funcPrmLst[k].value.split(',');
								for(let k = 0; k < fieldValueList.length; k++)
								{
									let funcPrm = JSON.parse(JSON.stringify(funcPrmLst[k]));
									funcPrm.fieldValue = fieldValueList[k];
									funcPrm.value = fieldValueList[k];
									this.calcRepeatList.push(funcPrm);
								}
							}
						}
					}
				}
			}
			this.calcRepeatList = [...new Set(this.calcRepeatList)];
		},
		setRepeatValue(calcRepeatList) {
			for(var i = 0; i < calcRepeatList.length; i++)
			{
				if(calcRepeatList[i].isCalcRepeat)
				{
					if(calcRepeatList[i].visitNo == this.param.visitNo && calcRepeatList[i].pageNo == this.param.pageNo)
					{
						for(var j = 0; j < this.tableDataList.length; j++)
						{
							var keys = Object.keys(this.tableDataList[j]);
							for(var k = 0; k < keys.length; k++)
							{
								if(!this.tableDataList[j][keys[k]].isHeader
									&& Object.keys(this.tableDataList[j][keys[k]].field).length > 0
									&& this.tableDataList[j][keys[k]].field.fieldList[0].procFieldNo == calcRepeatList[i].procFieldNo 
									&& this.tableDataList[j][keys[k]].field.fieldList[0].repeatSortOrder == calcRepeatList[i].repeatSortOrder)
								{
									this.$set(this.tableDataList[j][keys[k]].field.fieldList[0], "isCalcRepeat", true);
								}
							}
						}
					}
					else
					{
						var calcGpLst = this.calcVo.calculateGroupList;
						for(var l = 0; l < calcGpLst.length; l++)
						{
							var calcDtLst = calcGpLst[l].calculateDetailList;
							for(var m = 0; m < calcDtLst.length; m++){
								if(calcRepeatList[i].procFieldNo == calcDtLst[m].procFieldNo)
								{
									this.$set(this.calcVo.calculateGroupList[l].calculateDetailList[m], "fieldValue", calcRepeatList[i].fieldValue);
								}
							}
						}
					}
				}
			}
		},
		executeFunction(funcPrmLst, isValid) {
			if(funcPrmLst.length < 1) {
				this.$alert("[Can't be calculated]\nOne or more functions of the equation are invalid.", "Warn", "warning");
				return false;
			}
			else {
				var prmLst = [];
				for(var i = 0; i < funcPrmLst.length; i++)
				{
					var prm = funcPrmLst[i].value;
					console.log("prm"+ i +" : " + prm);
					if(funcPrmLst[i].visitNo == this.param.visitNo || funcPrmLst[i].visitNo == '0' && funcPrmLst[i].pageNo == this.param.pageNo)
					{
						outerFor: for(var j = 0; j < this.tableDataList.length; j++)
						{
							var keys = Object.keys(this.tableDataList[j]);
							for(var k = 0; k < keys.length; k++)
							{
								if(!this.tableDataList[j][keys[k]].isHeader && !this.tableDataList[j][keys[k]].isRepeat 
									&& Object.keys(this.tableDataList[j][keys[k]].field).length > 0 
									&& funcPrmLst[i].procNo == this.tableDataList[j][keys[k]].field.procNo 
									&& funcPrmLst[i].procQuestionNo == this.tableDataList[j][keys[k]].field.procQuestionNo)
								{
									prmLst.push('"' + this.tableDataList[j][keys[k]].field.fieldList[0].fieldValue + '"');
								}
								else if(!this.tableDataList[j][keys[k]].isHeader && this.tableDataList[j][keys[k]].isRepeat 
									&& Object.keys(this.tableDataList[j][keys[k]].field).length > 0 
									&& funcPrmLst[i].procNo == this.tableDataList[j][keys[k]].field.procNo 
									&& funcPrmLst[i].procQuestionNo == this.tableDataList[j][keys[k]].field.procQuestionNo
									&& this.tableDataList[j][keys[k]].field.fieldList[0].isCalcRepeat)
								{
									prmLst.push('"' + this.tableDataList[j][keys[k]].field.fieldList[0].fieldValue + '"');
									break outerFor;
								}
							}
						}
					}
					else
					{
						prmLst.push('"' + prm + '"');
					}
				}
				var funcDefCode = funcPrmLst[0].funcDefCode;
				var executeCode = funcDefCode.slice(funcDefCode.indexOf(" ") + 1, funcDefCode.indexOf("("));

				var prmLstStr = prmLst.join(", ");
				executeCode += "(" + prmLstStr + ")";
				var totalCode = funcDefCode + executeCode;
				return this.evalInContext(totalCode, this, isValid);
			}
		},
		evalInContext(js, context, isValid){
			console.log(isValid);
			// alert(function(){ return eval(js);}.call(context));
			return function(){ return eval(js);}.call(context);
		},
		openStatusHistory() {
			this.logger.debug(this, "openStatusHistory()");

			this.isScreeningStatusHistory = true;
		},
		openHistory() {
			this.logger.debug(this, "openHistory()");

			this.isScreeningHistory = true;
		},
		openQuery(field){
			if(this.workAccessCheck('QUERY ANSWER') || this.workAccessCheck('QUERY CLOSE')){
				this.logger.debug(this, "openQuery()");
				this.selectField = field;
				this.selectQuery = field.queryConfirmList;
				this.isOpenQuery = true;
			}
			else{
				this.$alert("You are not authorized.","Warning","warning");
			}
		},
		resetQuery(field, queryConfirmList){
			this.logger.debug(this, "resetQuery()");
			
			if(queryConfirmList.length == 0){
				for(let i = 0; i < this.tableDataList.length; i++)
				{
					let keys = Object.keys(this.tableDataList[i]);
					for(let j = 0; j < keys.length; j++)
					{
						if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
						{
							let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
							for(let k = 0; k < fieldNewList.length; k++)
							{
								if(fieldNewList[k].testScreeningNo == field.testScreeningNo && fieldNewList[k].visitNo == field.visitNo && fieldNewList[k].pageNo == field.pageNo
								&& fieldNewList[k].procQuestionNo == field.procQuestionNo && fieldNewList[k].procFieldNo == field.procFieldNo && fieldNewList[k].fieldNo == field.fieldNo
								)
								{
									this.$set(fieldNewList[k], 'queryConfirmList' , []);
								}
								
							}
						}
					}
				}
			}
			else{
				let confirmList = [];
				queryConfirmList.forEach((item) => {
					confirmList.push(item);
				});
				for(let i = 0; i < this.tableDataList.length; i++)
				{
					let keys = Object.keys(this.tableDataList[i]);
					for(let j = 0; j < keys.length; j++)
					{
						if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
						{
							let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
							for(let k = 0; k < fieldNewList.length; k++)
							{
								if(fieldNewList[k].testScreeningNo == field.testScreeningNo && fieldNewList[k].visitNo == field.visitNo && fieldNewList[k].pageNo == field.pageNo
								&& fieldNewList[k].procQuestionNo == field.procQuestionNo && fieldNewList[k].procFieldNo == field.procFieldNo && fieldNewList[k].fieldNo == field.fieldNo
								)
								{
									this.$set(fieldNewList[k], 'queryConfirmList' , confirmList);
								}
								
							}
						}
					}
				}
			}
		},
		piSignature(){
			this.logger.debug(this, "piSignature()");
			this.isOpenSign = true;
		},
		requestEdit(){
			this.logger.debug(this, "requestEdit()");
			this.isRequestEdit = true;
			this.procFieldList = [];

			for(let i = 0; i < this.tableDataList.length; i++)
			{
				let keys = Object.keys(this.tableDataList[i]);
				for(let j = 0; j < keys.length; j++)
				{
					if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
					{
						let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
						for(let k = 0; k < fieldNewList.length; k++)
						{
							if(fieldNewList[k].fieldTypeCd != '12'){
								let optionLabel = [];
								let optionValue = [];
								let fieldLabel = "";
								if(fieldNewList[k].fieldTypeCd == '03'){
									optionLabel = fieldNewList[k].radioOptionsLabel.split(';');
									optionValue = fieldNewList[k].radioOptionsValue.split(';');

									let index = optionValue.indexOf(fieldNewList[k].fieldValue);
									fieldLabel = optionLabel[index];
								}
								if(fieldNewList[k].fieldTypeCd == '04'){
									optionLabel = fieldNewList[k].checkboxOptionsLabel.split(';');
									optionValue = fieldNewList[k].checkboxOptionsValue.split(';');

									let index = [];
									fieldNewList[k].fieldValue.forEach((item) => {
										index.push(optionValue.indexOf(item))
									});

									fieldLabel = [];

									for(let i = 0; i < optionLabel.length; i++){
										index.forEach((item) => {
											if(item == i){
												fieldLabel.push(optionLabel[i]);
											}
										})
									}			
								}
								if(fieldNewList[k].fieldTypeCd == '05'){
									optionLabel = fieldNewList[k].selectOptionsLabel.split(';');
									optionValue = fieldNewList[k].selectOptionsValue.split(';');

									let index = optionValue.indexOf(fieldNewList[k].fieldValue);
									fieldLabel = optionLabel[index];
								}

								let item = {
									cellName: this.tableDataList[i][keys[j]].cellName,
									pageNo: this.param.pageNo,
									visitNo:this.param.visitNo,
									procFieldNo:fieldNewList[k].procFieldNo,
									procQuestionNo:fieldNewList[k].procQuestionNo,
									repeatSortOrder: fieldNewList[k].repeatSortOrder,
									testScreeningEntryNo: fieldNewList[k].testScreeningEntryNo,
									beforeFieldValue: fieldNewList[k].beforeFieldValue,
									fieldValue: fieldNewList[k].fieldValue,
									selectDefault: fieldNewList[k].selectDefault,
									// fieldValueDate: fieldNewList[k].fieldValueDate,
									// fieldValueTime: fieldNewList[k].fieldValueTime,
									procNo : this.tableDataList[i][keys[j]].field.procNo,
									testScreeningNo : this.param.selectedScreeningNo,
									userId : this.userInfo.userId,
									fieldTypeCd : fieldNewList[k].fieldTypeCd,
									testScreeningInputNo : fieldNewList[k].testScreeningInputNo,
									fileList : fieldNewList[k].fileList,
									siteFileList : fieldNewList[k].siteFileList,
									dateSeperator : fieldNewList[k].dateSeperator,
									timeSeperator : fieldNewList[k].timeSeperator,
									dataTypeCd : fieldNewList[k].dataTypeCd,
									updateReason : fieldNewList[k].updateReason,
									questionName : this.tableDataList[i][keys[j]].field.questionName,
									questionFieldName : this.tableDataList[i][keys[j]].field.questionFieldName,
									procName : this.surveyItemList[i].procName,
									fieldLabel : fieldLabel,
								};
								this.procFieldList.push(item);
							}
						}
					}
				}
			}
		},
		gotoPageAction(gotoVo){
			this.logger.debug(this, "gotoPageAction()" + gotoVo);
			this.$confirm(gotoVo.printMsg, "Question", "question").then(() => {
				this.logger.debug(this, "doInsert()");
				this.procFieldList = [];
				let statusCd = "";
				if(this.entryStatus == null || this.entryStatus == '1' || this.entryStatus == '2'){
					statusCd = '2';
				}
				else if(this.entryStatus == '3'){
					statusCd = '3';
				}

				if(this.entryStatus == null || this.entryStatus == '1' || this.entryStatus == '2' || this.entryStatus == '3'){
					let param = {
						procFieldList : this.procFieldList,
						deleteRepeatList : this.deleteRepeatList,
						statusCd : statusCd
					};

					for(let i = 0; i < this.tableDataList.length; i++)
					{
						let keys = Object.keys(this.tableDataList[i]);
						for(let j = 0; j < keys.length; j++)
						{
							if(!this.tableDataList[i][keys[j]].isHeader && Object.keys(this.tableDataList[i][keys[j]].field).length > 0)
							{
								let fieldNewList = this.tableDataList[i][keys[j]].field.fieldList;
								for(let k = 0; k < fieldNewList.length; k++)
								{
									let item = {
										pageNo: this.param.pageNo,
										visitNo:this.param.visitNo,
										procFieldNo:fieldNewList[k].procFieldNo,
										procQuestionNo:fieldNewList[k].procQuestionNo,
										repeatSortOrder: fieldNewList[k].repeatSortOrder,
										testScreeningEntryNo: fieldNewList[k].testScreeningEntryNo,
										fieldValue: fieldNewList[k].fieldValue,
										selectDefault: fieldNewList[k].selectDefault,
										// fieldValueDate: fieldNewList[k].fieldValueDate,
										// fieldValueTime: fieldNewList[k].fieldValueTime,
										procNo : this.tableDataList[i][keys[j]].field.procNo,
										testScreeningNo : this.param.selectedScreeningNo,
										userId : this.userInfo.userId,
										fieldTypeCd : fieldNewList[k].fieldTypeCd,
										testScreeningInputNo : fieldNewList[k].testScreeningInputNo,
										fileList : fieldNewList[k].fileList,
										siteFileList : fieldNewList[k].siteFileList,
										dateSeperator : fieldNewList[k].dateSeperator,
										timeSeperator : fieldNewList[k].timeSeperator,
										dataTypeCd : fieldNewList[k].dataTypeCd,
									};
									this.procFieldList.push(item);
								}
							}
						}
					}

					axios
						.post(this.config.contextRoot + "/testScreening/insertVisitScreeningInput.do", param)
						.then((response) => {
							if (response.status == 200) {
								this.logger.debug(this, "doClose()");
								this.$refs.ScreeningSingleCreate.style.animation = "fadeOutUp 0.4s ease-in-out both";
								EventBus.$emit("project-loading", true);
								setTimeout(function() {
									EventBus.$emit("project-loading", false);
									EventBus.$emit("project-cancel", "isSingleCreate");	
									setTimeout(function() {
										this.$parent.doGotoPage(gotoVo);
									}.bind(this), 410);
								}.bind(this), 500);
							} else {
								alert(response.status);
								console.log(response);
							}
						})
						.catch((error) => {
							this.exceptionHandler(this, error);
					});
				}
				else{
					EventBus.$emit("project-cancel", "isSingleCreate");	
					setTimeout(function() {
						this.$parent.doGotoPage(gotoVo);
					}.bind(this), 410);
				}
			});
			// let gotoList = this.$parent.doGotoPage(gotoVo);
			// this.newSinglePopup(gotoList);
		},
		// newSinglePopup(gotoList){
		// 	this.logger.debug(this, "newSinglePopup()");
		// 	let pageItem = gotoList.pageItem;
		// 	let visitItem = gotoList.visitItem;
		// 	if(this.$parent.disabledCheck(pageItem, visitItem))
		// 	{
		// 		this.$alert("Disabled Page","Warning","warning");
		// 	}
		// 	else
		// 	{
		// 		this.paramSigleCreate = {
		// 			pageNo: pageItem.pageNo,
		// 			centralEdcPageNo: pageItem.centralEdcPageNo,
		// 			pageLabel: pageItem.pageLabel,
		// 			pageName: pageItem.pageName,
		// 			projectNo: this.items.projectNo,
		// 			visitNo: visitItem.visitNo,
		// 			centralEdcVisitNo: visitItem.centralEdcVisitNo,
		// 			visitName: visitItem.visitName,
		// 			visitPageList: visitItem.screeningVisitPageList,
		// 			selectedScreeningNo: this.selectedScreeningNo,
		// 			selectedScreeningName: this.selectedScreeningName,
		// 		};
		// 		this.isSingleCreate = true;
		// 	}
		// },
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		clearDate(index, cellName, fieldIndex) {
			this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValueTemp", "");
			this.$set(this.tableDataList[index][cellName].field.fieldList[fieldIndex], "fieldValue", "");
			setTimeout(function() {
				this.doValidCalc(true);
				let popup = document.getElementsByClassName("mx-datepicker-popup")[0];
				popup.style.display = "none";
			}.bind(this), 0);
		},
		missingQueryCheck(field, fieldValue){
			let result = false;
			if(field.queryResultList.length > 0){
				let valueCount = [];
				for(let i = 0; i < field.queryResultList.length; i++){
					if(field.queryResultList[i].resultValue != '' || fieldValue != '' || field.queryResultList[i].queryStatusCd != '1'){
						valueCount.push(field.queryResultList[i].resultValue);
					}
				}
				if(valueCount.length <= 0){
					result = true;
				}
			}
			return result; 
		},
		entryStatusAuth(status){
			let result = false;

			// temp save 조건
			if(status == '2'){
				if(this.entryStatus == null || this.entryStatus == '1' || this.entryStatus == '2'){
					result = true;
				}
			}
			// save 조건
			else if(status == '3'){
				if(this.entryStatus == null || this.entryStatus == '1' || this.entryStatus == '2' || this.entryStatus == '3'){
					result = true;
				}
			}
			// monitoring request 조건
			else if(status == '4'){
				if(this.entryStatus == '3' || this.entryStatus == '4' || this.entryStatus == '5'){
					result = true;
				}
			}
			// request edit 조건
			else if(status == '5'){
				if(this.entryStatus == '4' || this.entryStatus == '5' || this.entryStatus == '6'){
					result = true;
				}
			}
			// end monitoring 조건
			else if(status == '6'){
				if(this.entryStatus == '3' || this.entryStatus == '5'){
					result = true;
				}
			}
			// pi sign 조건
			else if(status == '7'){
				if(this.entryStatus == '8'){
					result = true;
				}
			}
			// data freezing 조건
			else if(status == '8'){
				if(this.entryStatus == '6'){
					result = true;
				}
			}
			// Un freezing 조건(end monitoring상태로 변경)
			else if(status == '9'){
				if(this.entryStatus == '7' || this.entryStatus == '8'){
					result = true;
				}
			}

			return result;
		},
		msgPopup(field, event){
			if(field.openMsgYn == 'Y'){
				event.target.children[0].style.animation = "hideTopBalloon 0.3s ease-out both";
				field.openMsgYn = 'N';
			}
			else{
				field.openMsgYn = 'Y';
			}
		},
		msgHover(event, type){
			if(type == 'over'){
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.overflow = 'visible';
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.zIndex = '500';
			}
			if(type == 'leave'){
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.overflow = 'hidden'
				event.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.zIndex = '0';
			}
		},
		validReset(){
			this.applyValidVoList = [];
			// this.validApply();
		}
	}
};
</script>

<style scoped>
.screening-contents-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    row-gap: 25px;
    position: absolute;
    width: 95%;
    height: calc(100% - 300px);
    padding: 10px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 50;
}

@media ( max-width: 1200px) {
    .screening-contents-container {
        grid-template-columns: 100% !important;
        /* left: calc(35% + 60px) !important; */
        /* width: calc(65% - 90px) !important; */
		width: calc(100% - 60px) !important;
        transition: left 0.4s linear !important;
    }
    .dinput-info{
		align-items: stretch !important;
	}
	.dinput-info > .item > .title{
		min-height: 60px;
		padding: 10px !important;
	}
	.dinput-info > .item > .content{
		word-break: break-all;
		padding: 10px !important;
	}
}

.screening-contents-container::-webkit-scrollbar {
    width: 18px;
}

.screening-contents-container::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to top, #9ecc5a, #2bade0);
    background-clip: padding-box;
}

.screening-contents-container::-webkit-scrollbar-track {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 20px;
    background: #eee;
    background-clip: padding-box;
}

.screening-contents-container::after {
    content: "";
    display: block;
    width: 100%;
    min-height: 1px;
}

.unfocus-text,
.table-head {
	display: flex;
	justify-content: inherit;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
	z-index: 15;
}

.table-data {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	font-weight: inherit;
	font-size: 10pt;
	overflow: hidden;
}

.survey-field-list {
	width: 100%;
	font-weight: 600 !important;
	font-size: 10pt !important;
	color: #666;
	overflow-y: visible;
}

.survey-field-vtype,
.survey-field-htype {
    max-width: 100%;
	font-weight: 400 !important;
}

.input-crud-error::placeholder {
	color: rgb(224, 91, 91);
}

.btn-cancel {
	margin-right: 10px;
}

.dinput-info {
	display: flex;
	align-items: center;
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.dinput-info > .item {
	width: 25%;
	border-left: 1px solid #ccc;
}

.dinput-info > .item:first-child {
	border-left: none;
}

.dinput-info > .item > .title {
	padding: 8px 0 8px 15px;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
	border-bottom: 1px solid #ccc;
	background-color: #eee;
	text-align: left;
}

.dinput-info > .item > .content {
	padding: 15px 0 15px 15px;
	font-size: 10.5pt;
	color: #999;
	text-align: left;
}

.procedure-input-wrap {
	margin: 0 0 20px;
	border-top: 1px solid #ccc;
}

.procedure-title {
	padding: 8px 0 8px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #e3f7ff;
	font-weight: 600;
	font-size: 10.5pt;
	color: #000;
	text-align: left;
}

.procedure-field {
	display: flex;
}

.procedure-question {
	display: flex;
	align-items: center;
	width: 20%;
	padding: 20px 0 20px 15px;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10.5pt;
	color: #333;
	text-align: left;
}

.procedure-form {
	width: 80%;
	padding: 20px 15px;
	border-bottom: 1px solid #ccc;
	background-color: #fff;
	font-weight: 400;
	font-size: 10.5pt;
	color: #666;
	text-align: left;
}

.procedure-form > .procedure-item:last-child {
	margin: 0;
}

.procedure-item {
	display: block;
	margin: 0 0 15px 0;
	font-weight: 600;
	font-size: 10.5pt;
	color: #666;
}

.procedure-item > .vertical {
	display: block;
}

.procedure-item > .horizon {
	display: inline-block;
}

.input-crud {
	max-width: 100%;
}

.textarea-crud {
	height: unset !important;
}

.radio-crud,
.checkbox-crud {
	font-weight: 400;
	font-size: 10pt;
}

.select-crud {
	width: auto !important;
	max-width: 100% !important;
}

.btn-temp,
.btn-history:hover {
	border: 1px solid #2bade0 !important;
	color: #2bade0 !important;
}

.txt-crud-title {
	justify-content: space-between;
}


.procedure-table {
	width: 100%;
	border-collapse: collapse;
}

.procedure-table:focus {
	outline: none;
}

.procedure-table tr {
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead th {
	position: relative;
	min-width: 30px;
	height: 35px;
	overflow-x: auto;
	resize: horizontal;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.procedure-table thead tr th:first-child {
	width: 35px;
	resize: none;
}

.procedure-table th {
	border: 1px solid #ccc;
	background-color: #eee;
	font-weight: 600;
	font-size: 10pt;
	color: #000;
}

.procedure-table td {
	position: relative;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	word-break: keep-all;
}

.procedure-table td > input[type=text] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	border: none;
	background-color: unset;
	font-weight: inherit;
	font-size: inherit;
	text-align: inherit;
}

.procedure-table .focus-celltype {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 5px;
	font-family: cursive;
	font-weight: 500;
	font-size: 9pt;
	text-align: right;
	overflow: hidden;
	z-index: 5;
	opacity: 0.6;
}

.repeat-Btn {
	width: 20px;
	height: 20px;
	font-size: 20px;
	position: absolute;
	margin: 5px 5px;
	display: flex;
    align-items: center;
    justify-content: center;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
	z-index: 20;
	top: 0;
	left: 1175px;
}

.procedure-table .focus-celltype .celltype-header {
	display: inline-block;
	margin: 0 5px;
	padding: 2px 5px;
	background-color: #2bade0;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-text {
	display: inline-block;
	padding: 2px 5px;
	background-color: #9ecc5a;
	border-radius: 3px;
	color: #fff;
}

.procedure-table .focus-celltype .celltype-data {
	display: inline-block;
	padding: 2px 5px;
	background-color: #cc5a5a;
	border-radius: 3px;
	color: #fff;
}

.download-preview {
	color: rgb(0, 162, 255);
}

.download-preview:hover {
	text-decoration: underline;
}

.btn-preview-close {
    display: inline-block;
    position: fixed;
    top: 10px;
    left: calc(50% - 140px);
    width: 36px;
    height: 36px;
    border-radius: 36px;
    z-index: 5555;
    font-weight: 600;
    font-size: 10pt;
    color: #fff;
}

.btn-preview-close:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.btn-calc {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 10px;
	padding: 3px 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #eee;
	font-weight: 600;
	font-size: 9.5pt;
	color: #999;
}

.btn-calc:hover {
	border: 1px solid #aaa;
	color: #666;
}
.valid-msg{
	min-width: 15px;
	min-height: 15px;
	border-radius: 3px;
	background-color: #cc5a5a;
	cursor: pointer;
}
.open-query{
	cursor: pointer;
	min-width: 15px;
	min-height: 15px;
	border-radius: 3px;
	background-color: #fff;
	border: 1px solid;
	color: rgb(224, 91, 91);
	text-align: center;
	font-size: 7pt;
	font-weight: bold;
}
.valid-goto{
	min-width: 15px;
	min-height: 15px;
	border-radius: 3px;
	background-color: #2bade0;
	cursor: pointer;
}

.valid-event-zone{
	position: absolute;
	z-index: 1;
	right: 0;
	top: 0;
	margin: 5px 5px 0 0;
	display: flex;
    column-gap: 5px;
}

.valid-event-sub{
	display: flex;
	column-gap: 20px;
	font-size: 10.5pt;
    color: #999;
	margin-bottom: 5px;
}

.valid-event-part{
	display: flex;
    align-items: center;
    column-gap: 5px;
}

.blinking{
	-webkit-animation: blink 0.5s ease-in-out infinite alternate;
	-moz-animation: blink 0.5s ease-in-out infinite alternate;
	animation: blink 0.9s cubic-bezier(0, 0, 0.05, 1.0) infinite alternate
}

@-webkit-keyframes blink{
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@-moz-keyframes blink{
	0% {opacity: 0;}
	100% {opacity: 1;}
}

@keyframes blink{
	0% {opacity: 0;}
	100% {opacity: 1;}
}
</style>import { apply } from "core-js/fn/reflect";
