<template>
	<div ref="validationCreate" class="full-container">
		<div class="full-top-wrap">
			Validation Create
		</div>
		<div class="full-center-wrap" style="overflow-y: scroll;">
			<form @submit.prevent="doValidation()">
				<p class="txt-crud-title">
					Validation Form
				</p>
				<p class="txt-form-title">
					Validation Info
				</p>
				<!-- Validation Info -->
				<table class="tb-crud-horizon">
					<colgroup>
						<col width="11%" />
						<col width="39%" />
						<col width="11%" />
						<col width="39%" />
					</colgroup>
					<tbody>
						<tr>
							<th>Validation Name</th>
							<td>
								<input
									type="text"
									class="input-crud"
									:class="errors.has('validationName') ? 'input-crud-error' : ''"
									name="validationName"
									v-model="validForm.validationName"
									v-validate="'required'"
									data-vv-as="validation Name"
									placeholder="Please enter the Validation Name."
									@keyup="doDuplicateCheck()"
								/>
								<p v-if="isDuplicate" class="duplicate-balloon" style="position: inherit; padding-top: 7px; font-size: 9pt;">
								* Validation Name is duplicated.
								</p>
							</td>
							<th>Use Status</th>
							<td>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="Y"
										v-model="validForm.useYn"
										data-vv-as="Use Status"
										v-validate="'required'"
									/><div class="radio" />
									Yes
								</label>
								<label class="radio-crud">
									<input
										type="radio"
										name="useYn"
										value="N"
										v-model="validForm.useYn"
										data-vv-as="Use Status"
										v-validate="'required'"
									/><div class="radio" />
									No
								</label>
							</td>
						</tr>
						<!-- <tr>
							<th>Select Visit</th>
							<td colspan="2">
								<select
									name="visitNo"
									class="select-crud"
									:class="errors.has('visitNo') ? 'input-crud-error' : ''"
									v-model="validForm.visitNo"
									v-validate="'required'"
									data-vv-as="Visit"
									:style="(validForm.visitNo == '' ? {color: '#ccc'} : {})"
									@change="selectPageList()"
								>
									<option value="" disabled hidden>
										Please select the visit first.
									</option>
									<option
										v-for="(option, index) in visitList"
										:key="index"
										:value="option.visitNo"
									>
										{{ option.visitName }}
									</option>
								</select>
							</td>
						</tr> -->
						<tr>
							<th>Register</th>
							<td>
								{{ item.firstRegistUserId }}
							</td>
							<th>Regist Date</th>
							<td>
								{{ item.firstRegistDate }}
							</td>
						</tr>
					</tbody>
				</table>
				<!-- Validation List -->
				<template>
					<p class="txt-form-title" style="margin-top: 50px;">
						Validation
						<!-- <button
							type="button"
							title="Add Validation"
							class="btn-new"
							style="margin: 0;"
							@click="addValidDetailForm()"
						>
							Add Validation
						</button> -->
					</p>
					<!-- Validation Form -->
					<table
						v-for="(valid, validIndex) in validForm.validDetailList"
						:key="validIndex"
						class="tb-crud-horizon"
					>
						<!-- <caption :style="(validIndex == 0 ? {margin:'20px 0 5px'} : {})">
							Validation
							<button
								type="button"
								title="Remove Validation"
								class="btn-small-remove"
								style="margin: 0; float: right;"
								@click="removeValidDetailForm(validIndex)"
							></button>
						</caption> -->
						<colgroup>
							<col width="11%" />
							<col width="39%" />
							<col width="11%" />
							<col width="39%" />
						</colgroup>
						<tbody>
							<tr>
								<th>Type</th>
								<td colspan="3">
									<label
										v-for="(resultType, typeIndex) in resultTypeList"
										:key="typeIndex"
										class="checkbox-crud"
									>
										<input v-if="resultType.comCode == 'printMsgYn'" type="checkbox" v-model="valid[resultType.comCode]" @click="validSelectCheck(resultType.comCode, valid[resultType.comCode])"/>
										<input v-if="resultType.comCode == 'systemPopupYn'" type="checkbox" v-model="valid[resultType.comCode]" @click="validSelectCheck(resultType.comCode, valid[resultType.comCode])"/>
										<input v-if="resultType.comCode == 'gotoPageYn'" type="checkbox" v-model="valid[resultType.comCode]" @click="validSelectCheck(resultType.comCode, valid[resultType.comCode])"/>
										<input v-if="resultType.comCode == 'dynamicYn'" type="checkbox" v-model="valid[resultType.comCode]" @click="validSelectCheck(resultType.comCode, valid[resultType.comCode])"/>
										<input v-if="resultType.comCode != 'dynamicYn' || resultType.comCode != 'printMsgYn' || resultType.comCode != 'gotoPageYn' || resultType.comCode != 'systemPopupYn'" type="checkbox" v-model="valid[resultType.comCode]" @change="setDsa(valid, resultType.comCode)" @click="validSelectCheck(resultType.comCode, valid[resultType.comCode])"/>
										<span class="checkbox" />
										{{ resultType.comCodeName }}
									</label>
								</td>
							</tr>
							<tr>
								<th>Conditional Formula</th>
								<td colspan="3" style="padding: 15px 10px;">
									<!-- Validation Condition Group List -->
									<p class="txt-form-stitle" style="margin-top: 0; padding: 0;">
										Group List
										<button
											type="button"
											title="Add Group"
											class="btn-new"
											style="margin: 0;"
											@click="addValidConForm(validIndex)"
										>
											Add Group
										</button>
									</p>
									<!-- Validation Condition Group Form -->
									<template v-for="(con, conIndex) in valid.validConList">
										<div
											:key="conIndex"
											class="con-group-wrap"
										>
											<!-- Validation Condition Detail List -->
											<p class="con-group-title">
												Group {{ conIndex + 1 }}
												<button
													type="button"
													title="Remove Group"
													class="btn-small-remove"
													style="margin: 0;"
													@click="removeValidConForm(validIndex, conIndex)"
												></button>
											</p>
											<!-- Validation Condition Detail Form -->
											<template v-for="(conDetail, conDetailIndex) in con.validConDetailList">
												<div
													:key="conDetailIndex"
													class="con-detail-wrap"
												>
													<!-- Validation Condition Detail Select Field -->
													<div class="label">
														<p class="no-drag">
															<button
																type="button"
																class="select-field-value"
																:class="conDetail.ogTypeCd == 'FIELD' ? 'selected-field-value' : ''"
																@click="setOgTypeCd(validIndex, conIndex, conDetailIndex, 'FIELD')"
															>
																Field
															</button>
															or
															<button
																type="button"
																class="select-field-value"
																:class="conDetail.ogTypeCd == 'CALCULATION' ? 'selected-field-value' : ''"
																@click="setOgTypeCd(validIndex, conIndex, conDetailIndex, 'CALCULATION', conDetail.isRepeat)"
															>
																Calculate
															</button>
														</p>
														<div v-if="conDetail.ogTypeCd == 'FIELD'" class="drop-list-wrap">
															<div
																class="drop-list"
																:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_field') ? 'input-crud-error' : ''"
																:title="
																	(
																		Object.keys(conDetail.value).length != 0 ?
																		conDetail.value.valueText :
																		'Please select the field'
																	)
																"
																:style="Object.keys(conDetail.value).length != 0 ? {color: '#999'} : {}"
																@click="openPageList(validIndex + '_' + conIndex + '_' + conDetailIndex + '_field', $event);"
															>
																{{
																	(
																		Object.keys(conDetail.value).length != 0 ?
																		conDetail.value.valueText :
																		"Please select the field"
																	)
																}}
															</div>
															<DropList
																v-if="openFieldIndex == (validIndex + '_' + conIndex + '_' + conDetailIndex + '_field')"
																v-model="conDetail.value"
																:validVisitList="validVisitList"
																:fieldTypeList="fieldTypeList"
																@input="setDropValue(conDetail, 'conField', validIndex)"
															/>
															<input
																type="hidden"
																:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_field'"
																v-model="conDetail.value"
																v-validate="'required'"
																data-vv-as="Field"
															/>
														</div>
														<input
															v-if="conDetail.ogTypeCd == 'CALCULATION'"
															type="text"
															class="input-crud"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_ogCalculateForm') ? 'input-crud-error' : ''"
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_ogCalculateForm'"
															v-model="conDetail.ogCalculateForm.allText"
															v-validate="'required'"
															data-vv-as="Calculate Form"
															placeholder="Please enter the calculation value."
															:title="conDetail.ogCalculateForm.allText"
															disabled
														/>
													</div>
													<!-- Validation Condition Detail Operator -->
													<label class="label">
														<p>
															Operator
														</p>
														<select
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_operatorCd'"
															class="select-crud"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_operatorCd') ? 'input-crud-error' : ''"
															v-model="conDetail.operatorCd"
															v-validate="'required'"
															:data-vv-as="validIndex + '_' + conIndex + '_' + conDetailIndex + '_operatorCd'"
															:style="(conDetail.operatorCd == '' ? {color: '#ccc'} : {})"
														>
															<option value="" disabled hidden>
																Operator
															</option>
															<option
																v-for="(operator, opIndex) in getOperatorList(conDetail.targetTypeCd)"
																:key="opIndex"
																:value="operator.comCode"
															>
																{{ operator.comCodeName }}
															</option>
														</select>
													</label>
													<!-- Validation Condition Detail Target Field -->
													<div class="label">
														<p class="no-drag">
															<button
																type="button"
																class="select-field-value"
																:class="conDetail.targetTypeCd == 'FIELD' ? 'selected-field-value' : ''"
																@click="setTargetTypeCd(validIndex, conIndex, conDetailIndex, 'FIELD')"
															>
																Field
															</button>
															or
															<button
																type="button"
																class="select-field-value"
																:class="conDetail.targetTypeCd == 'CALCULATION' ? 'selected-field-value' : ''"
																@click="setTargetTypeCd(validIndex, conIndex, conDetailIndex, 'CALCULATION', conDetail.isRepeat)"
															>
																Calculate
															</button>
															or
															<button
																type="button"
																class="select-field-value"
																:class="conDetail.targetTypeCd == 'VALUE' ? 'selected-field-value' : ''"
																@click="setTargetTypeCd(validIndex, conIndex, conDetailIndex, 'VALUE')"
															>
																Value
															</button>
															or
															<button
																type="button"
																class="select-field-value"
																:class="conDetail.targetTypeCd == 'EMPTY' ? 'selected-field-value' : ''"
																@click="setTargetTypeCd(validIndex, conIndex, conDetailIndex, 'EMPTY')"
															>
																Empty
															</button>
														</p>
														<div v-if="conDetail.targetTypeCd == 'FIELD'" class="drop-list-wrap">
															<div
																class="drop-list"
																:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetField') ? 'input-crud-error' : ''"
																:title="
																	(
																		Object.keys(conDetail.target).length != 0 ?
																		conDetail.target.valueText :
																		'Please select the field'
																	)
																"
																:style="Object.keys(conDetail.target).length != 0 ? {color: '#999'} : {}"
																@click="openPageList(validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetField', $event);"
															>
																{{
																	(
																		Object.keys(conDetail.target).length != 0 ?
																		conDetail.target.valueText :
																		"Please select the field"
																	)
																}}
															</div>
															<DropList
																v-if="openFieldIndex == (validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetField') && !setDateBox(conDetail)"
																v-model="conDetail.target"
																:validVisitList="validVisitList"
																:fieldTypeList="fieldTypeList"
																@input="setDropValue(conDetail, 'conTargetField')"
															/>
															<DropList
																v-if="openFieldIndex == (validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetField') && setDateBox(conDetail)"
																v-model="conDetail.target"
																:validVisitList="dateTypeList"
																:fieldTypeList="fieldTypeList"
																@input="setDropValue(conDetail, 'conTargetField')"
															/>
															<input
																type="hidden"
																:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetField'"
																v-model="conDetail.target"
																v-validate="'required'"
																data-vv-as="Target Field"
															/>
														</div>
														<input
															v-if="conDetail.targetTypeCd == 'CALCULATION'"
															type="text"
															class="input-crud"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetCalculateForm') ? 'input-crud-error' : ''"
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetCalculateForm'"
															v-model="conDetail.targetCalculateForm.allText"
															v-validate="'required'"
															data-vv-as="Calculate Form"
															placeholder="Please enter the calculation value."
															disabled
														/>
														<input
															v-if="(conDetail.targetTypeCd == 'VALUE') && !setSelectBox(conDetail) && !setDateBox(conDetail)"
															type="text"
															class="input-crud"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetValue') ? 'input-crud-error' : ''"
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetValue'"
															v-model="conDetail.targetValue"
															v-validate="'required'"
															data-vv-as="Target Value"
															placeholder="Please enter the compare value."
														/>
														<select
															v-if="(conDetail.targetTypeCd == 'VALUE') && setSelectBox(conDetail)"
															class="select-crud"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetSelectValue') ? 'input-crud-error' : ''"
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetSelectValue'"
															v-model="conDetail.targetValue"
															v-validate="'required'"
															data-vv-as="Target Select Value"
															placeholder="Please enter the compare value."
															:style="(conDetail.targetValue == '' ? {color: '#ccc'} : {})"
														>
															<option value="" disabled hidden>
																Please enter the compare value.
															</option>
															<option
																v-for="(item, itemIndex) in optionList(conDetail.value)"
																:key="itemIndex"
																:value="item.optionValue"
															>
																{{ item.optionLabel }}
																<!-- ({{item.optionValue}}) -->
															</option>
														</select>
														<date-picker
															v-if="(conDetail.targetTypeCd == 'VALUE') && setDateBox(conDetail)"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_targetDateValue') ? 'input-crud-error' : ''"
															:type="getViewOption(conDetail)"
															:format="getDateFormat(conDetail)"
															:lang="lang"
															v-model="conDetail.targetValue"
															value-type="format"
															v-validate="'required'"
															data-vv-as="Target Date Value"
															placeholder="Please enter the date value."
															style="width: 100%; height: 32px; font-size: 10px;"
															:style="(conDetail.targetValue == '' ? {color: '#ccc'} : {})"
															confirm
															clearable
														>
														</date-picker>
														<input
															v-if="(conDetail.targetTypeCd == 'EMPTY')"
															type="text"
															class="input-crud"
															disabled
														/>
													</div>
													<!-- Validation Condition Detail Logical Operator -->
													<label class="label">
														<p>
															Logical
														</p>
														<select
															v-if="(conDetailIndex + 1) != con.validConDetailList.length"
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_logicalOperatorCd'"
															class="select-crud"
															:class="errors.has(validIndex + '_' + conIndex + '_' + conDetailIndex + '_logicalOperatorCd') ? 'input-crud-error' : ''"
															v-model="conDetail.logicalOperatorCd"
															v-validate="'required'"
															:data-vv-as="validIndex + '_' + conIndex + '_' + conDetailIndex + '_logicalOperatorCd'"
															:style="(conDetail.logicalOperatorCd == '' ? {color: '#ccc'} : {})"
														>
															<option value="" disabled hidden>
																Logical
															</option>
															<option
																v-for="(operator, opIndex) in groupOperatorList"
																:key="opIndex"
																:value="operator.comCode"
															>
																{{ operator.comCodeName }}
															</option>
														</select>
														<select
															v-if="(conDetailIndex + 1) == con.validConDetailList.length"
															:name="validIndex + '_' + conIndex + '_' + conDetailIndex + '_logicalOperatorCd'"
															class="select-crud"
															v-model="conDetail.logicalOperatorCd"
															v-validate="'required'"
															:data-vv-as="validIndex + '_' + conIndex + '_' + conDetailIndex + '_logicalOperatorCd'"
															:style="(conDetail.logicalOperatorCd == '' ? {color: '#ccc'} : {})"
															disabled
														>
															<option value="" disabled hidden>
																Logical
															</option>
															<option
																v-for="(operator, opIndex) in groupOperatorList"
																:key="opIndex"
																:value="operator.comCode"
															>
																{{ operator.comCodeName }}
															</option>
														</select>
													</label>
													<!-- Validation Condition Detail Last Row Add or Remove -->
													<button
														v-if="conDetailIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addValidConDetailForm(validIndex, conIndex)"
													></button>
													<button
														v-if="conDetailIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeValidConDetailForm(validIndex, conIndex, conDetailIndex)"
													></button>
												</div>
											</template>
										</div>
										<!-- Validation Condition Group Operator -->
										<div
											v-if="(conIndex + 1) != valid.validConList.length"
											:key="'groupOperator' + conIndex"
											class="con-group-wrap"
										>
											<p class="con-operator-title" style="width: 35%; margin: 0; align-items: center;">
												Group Operator
												<select
													:name="validIndex + '_' + conIndex + 'groupOperator'"
													class="select-crud"
													v-model="con.groupOperator"
													v-validate="'required'"
													:data-vv-as="validIndex + '_' + conIndex + 'groupOperator'"
													style="width: 250px;"
													:style="(con.groupOperator == '' ? {color: '#ccc'} : {})"
												>
													<option value="" disabled hidden>
														Group Operator
													</option>
													<option
														v-for="(operator, opIndex) in groupOperatorList"
														:key="opIndex"
														:value="operator.comCode"
													>
														{{ operator.comCodeName }}
													</option>
												</select>
											</p>
										</div>
									</template>
								</td>
							</tr>
							<!-- Validation Result -->
							<tr>
								<th>Result</th>
								<td colspan="3" style="padding: 15px 10px;">
									<!-- Print Msg Form -->
									<template v-if="valid.printMsgYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Print Message
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_resultMsg_conditionYn'"
															value="Y"
															v-model="valid.validResultMsg.conditionYn"
															data-vv-as="Result Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_resultMsg_conditionYn'"
															value="N"
															v-model="valid.validResultMsg.conditionYn"
															data-vv-as="Result Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Print message
												</span>
												<div class="result-row-form">
													<input
														type="text"
														class="input-crud"
														:class="errors.has(validIndex + '_resultMsg_printMsg') ? 'input-crud-error' : ''"
														:name="validIndex + '_resultMsg_printMsg'"
														v-model="valid.validResultMsg.printMsg"
														v-validate="'required'"
														data-vv-as="Print Message"
														placeholder="Please enter the reulst message."
													/>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Under the
												</span>
												<div class="result-row-form">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_resultMsg_field') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(valid.validResultMsg.value) ?
																	valid.validResultMsg.value.valueText :
																	'Please select the field'
																)
															"
															:style="!utils.isEmpty(valid.validResultMsg.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_resultMsg_field', $event);"
														>
															{{
																(
																	!utils.isEmpty(valid.validResultMsg.value) ?
																	valid.validResultMsg.value.valueText :
																	'Please select the field'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_resultMsg_field')"
															v-model="valid.validResultMsg.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="FIELD"
															@input="setDropValue(valid.validResultMsg, 'resultMsg')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_resultMsg_field'"
															v-model="valid.validResultMsg.value"
															v-validate="'required'"
															data-vv-as="Print Field"
														/>
													</div>
												</div>
											</div>
										</div>
									</template>
									<!-- System Popup Form -->
									<template v-if="valid.systemPopupYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												System Popup
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_systemPopup_conditionYn'"
															value="Y"
															v-model="valid.validResultPopup.conditionYn"
															data-vv-as="Result Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_systemPopup_conditionYn'"
															value="N"
															v-model="valid.validResultPopup.conditionYn"
															data-vv-as="Result Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Print popup
												</span>
												<div class="result-row-form">
													<input
														type="text"
														class="input-crud"
														:class="errors.has(validIndex + '_systemPopup_printMsg') ? 'input-crud-error' : ''"
														:name="validIndex + '_systemPopup_printMsg'"
														v-model="valid.validResultPopup.printMsg"
														v-validate="'required'"
														data-vv-as="Print Popup"
														placeholder="Please enter the reulst message."
													/>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Page to open
												</span>
												<div class="result-row-form">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_systemPopup_field') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(valid.validResultPopup.value) ?
																	valid.validResultPopup.value.valueText :
																	'Please select the field'
																)
															"
															:style="!utils.isEmpty(valid.validResultPopup.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_systemPopup_field', $event);"
														>
															{{
																(
																	!utils.isEmpty(valid.validResultPopup.value) ?
																	valid.validResultPopup.value.valueText :
																	'Please select the field'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_systemPopup_field')"
															v-model="valid.validResultPopup.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="PAGE"
															@input="setDropValue(valid.validResultPopup, 'resultPopup')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_systemPopup_field'"
															v-model="valid.validResultPopup.value"
															v-validate="'required'"
															data-vv-as="Print Field"
														/>
													</div>
												</div>
											</div>
										</div>
									</template>
									<!-- Disabled Visit Form -->
									<template v-if="valid.disableVisitYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Disabled Visit
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_visitDsa_conditionYn'"
															value="Y"
															v-model="valid.visitDsa.conditionYn"
															data-vv-as="Visit Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_visitDsa_conditionYn'"
															value="N"
															v-model="valid.visitDsa.conditionYn"
															data-vv-as="Visit Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Data option is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_visitDsa_dataOptionYn'"
															value="Y"
															v-model="valid.visitDsa.dataRemoveYn"
															data-vv-as="Visit Disabeld Data option"
															v-validate="'required'"
														/><div class="radio" />
														Initialize
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_visitDsa_dataOptionYn'"
															value="N"
															v-model="valid.visitDsa.dataRemoveYn"
															data-vv-as="Visit Disabeld Data option"
															v-validate="'required'"
														/><div class="radio" />
														Not Commit
													</label>
												</div>
											</div>
											<div
												v-for="(dsaVisit, dsaVisitIndex) in valid.visitDsa.validResultDsaDetailList"
												:key="dsaVisitIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ dsaVisitIndex == 0 ? 'Disabled Visit is' : '' }}
												</span>
												<div class="result-row-form result-row-list">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_visitDsa_' + dsaVisitIndex + '_visit') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(dsaVisit.value) ?
																	dsaVisit.value.valueText :
																	'Please select the visit'
																)
															"
															:style="!utils.isEmpty(dsaVisit.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_visitDsa_' + dsaVisitIndex + '_visit', $event);"
														>
															{{
																(
																	!utils.isEmpty(dsaVisit.value) ?
																	dsaVisit.value.valueText :
																	'Please select the visit'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_visitDsa_' + dsaVisitIndex + '_visit')"
															v-model="dsaVisit.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="VISIT"
															@input="setDropValue(dsaVisit, 'dsaVisit')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_visitDsa_' + dsaVisitIndex + '_visit'"
															v-model="dsaVisit.value"
															v-validate="'required'"
															data-vv-as="Disabled Visit"
														/>
													</div>
													<button
														v-if="dsaVisitIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addDsaDetail(valid.visitDsa.validResultDsaDetailList)"
													></button>
													<button
														v-if="dsaVisitIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeDsaDetail(valid.visitDsa.validResultDsaDetailList, dsaVisitIndex)"
													></button>
												</div>
											</div>
										</div>
									</template>
									<!-- Disabled Page Form -->
									<template v-if="valid.disablePageYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Disabled Page
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_pageDsa_conditionYn'"
															value="Y"
															v-model="valid.pageDsa.conditionYn"
															data-vv-as="Page Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_pageDsa_conditionYn'"
															value="N"
															v-model="valid.pageDsa.conditionYn"
															data-vv-as="Page Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Data option is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_pageDsa_dataOptionYn'"
															value="Y"
															v-model="valid.pageDsa.dataRemoveYn"
															data-vv-as="Page Disabeld Data Option"
															v-validate="'required'"
														/><div class="radio" />
														Initialize
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_pageDsa_dataOptionYn'"
															value="N"
															v-model="valid.pageDsa.dataRemoveYn"
															data-vv-as="Page Disabeld Data Option"
															v-validate="'required'"
														/><div class="radio" />
														Not Commit
													</label>
												</div>
											</div>
											<div
												v-for="(dsaPage, dsaPageIndex) in valid.pageDsa.validResultDsaDetailList"
												:key="dsaPageIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ dsaPageIndex == 0 ? 'Disabled Page is' : '' }}
												</span>
												<div class="result-row-form result-row-list">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_pageDsa_' + dsaPageIndex + '_page') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(dsaPage.value) ?
																	dsaPage.value.valueText :
																	'Please select the page'
																)
															"
															:style="!utils.isEmpty(dsaPage.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_pageDsa_' + dsaPageIndex + '_page', $event);"
														>
															{{
																(
																	!utils.isEmpty(dsaPage.value) ?
																	dsaPage.value.valueText :
																	'Please select the page'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_pageDsa_' + dsaPageIndex + '_page')"
															v-model="dsaPage.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="PAGE"
															@input="setDropValue(dsaPage, 'dsaPage')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_pageDsa_' + dsaPageIndex + '_page'"
															v-model="dsaPage.value"
															v-validate="'required'"
															data-vv-as="Disabled Page"
														/>
													</div>
													<button
														v-if="dsaPageIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addDsaDetail(valid.pageDsa.validResultDsaDetailList)"
													></button>
													<button
														v-if="dsaPageIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeDsaDetail(valid.pageDsa.validResultDsaDetailList, dsaPageIndex)"
													></button>
												</div>
											</div>
										</div>
									</template>
									<!-- Disabled Proc Form -->
									<template v-if="valid.disableProcYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Disabled Procedure
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_procDsa_conditionYn'"
															value="Y"
															v-model="valid.procDsa.conditionYn"
															data-vv-as="Procedure Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_procDsa_conditionYn'"
															value="N"
															v-model="valid.procDsa.conditionYn"
															data-vv-as="Procedure Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													If data remove is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_procDsa_dataRemoveYn'"
															value="Y"
															v-model="valid.procDsa.dataRemoveYn"
															data-vv-as="Procedure Disabeld Data Remove"
															v-validate="'required'"
														/><div class="radio" />
														Yes
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_procDsa_dataRemoveYn'"
															value="N"
															v-model="valid.procDsa.dataRemoveYn"
															data-vv-as="Procedure Disabeld Data Remove"
															v-validate="'required'"
														/><div class="radio" />
														No
													</label>
												</div>
											</div>
											<div
												v-for="(dsaProc, dsaProcIndex) in valid.procDsa.validResultDsaDetailList"
												:key="dsaProcIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ dsaProcIndex == 0 ? 'Disabled Procedure is' : '' }}
												</span>
												<div class="result-row-form result-row-list">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_procDsa_' + dsaProcIndex + '_proc') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(dsaProc.value) ?
																	dsaProc.value.valueText :
																	'Please select the procedure'
																)
															"
															:style="!utils.isEmpty(dsaProc.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_procDsa_' + dsaProcIndex + '_proc', $event);"
														>
															{{
																(
																	!utils.isEmpty(dsaProc.value) ?
																	dsaProc.value.valueText :
																	'Please select the procedure'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_procDsa_' + dsaProcIndex + '_proc')"
															v-model="dsaProc.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="PROC"
															@input="setDropValue(dsaProc, 'dsaProc')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_procDsa_' + dsaProcIndex + '_proc'"
															v-model="dsaProc.value"
															v-validate="'required'"
															data-vv-as="Disabled Procedure"
														/>
													</div>
													<button
														v-if="dsaProcIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addDsaDetail(valid.procDsa.validResultDsaDetailList)"
													></button>
													<button
														v-if="dsaProcIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeDsaDetail(valid.procDsa.validResultDsaDetailList, dsaProcIndex)"
													></button>
												</div>
											</div>
										</div>
									</template>
									<!-- Disabled Question Form -->
									<template v-if="valid.disableQuestionYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Disabled Question
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionDsa_conditionYn'"
															value="Y"
															v-model="valid.questionDsa.conditionYn"
															data-vv-as="Question Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionDsa_conditionYn'"
															value="N"
															v-model="valid.questionDsa.conditionYn"
															data-vv-as="Question Disabeld Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													If data remove is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionDsa_dataRemoveYn'"
															value="Y"
															v-model="valid.questionDsa.dataRemoveYn"
															data-vv-as="Question Disabeld Data Remove"
															v-validate="'required'"
														/><div class="radio" />
														Yes
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionDsa_dataRemoveYn'"
															value="N"
															v-model="valid.questionDsa.dataRemoveYn"
															data-vv-as="Question Disabeld Data Remove"
															v-validate="'required'"
														/><div class="radio" />
														No
													</label>
												</div>
											</div>
											<div
												v-for="(dsaQuestion, dsaQuestionIndex) in valid.questionDsa.validResultDsaDetailList"
												:key="dsaQuestionIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ dsaQuestionIndex == 0 ? 'Disabled Question is' : '' }}
												</span>
												<div class="result-row-form result-row-list">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_questionDsa_' + dsaQuestionIndex + '_question') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(dsaQuestion.value) ?
																	dsaQuestion.value.valueText :
																	'Please select the question'
																)
															"
															:style="!utils.isEmpty(dsaQuestion.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_questionDsa_' + dsaQuestionIndex + '_question', $event);"
														>
															{{
																(
																	!utils.isEmpty(dsaQuestion.value) ?
																	dsaQuestion.value.valueText :
																	'Please select the question'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_questionDsa_' + dsaQuestionIndex + '_question')"
															v-model="dsaQuestion.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="FIELD"
															@input="setDropValue(dsaQuestion, 'dsaQuestion')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_questionDsa_' + dsaQuestionIndex + '_question'"
															v-model="dsaQuestion.value"
															v-validate="'required'"
															data-vv-as="Disabled Question"
														/>
													</div>
													<button
														v-if="dsaQuestionIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addDsaDetail(valid.questionDsa.validResultDsaDetailList)"
													></button>
													<button
														v-if="dsaQuestionIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeDsaDetail(valid.questionDsa.validResultDsaDetailList, dsaQuestionIndex)"
													></button>
												</div>
											</div>
										</div>
									</template>
									<!-- Goto Page Form -->
									<template v-if="valid.gotoPageYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Go to Page
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_gotoPageDsa_conditionYn'"
															value="Y"
															v-model="valid.gotoPageDsa.conditionYn"
															data-vv-as="Go to Page Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_gotoPageDsa_conditionYn'"
															value="N"
															v-model="valid.gotoPageDsa.conditionYn"
															data-vv-as="Go to Page Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Print message
												</span>
												<div class="result-row-form">
													<input
														type="text"
														class="input-crud"
														:class="errors.has(validIndex + '_gotoPageDsa_printMsg') ? 'input-crud-error' : ''"
														:name="validIndex + '_gotoPageDsa_printMsg'"
														v-model="valid.gotoPageDsa.printMsg"
														v-validate="'required'"
														data-vv-as="Print Message"
														placeholder="Please enter the message."
													/>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Go to Page is
												</span>
												<div class="result-row-form">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_gotoPageDsa_gotoPage') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(valid.gotoPageDsa.goPage) ?
																	valid.gotoPageDsa.goPage.valueText :
																	'Please select the page'
																)
															"
															:style="!utils.isEmpty(valid.gotoPageDsa.goPage) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_gotoPageDsa_gotoPage', $event);"
														>
															{{
																(
																	!utils.isEmpty(valid.gotoPageDsa.goPage) ?
																	valid.gotoPageDsa.goPage.valueText :
																	'Please select the page'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_gotoPageDsa_gotoPage')"
															v-model="valid.gotoPageDsa.goPage"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="PAGE"
															@input="setDropValue(valid.gotoPageDsa, 'gotoPage')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_gotoPageDsa_gotoPage'"
															v-model="valid.gotoPageDsa.goPage"
															v-validate="'required'"
															data-vv-as="Go to Page"
														/>
													</div>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Under the
												</span>
												<div class="result-row-form">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_gotoPageDsa_field') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(valid.gotoPageDsa.underQuestion) ?
																	valid.gotoPageDsa.underQuestion.valueText :
																	'Please select the field'
																)
															"
															:style="!utils.isEmpty(valid.gotoPageDsa.underQuestion) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_gotoPageDsa_field', $event);"
														>
															{{
																(
																	!utils.isEmpty(valid.gotoPageDsa.underQuestion) ?
																	valid.gotoPageDsa.underQuestion.valueText :
																	'Please select the field'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_gotoPageDsa_field')"
															v-model="valid.gotoPageDsa.underQuestion"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="QUESTION"
															@input="setDropValue(valid.gotoPageDsa, 'gotoUnder')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_gotoPageDsa_field'"
															v-model="valid.gotoPageDsa.underQuestion"
															v-validate="'required'"
															data-vv-as="Go to Page"
														/>
													</div>
												</div>
											</div>
										</div>
									</template>
									<!-- Dynamic Comment Form -->
									<template v-if="valid.dynamicYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Dynamic Comment Page
												<span class="dynamic-warn">※ It is an operation that is not affected by the condition.</span>
											</p>
											<div class="result-row">
												<span class="result-row-label">
													Print message
												</span>
												<div class="result-row-form">
													<input
														type="text"
														class="input-crud"
														:class="errors.has(validIndex + '_dynamicDsa_printMsg') ? 'input-crud-error' : ''"
														:name="validIndex + '_dynamicDsa_printMsg'"
														v-model="valid.dynamicDsa.printMsg"
														v-validate="'required'"
														data-vv-as="Print Message"
														placeholder="Please enter the message."
													/>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Go to Page is
												</span>
												<div class="result-row-form">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_dynamicDsa_gotoPage') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(valid.dynamicDsa.goPage) ?
																	valid.dynamicDsa.goPage.valueText :
																	'Please select the page'
																)
															"
															:style="!utils.isEmpty(valid.dynamicDsa.goPage) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_dynamicDsa_gotoPage', $event);"
														>
															{{
																(
																	!utils.isEmpty(valid.dynamicDsa.goPage) ?
																	valid.dynamicDsa.goPage.valueText :
																	'Please select the page'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_dynamicDsa_gotoPage')"
															v-model="valid.dynamicDsa.goPage"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="PAGE"
															@input="setDropValue(valid.dynamicDsa, 'gotoPage')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_dynamicDsa_gotoPage'"
															v-model="valid.dynamicDsa.goPage"
															v-validate="'required'"
															data-vv-as="Go to Page"
														/>
													</div>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													Under the
												</span>
												<div class="result-row-form">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_dynamicDsa_field') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(valid.dynamicDsa.underQuestion) ?
																	valid.dynamicDsa.underQuestion.valueText :
																	'Please select the field'
																)
															"
															:style="!utils.isEmpty(valid.dynamicDsa.underQuestion) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_dynamicDsa_field', $event);"
														>
															{{
																(
																	!utils.isEmpty(valid.dynamicDsa.underQuestion) ?
																	valid.dynamicDsa.underQuestion.valueText :
																	'Please select the field'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_dynamicDsa_field')"
															v-model="valid.dynamicDsa.underQuestion"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="QUESTION"
															@input="setDropValue(valid.dynamicDsa, 'gotoUnder')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_dynamicDsa_field'"
															v-model="valid.dynamicDsa.underQuestion"
															v-validate="'required'"
															data-vv-as="Go to Page"
														/>
													</div>
												</div>
											</div>
										</div>
									</template>
									<!-- data link -->
									<template v-if="valid.dataLinkYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Data Link
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_dataLinkDsa_conditionYn'"
															value="Y"
															v-model="valid.dataLinkDsa.conditionYn"
															data-vv-as="Data Link Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_dataLinkDsa_conditionYn'"
															value="N"
															v-model="valid.dataLinkDsa.conditionYn"
															data-vv-as="Data Link Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div
												v-for="(dsaDataLink, dsaDataLinkIndex) in valid.dataLinkDsa.dataLinkDsaDetailList"
												:key="dsaDataLinkIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ dsaDataLinkIndex == 0 ? 'Setting Field Value is' : '' }}
												</span>
												<div class="result-row-form result-row-list" style="align-items: flex-end;">
													<div class="drop-list-wrap" style="width: 45%;">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_field') ? 'input-crud-error' : ''"
															:title="
																(
																	Object.keys(dsaDataLink.value).length != 0 ?
																	dsaDataLink.value.valueText :
																	'Please select the field'
																)
															"
															:style="Object.keys(dsaDataLink.value).length != 0 ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_field', $event);"
														>
															{{
																(
																	Object.keys(dsaDataLink.value).length != 0 ?
																	dsaDataLink.value.valueText :
																	'Please select the field'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_field')"
															v-model="dsaDataLink.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="FIELD"
															@input="setDropValue(dsaDataLink, 'dsaDataLink')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_field'"
															v-model="dsaDataLink.value"
															v-validate="'required'"
															data-vv-as="Data Link"
														/>
													</div>
													<div class="label" style="width: 45%;">
														<p class="no-drag" style=" font-weight: 600; font-size: 10pt;">
															<button
																v-if="!setRadioCheckSelectBox(dsaDataLink)"
																type="button"
																class="select-field-value"
																:class="dsaDataLink.targetTypeCd == 'FIELD' ? 'selected-field-value' : ''"
																@click="setTargetTypeCdDataLink(validIndex, dsaDataLinkIndex, 'FIELD')"
															>
																Field
															</button>
															<span v-if="!setRadioCheckSelectBox(dsaDataLink)"> or </span>
															<button
																type="button"
																class="select-field-value"
																:class="dsaDataLink.targetTypeCd == 'VALUE' ? 'selected-field-value' : ''"
																@click="setTargetTypeCdDataLink(validIndex, dsaDataLinkIndex, 'VALUE')"
															>
																Value
															</button>
														</p>
														<div v-if="dsaDataLink.targetTypeCd == 'FIELD'" class="drop-list-wrap">
															<div
																class="drop-list"
																:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetField') ? 'input-crud-error' : ''"
																:title="
																	(
																		Object.keys(dsaDataLink.target).length != 0 ?
																		dsaDataLink.target.valueText :
																		'Please select the field'
																	)
																"
																:style="Object.keys(dsaDataLink.target).length != 0 ? {color: '#999'} : {}"
																@click="openPageList(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetField', $event);"
															>
																{{
																	(
																		Object.keys(dsaDataLink.target).length != 0 ?
																		dsaDataLink.target.valueText :
																		"Please select the field"
																	)
																}}
															</div>
															<DropList
																v-if="openFieldIndex == (validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetField') && !setDateBox(dsaDataLink)"
																v-model="dsaDataLink.target"
																:validVisitList="validVisitList"
																:fieldTypeList="fieldTypeList"
																@input="setDropValue(dsaDataLink, 'dsaDataLinkTarget')"
															/>
															<DropList
																v-if="openFieldIndex == (validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetField') && setDateBox(dsaDataLink)"
																v-model="dsaDataLink.target"
																:validVisitList="dateTypeList"
																:fieldTypeList="fieldTypeList"
																@input="setDropValue(dsaDataLink, 'dsaDataLinkTarget')"
															/>
															<input
																type="hidden"
																:name="validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetField'"
																v-model="dsaDataLink.target"
																v-validate="'required'"
																data-vv-as="Target Field"
															/>
														</div>
														<input
															v-if="(dsaDataLink.targetTypeCd == 'VALUE') && !setSelectBox(dsaDataLink) && !setDateBox(dsaDataLink)"
															type="text"
															class="input-crud"
															:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetValue') ? 'input-crud-error' : ''"
															:name="validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_targetValue'"
															v-model="dsaDataLink.targetValue"
															v-validate="'required'"
															data-vv-as="Target Value"
															placeholder="Please enter the compare value."
														/>
														<select
															v-if="(dsaDataLink.targetTypeCd == 'VALUE') && setSelectBox(dsaDataLink)"
															class="select-crud"
															:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_selectTargetValue') ? 'input-crud-error' : ''"
															:name="validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_selectTargetValue'"
															v-model="dsaDataLink.targetValue"
															v-validate="'required'"
															data-vv-as="Target Select Value"
															placeholder="Please enter the compare value."
															:style="(dsaDataLink.targetValue == '' ? {color: '#ccc'} : {})"
														>
															<option value="" disabled hidden>
																Please enter the compare value.
															</option>
															<option
																v-for="(item, itemIndex) in optionList(dsaDataLink.value)"
																:key="itemIndex"
																:value="item.optionValue"
															>
																{{ item.optionLabel }}
															</option>
														</select>
														<date-picker
															v-if="(dsaDataLink.targetTypeCd == 'VALUE') && setDateBox(dsaDataLink)"
															:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_TargetDateValue') ? 'input-crud-error' : ''"
															:type="getViewOption(dsaDataLink)"
															:format="getDateFormat(dsaDataLink)"
															:lang="lang"
															v-model="dsaDataLink.targetValue"
															value-type="format"
															v-validate="'required'"
															data-vv-as="Target Date Value"
															placeholder="Please enter the date value."
															style="width: 100%; height: 32px; font-size: 10px;"
															:style="(dsaDataLink.targetValue == '' ? {color: '#ccc'} : {})"
															confirm
															clearable
														>
														</date-picker>
													</div>
													<!-- <input
													v-if="!setSelectBox(dsaDataLink) && !setDateBox(dsaDataLink)"
														type="text"
														class="input-crud"
														:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_fieldValue') ? 'input-crud-error' : ''"
														style="width: calc(45% - 62px);"
														:name="validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_fieldValue'"
														v-model="dsaDataLink.fieldValue"
														v-validate="'required'"
														data-vv-as="Field Value"
														placeholder="Please enter the compare value."
													/>
													<select
														v-if="setSelectBox(dsaDataLink)"
														class="select-crud"
														:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_fieldSelectValue') ? 'input-crud-error' : ''"
														:name="validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_fieldSelectValue'"
														v-model="dsaDataLink.fieldValue"
														v-validate="'required'"
														data-vv-as="Field Select Value"
														placeholder="Please enter the compare value."
														style="width: calc(45% - 62px);"
														:style="(dsaDataLink.fieldValue == '' ? {color: '#ccc'} : {})"
													>
														<option value="" disabled hidden>
															Please enter the compare value.
														</option>
														<option
															v-for="(item, itemIndex) in optionList(dsaDataLink.value)"
															:key="itemIndex"
															:value="item.optionValue"
														>
															{{ item.optionLabel }}
														</option>
													</select>
													<date-picker
														v-if="setDateBox(dsaDataLink)"
														:class="errors.has(validIndex + '_dataLinkDsa_' + dsaDataLinkIndex + '_fieldDateValue') ? 'input-crud-error' : ''"
														:type="getViewOption(dsaDataLink)"
														:format="getDateFormat(dsaDataLink)"
														:lang="lang"
														v-model="dsaDataLink.fieldValue"
														value-type="format"
														v-validate="'required'"
														data-vv-as="Field Date Value"
														placeholder="Please enter the date value."
														style="width: calc(45% - 62px); height: 32px; font-size: 10px;"
														:style="(dsaDataLink.fieldValue == '' ? {color: '#ccc'} : {})"
														confirm
													>
													</date-picker> -->
													<button
														v-if="dsaDataLinkIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addLinkDsaDetail(valid.dataLinkDsa.dataLinkDsaDetailList)"
													></button>
													<button
														v-if="dsaDataLinkIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeDsaDetail(valid.dataLinkDsa.dataLinkDsaDetailList, dsaDataLinkIndex)"
													></button>
												</div>
											</div>
										</div>
									</template>
									<!-- Hide Question Form -->
									<template v-if="valid.hideQuestionYn">
										<div class="con-group-wrap">
											<p class="con-group-title">
												Hide Question
											</p>
											<div class="result-row">
												<span class="result-row-label">
													If conditional formula is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionHide_conditionYn'"
															value="Y"
															v-model="valid.questionHide.conditionYn"
															data-vv-as="Question Hide Condition"
															v-validate="'required'"
														/><div class="radio" />
														True
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionHide_conditionYn'"
															value="N"
															v-model="valid.questionHide.conditionYn"
															data-vv-as="Question Hide Condition"
															v-validate="'required'"
														/><div class="radio" />
														False
													</label>
												</div>
											</div>
											<div class="result-row">
												<span class="result-row-label">
													If data remove is
												</span>
												<div class="result-row-form">
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionHide_dataRemoveYn'"
															value="Y"
															v-model="valid.questionHide.dataRemoveYn"
															data-vv-as="Question Hide Data Remove"
															v-validate="'required'"
														/><div class="radio" />
														Yes
													</label>
													<label class="radio-crud">
														<input
															type="radio"
															:name="validIndex + '_questionHide_dataRemoveYn'"
															value="N"
															v-model="valid.questionHide.dataRemoveYn"
															data-vv-as="Question Hide Data Remove"
															v-validate="'required'"
														/><div class="radio" />
														No
													</label>
												</div>
											</div>
											<div
												v-for="(hideQuestion, hideQuestionIndex) in valid.questionHide.validResultHideDetailList"
												:key="hideQuestionIndex"
												class="result-row"
											>
												<span class="result-row-label">
													{{ hideQuestionIndex == 0 ? 'Hide Question is' : '' }}
												</span>
												<div class="result-row-form result-row-list">
													<div class="drop-list-wrap">
														<div
															class="drop-list"
															:class="errors.has(validIndex + '_questionHide_' + hideQuestionIndex + '_question') ? 'input-crud-error' : ''"
															:title="
																(
																	!utils.isEmpty(hideQuestion.value) ?
																	hideQuestion.value.valueText :
																	'Please select the question'
																)
															"
															:style="!utils.isEmpty(hideQuestion.value) ? {color: '#999'} : {}"
															@click="openPageList(validIndex + '_questionHide_' + hideQuestionIndex + '_question', $event);"
														>
															{{
																(
																	!utils.isEmpty(hideQuestion.value) ?
																	hideQuestion.value.valueText :
																	'Please select the question'
																)
															}}
														</div>
														<DropList
															v-if="openFieldIndex == (validIndex + '_questionHide_' + hideQuestionIndex + '_question')"
															v-model="hideQuestion.value"
															:validVisitList="validVisitList"
															:fieldTypeList="fieldTypeList"
															endDepth="FIELD"
															@input="setDropValue(hideQuestion, 'hideQuestion')"
														/>
														<!-- :validVisitList="visitSortList[validIndex]" -->
														<input
															type="hidden"
															:name="validIndex + '_questionHide_' + hideQuestionIndex + '_question'"
															v-model="hideQuestion.value"
															v-validate="'required'"
															data-vv-as="Hide Question"
														/>
													</div>
													<button
														v-if="hideQuestionIndex == 0"
														type="button"
														class="btn-add-row"
														title="Add Row"
														@click.stop="addDsaDetail(valid.questionHide.validResultHideDetailList)"
													></button>
													<button
														v-if="hideQuestionIndex != 0"
														type="button"
														class="btn-remove-row"
														title="Remove Row"
														@click.stop="removeDsaDetail(valid.questionHide.validResultHideDetailList, hideQuestionIndex)"
													></button>
												</div>
											</div>
										</div>
									</template>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</form>
		</div>
		<div class="full-bottom-wrap">
			<button v-if="workAccessCheck('CREATE') && !(projectStateCd == '4' || projectStateCd == '3')" type="button" class="btn-save fright" title="Save" @click.stop="doValidation()">
				Save
			</button>
			<button type="button" class="btn-cancel fright" title="Cancel" @click.stop="doCancel()">
				Cancel
			</button>
		</div>
		<ValidCalculationAdd v-if="isCalcAdd" :calcTarget="this.calcTarget" :indexList="this.indexList"/>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
import moment from "moment";
import DropList from "@/components/project/validation/DropList.vue";
import ValidCalculationAdd from "@/components/project/validation/ValidCalculationAdd.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "ValidationCreate",
	created() {
		this.logger.debug(this, "created()");

		this.item.projectNo = this.$route.params.projectNo;
		this.item.firstRegistDate = moment().format("YYYY-MM-DD");
		this.item.firstRegistUserId = sessionStorage.userName + " (" + sessionStorage.userId + ")";

		this.selectValidVisitList();
		this.getDateTypeList();
		this.selectVisitList();
		this.selectResultTypeList();
		this.selectOperatorCdList();
		this.selectGroupOperatorCdList();
		this.selectFieldTypeCdList();
		this.getAccessList('VALIDATION');
		this.getProjectStateCd();
		
		// 로딩바 종료
		this.$nextTick(function () {
			setTimeout(function() {
				EventBus.$emit("project-loading", false);
			}.bind(this), 400);
		});

		EventBus.$on("blurDropList", () => {
			this.logger.debug(this, "[EventBus] blurDropList");
			this.openFieldIndex = "";
		});
	},
	beforeDestroy(){
		EventBus.$off('blurDropList');
	},
	components: {
		DropList,
		ValidCalculationAdd,
		DatePicker
	},
	data() {
		return {
			// 조회 및 로드를 위한 데이터
			isDuplicate: false,
			item: {},
			visitList: [],
			visitSortList: [],
			validVisitList: [],
			dateTypeList: [],
			resultTypeList: [],
			operatorList: [],
			groupOperatorList: [],
			pageList: [],
			fieldTypeList: [],
			openFieldIndex: "",
			isCalcAdd: false,
			calcTarget: {},
			indexList: {},
			// 입력, 수정을 위한 데이터
			validForm: {
				projectNo: this.$route.params.projectNo,
				visitNo: "",
				validationName: "",
				useYn: "Y",
				// Validation 상세 리스트
				validDetailList: [
					{
						sortOrder: 1,
						printMsgYn: false,
						systemPopupYn: false,
						disableVisitYn: false,
						disablePageYn: false,
						disableProcYn: false,
						disableQuestionYn : false,
						hideQuestionYn : false,
						gotoPageYn : false,
						dynamicYn : false,
						dataLinkYn : false,
						// Validation별 조건 그룹 리스트 
						validConList: [
							{
								sortOrder: 1,
								groupOperator: "",
								// 그룹 조건식 상세 리스트
								validConDetailList: [
									{
										sortOrder: 1,
										ogTypeCd: "FIELD",
										visitNo: "",
										pageNo: "",
										procNo: "",
										procQuestionNo: "",
										procFieldNo: "",
										operatorCd: "",
										targetTypeCd: "FIELD",
										targetVisitNo: "",
										targetPageNo: "",
										targetProcNo: "",
										targetProcQuestionNo: "",
										targetProcFieldNo: "",
										targetValue: "",
										logicalOperatorCd: "",
										ogCalculateForm: {},
										targetCalculateForm: {},
										ogCalculateNo: "",
										targetCalculateNo: "",
										value: {},
										target: {},
										isRepeat: "",
										targetIsRepeat: ""
									}
								]
							}
						],
						// Result Msg
						validResultMsg: {
							conditionYn: 'Y',
							printTypeCd: 'MESSAGE',
							dataRemoveYn: '',
							printMsg: "",
							visitNo: "",
							pageNo: "",
							procNo: "",
							procQuestionNo: ""
						},
						// Result Popup
						validResultPopup: {
							conditionYn: 'Y',
							printTypeCd: 'POPUP',
							dataRemoveYn: '',
							printMsg: "",
							visitNo: "",
							pageNo: "",
							procNo: "",
							procQuestionNo: ""
						},
						// Result Disbled
						visitDsa: {},
						pageDsa: {},
						procDsa: {},
						questionDsa: {},
						questionHide: {},
						gotoPageDsa: {
							pageTypeCd: 'GOTO',
							conditionYn: 'Y',
							printMsg: "",
						},
						dynamicDsa: {
							pageTypeCd: 'DYNAMIC',
							printMsg: "",
						},
						dataLinkDsa: {}
					}
				]
			},
			// 추가 삭제를 위한 VO
			validDetailForm: {
				sortOrder: 1,
				printMsgYn: false,
				systemPopupYn: false,
				disableVisitYn: false,
				disablePageYn: false,
				disableProcYn: false,
				disableQuestionYn : false,
				hideQuestionYn : false,
				gotoPageYn : false,
				dynamicYn : false,
				dataLinkYn : false,
				validConList: [],
				validResultMsg: {
					conditionYn: 'Y',
					printTypeCd: 'MESSAGE',
					dataRemoveYn: '',
					printMsg: "",
					visitNo: "",
					pageNo: "",
					procNo: "",
					procQuestionNo: ""
				},
				validResultPopup: {
					conditionYn: 'Y',
					printTypeCd: 'POPUP',
					dataRemoveYn: '',
					printMsg: "",
					visitNo: "",
					pageNo: "",
					procNo: "",
					procQuestionNo: ""
				},
				visitDsa: {},
				pageDsa: {},
				procDsa: {},
				questionDsa: {},
				questionHide: {},
				gotoPageDsa: {
					pageTypeCd: 'GOTO',
					conditionYn: 'Y',
					printMsg: "",
				},
				dynamicDsa: {
					pageTypeCd: 'DYNAMIC',
					printMsg: "",
				},
				dataLinkDsa: {}
			},
			validConForm: {
				sortOrder: 1,
				groupOperator: "",
				validConDetailList: []
			},
			validConDetailForm: {
				sortOrder: 1,
				ogTypeCd: "FIELD",
				visitNo: "",
				pageNo: "",
				procNo: "",
				procQuestionNo: "",
				procFieldNo: "",
				operatorCd: "",
				targetTypeCd: "FIELD",
				targetVisitNo: "",
				targetPageNo: "",
				targetProcNo: "",
				targetProcQuestionNo: "",
				targetProcFieldNo: "",
				logicalOperatorCd: "",
				ogCalculateForm: {},
				targetCalculateForm: {},
				ogCalculateNo: "",
				targetCalculateNo: "",
				value: {},
				target: {},
				isRepeat: "",
				targetIsRepeat: ""
			},
			validResultDsaForm: {
				disabledTypeCd: "",
				conditionYn: "Y",
				dataRemoveYn: '',
				validResultDsaDetailList: [
					{
						sortOrder: "1",
						visitNo: "",
						pageNo: "",
						procNo: "",
						procQuestionNo: ""
					}
				],
				excludeFieldList: [
					{
						sortOrder: "1",
						visitNo: "",
						pageNo: "",
						procNo: "",
						procQuestionNo: ""
					}
				]
			},
			validResultHideForm: {
				disabledTypeCd: "",
				conditionYn: "Y",
				dataRemoveYn: '',
				validResultHideDetailList: [
					{
						sortOrder: "1",
						visitNo: "",
						pageNo: "",
						procNo: "",
						procQuestionNo: ""
					}
				],
			},
			dataLinkDsaForm: {
				conditionYn: "Y",
				dataLinkDsaDetailList: [
					{
						sortOrder: "1",
						visitNo: "",
						pageNo: "",
						procNo: "",
						procQuestionNo: "",
						procFieldNo: "",
						targetTypeCd: "VALUE",
						targetVisitNo: "",
						targetPageNo: "",
						targetProcNo: "",
						targetProcQuestionNo: "",
						targetProcFieldNo: "",
						value: {},
						target: {},
						targetValue: ""
					}
				]
			},
			validResultDsaDetailForm: {
				sortOrder: "1",
				visitNo: "",
				pageNo: "",
				procNo: "",
				procQuestionNo: ""
			},
			dataLinkDsaDetailForm: {
				sortOrder: "1",
				visitNo: "",
				pageNo: "",
				procNo: "",
				procQuestionNo: "",
				procFieldNo: "",
				targetTypeCd: "VALUE",
				targetVisitNo: "",
				targetPageNo: "",
				targetProcNo: "",
				targetProcQuestionNo: "",
				targetProcFieldNo: "",
				value: {},
				target: {},
				targetValue: ""
			},
			lang: {
				formatLocale: {
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
					weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토']
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
			accessList: [],
			projectStateCd: ""
		};
	},
	methods: {
		// 초기 조회 기능 시작 (visit-부터 쭉 test)
		selectValidVisitList() {
			this.logger.debug(this, "selectValidVisitList()");
			axios
				.post(this.config.contextRoot + "/valid/selectValidVisitList.do", this.item)
				.then((response) => {
					console.log(response);
					this.validVisitList = response.data.validVisitList;
					this.pageList = response.data.validVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// 초기 조회 기능 시작
		selectVisitList() {
			this.logger.debug(this, "selectVisitList()");
			axios
				.post(this.config.contextRoot + "/visit/selectList.do", this.item)
				.then((response) => {
					console.log(response);
					this.visitList = response.data.visitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectResultTypeList() {
			this.logger.debug(this, "selectResultTypeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "VALIDATION_RESULT_CD"})
				.then((response) => {
					console.log(response);
					this.resultTypeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectOperatorCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "FIELD_OPERATOR_CD"})
				.then((response) => {
					console.log(response);
					this.operatorList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectGroupOperatorCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "GROUP_OPERATOR_CD"})
				.then((response) => {
					console.log(response);
					this.groupOperatorList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectFieldTypeCdList() {
			this.logger.debug(this, "selectOperatorCdList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "FIELD_TYPE_CD"})
				.then((response) => {
					console.log(response);
					this.fieldTypeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectPageList() {
			this.logger.debug(this, "selectPageList()");
			axios
				.post(this.config.contextRoot + "/valid/selectVisitPageList.do", {visitNo: this.validForm.visitNo})
				.then((response) => {
					console.log(response);
					this.pageList = response.data.pageList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		// 초기 조회 기능 끝
		// Form 관련 기능 시작
		addValidDetailForm() {
			this.logger.debug(this, "addValidDetailForm()");
			let getDetailForm = JSON.parse(JSON.stringify(this.validDetailForm));
			let validConForm = JSON.parse(JSON.stringify(this.validConForm));
			let validConDetailForm = JSON.parse(JSON.stringify(this.validConDetailForm));

			getDetailForm.sortOrder = this.validForm.validDetailList.length + 1;
			getDetailForm.validConList.push(validConForm);
			getDetailForm.validConList[0].validConDetailList.push(validConDetailForm);

			this.validForm.validDetailList.push(getDetailForm);
		},
		removeValidDetailForm(validIndex) {
			this.logger.debug(this, "removeValidDetailForm(validIndex)");
			if(this.validForm.validDetailList.length == 1)
			{
				this.$alert("At least one validation must exist!", "Warn", "warning");
			}
			else
			{
				this.validForm.validDetailList.splice(validIndex, 1);
			}
		},
		addValidConForm(validDetailIndex) {
			this.logger.debug(this, "addValidConForm(validIndex)");
			let validConForm = JSON.parse(JSON.stringify(this.validConForm));
			let validConDetailForm = JSON.parse(JSON.stringify(this.validConDetailForm));

			validConForm.sortOrder = this.validForm.validDetailList[validDetailIndex].validConList.length + 1;
			validConForm.validConDetailList.push(validConDetailForm);

			this.validForm.validDetailList[validDetailIndex].validConList.push(validConForm);
		},
		removeValidConForm(validDetailIndex, conIndex) {
			this.logger.debug(this, "removeValidConForm(validIndex, conIndex)");
			if(this.validForm.validDetailList[validDetailIndex].validConList.length == 1)
			{
				this.$alert("At least one group must exist!", "Warn", "warning");
			}
			else
			{
				this.validForm.validDetailList[validDetailIndex].validConList.splice(conIndex, 1);
			}
		},
		addValidConDetailForm(validDetailIndex, validConIndex) {
			this.logger.debug(this, "addValidConDetailForm(validDetailIndex, validConIndex)");
			let validConDetailForm = JSON.parse(JSON.stringify(this.validConDetailForm));

			validConDetailForm.sortOrder = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.length + 1;

			this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.push(validConDetailForm);
		},
		removeValidConDetailForm(validDetailIndex, validConIndex, validConDetailIndex) {
			this.logger.debug(this, "removeValidConDetailForm(validDetailIndex, validConIndex, validConDetailIndex)");
			if(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.length == 1)
			{
				this.$alert("At least one row must exist!", "Warn", "warning");
			}
			else
			{
				this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.splice(validConDetailIndex, 1);

				//마지막 행 Logical Operator Code 초기화
				let detailLength = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList.length;
				this.$set(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[detailLength - 1], "logicalOperatorCd", "");
			}
		},
		setOgTypeCd(validDetailIndex, validConIndex, validConDetailIndex, value, isRepeat) {
			this.logger.debug(this, "setOgTypeCd(validDetailIndex, validConIndex, validConDetailIndex, 'FIELD', isRepeat)");
			this.$set(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex], "ogTypeCd", value);

			let target = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex];
			if(value == 'FIELD')
			{
				this.isCalcAdd = false;
				target.calculateNo = "";
				target.ogCalculateForm = {};
			}
			else
			{
				if(target.ogCalculateForm.calculateNo != ""){
					this.calcTarget = target.ogCalculateForm;
				}
				else{
					this.calcTarget = {};
				}
				this.$set(this.indexList, "validDetailIndex", validDetailIndex);
				this.$set(this.indexList, "validConIndex", validConIndex);
				this.$set(this.indexList, "validConDetailIndex", validConDetailIndex);
				this.$set(this.indexList, "type", "OG");
				this.$set(this.indexList, "isRepeat", isRepeat);
				this.isCalcAdd = true;
				target.value = {};
				target.visitNo = "";
				target.pageNo = "";
				target.procNo = "";
				target.procQuestionNo = "";
				target.procFieldNo = "";
			}
			this.openFieldIndex = "";
		},
		setCalcData(indexList, calclationForm, isRepeat){
			if(indexList.type == "OG"){
				this.validForm.validDetailList[indexList.validDetailIndex].validConList[indexList.validConIndex].validConDetailList[indexList.validConDetailIndex].ogCalculateForm = calclationForm;
				this.validForm.validDetailList[indexList.validDetailIndex].validConList[indexList.validConIndex].validConDetailList[indexList.validConDetailIndex].isRepeat = isRepeat;
			}
			else{
				this.validForm.validDetailList[indexList.validDetailIndex].validConList[indexList.validConIndex].validConDetailList[indexList.validConDetailIndex].targetCalculateForm = calclationForm;
				this.validForm.validDetailList[indexList.validDetailIndex].validConList[indexList.validConIndex].validConDetailList[indexList.validConDetailIndex].targetIsRepeat = isRepeat;
			}
		},
		setTargetTypeCdDataLink(validDetailIndex, dsaDataLinkIndex, value){
			this.$set(this.validForm.validDetailList[validDetailIndex].dataLinkDsa.dataLinkDsaDetailList[dsaDataLinkIndex], "targetTypeCd", value);

			let target = this.validForm.validDetailList[validDetailIndex].dataLinkDsa.dataLinkDsaDetailList[dsaDataLinkIndex];

			if(value == 'FIELD')
			{
				target.targetValue = "";
			}
			else if(value == 'VALUE')
			{
				target.target = {};
				target.targetVisitNo = "";
				target.targetPageNo = "";
				target.targetProcNo = "";
				target.targetProcQuestionNo = "";
				target.targetProcFieldNo = "";
			}
		},
		setTargetTypeCd(validDetailIndex, validConIndex, validConDetailIndex, value, isRepeat) {
			this.logger.debug(this, "setTargetTypeCd(validDetailIndex, validConIndex, validConDetailIndex, 'FIELD', isRepeat)");
			this.$set(this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex], "targetTypeCd", value);

			let target = this.validForm.validDetailList[validDetailIndex].validConList[validConIndex].validConDetailList[validConDetailIndex];
			if(value == 'FIELD')
			{
				this.isCalcAdd = false;
				target.targetValue = "";
				target.targetCalculateForm = {};
			}
			else if(value == 'VALUE')
			{
				this.isCalcAdd = false;
				target.target = {};
				target.targetVisitNo = "";
				target.targetPageNo = "";
				target.targetProcNo = "";
				target.targetProcQuestionNo = "";
				target.targetProcFieldNo = "";
				target.targetCalculateForm = {};
			}
			else if(value == 'EMPTY')
			{
				this.isCalcAdd = false;
				target.target = {};
				target.targetVisitNo = "";
				target.targetPageNo = "";
				target.targetProcNo = "";
				target.targetProcQuestionNo = "";
				target.targetProcFieldNo = "";
				target.targetCalculateForm = {};
			}
			else {
				if(target.targetCalculateForm.calculateNo != ""){
					this.calcTarget = target.targetCalculateForm;
				}
				else{
					this.calcTarget = {};
				}
				this.$set(this.indexList, "validDetailIndex", validDetailIndex);
				this.$set(this.indexList, "validConIndex", validConIndex);
				this.$set(this.indexList, "validConDetailIndex", validConDetailIndex);
				this.$set(this.indexList, "type", "TARGET");
				this.$set(this.indexList, "isRepeat", isRepeat);
				this.isCalcAdd = true;
				target.targetValue = "";
				target.target = {};
				target.targetVisitNo = "";
				target.targetPageNo = "";
				target.targetProcNo = "";
				target.targetProcQuestionNo = "";
				target.targetProcFieldNo = "";
			}
			this.openFieldIndex = "";
		},
		setDsa(valid, comCode) {
			this.logger.debug(this, "setDsa(valid, comCode)");
			let dsaType = "";
			let disabledTypeCd = "";
			let dataRemoveYn = "";

			if(comCode == "disableVisitYn")
			{
				dsaType = "visitDsa";
				disabledTypeCd = "VISIT";
				dataRemoveYn = "Y";
			}
			else if(comCode == "disablePageYn")
			{
				dsaType = "pageDsa";
				disabledTypeCd = "PAGE";
				dataRemoveYn = "Y";
			}
			else if(comCode == "disableProcYn")
			{
				dsaType = "procDsa";
				disabledTypeCd = "PROC";
				dataRemoveYn = "Y";
			}
			else if(comCode == "disableQuestionYn")
			{
				dsaType = "questionDsa";
				disabledTypeCd = "QUESTION";
				dataRemoveYn = "Y";
			}
			else if(comCode == "hideQuestionYn")
			{
				dsaType = "questionHide";
				disabledTypeCd = "QUESTION";
				dataRemoveYn = "Y";
			}
			else if(comCode == "dataLinkYn")
			{
				dsaType = "dataLinkDsa";
			}

			if(valid[comCode] && comCode != "dataLinkYn" && comCode != "hideQuestionYn")
			{
				let validResultDsaForm = JSON.parse(JSON.stringify(this.validResultDsaForm));
				validResultDsaForm.disabledTypeCd = disabledTypeCd;
				validResultDsaForm.dataRemoveYn = dataRemoveYn;
				valid[dsaType] = validResultDsaForm;
			}
			else if(valid[comCode] && comCode == "dataLinkYn"){
				let dataLinkDsaForm = JSON.parse(JSON.stringify(this.dataLinkDsaForm));
				valid[dsaType] = dataLinkDsaForm;
			}
			else if(valid[comCode] && comCode == "hideQuestionYn")
			{
				let validResultHideForm = JSON.parse(JSON.stringify(this.validResultHideForm));
				validResultHideForm.hideTypeCd = disabledTypeCd;
				validResultHideForm.dataRemoveYn = dataRemoveYn;
				valid[dsaType] = validResultHideForm;
			}
			else
			{
				valid[dsaType] = {};
			}
		},
		setSelectBox(conDetail){
			let value = conDetail.value;
			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.listFirstProcQuestionNo == ""){
												if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
													for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
														if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
															let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
															if(fieldTypeCd == '03' || fieldTypeCd == '04' || fieldTypeCd == '05'){
																return true;
															}
															else{
																return false;
															}
														}
													}
												}
											}
											else{
												if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.listFirstProcQuestionNo){
													for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
														if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.listFirstProcFieldNo){
															let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
															if(fieldTypeCd == '03' || fieldTypeCd == '04' || fieldTypeCd == '05'){
																return true;
															}
															else{
																return false;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}else{
				return false;
			}
		},
		setDateBox(conDetail){
			let value = conDetail.value;
			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
														if(fieldTypeCd == '08'){
															return true;
														}
														else{
															return false;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}else{
				return false;
			}
		},
		setRadioCheckSelectBox(conDetail){
			let value = conDetail.value;
			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
														if(fieldTypeCd == '03' || fieldTypeCd == '04' || fieldTypeCd == '05'){
															return true;
														}
														else{
															return false;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}else{
				return false;
			}
		},
		getViewOption(conDetail) {
			let value = conDetail.value;
			let type = "";
			let format = "";
			let result = "";

			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														type = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].dataTypeCd;
														format = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].dateFormat;
														if(type == 3 || type == 11){
															if(format.indexOf("YYYY") >= 0 && format.indexOf("MM") < 0 && format.indexOf("DD") < 0) 
																result = "year";
															else if(format.indexOf("YYYY") >= 0 && format.indexOf("MM") >= 0 && format.indexOf("DD") < 0 || format.indexOf("YYYY") < 0 && format.indexOf("MM") >= 0 && format.indexOf("DD") < 0) 
																result = "month";
															else result = "day";
														} else if (type == 4 || type == 12){
															result = "time";
														} else {
															result = "datetime";
														}
														return result;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		getDateFormat(conDetail) {
			let value = conDetail.value;
			let format = "";
			let result = "";

			if(Object.keys(value).length != 0){
				for(let i = 0; i < value.visitSortList.length; i++){
					if(value.visitSortList[i].visitNo == value.visitNo){
						for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
							if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
								for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
									if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
										for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														format = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].dateFormat;
														result = format.replace('hh', 'HH');
														return result;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		optionList(value){
			let optionList = [];
			for(let i = 0; i < value.visitSortList.length; i++){
				if(value.visitSortList[i].visitNo == value.visitNo){
					for(let j = 0; j < value.visitSortList[i].projectDictionaryList.length; j++){
						if(value.visitSortList[i].projectDictionaryList[j].pageNo == value.pageNo){
							for(let k = 0; k < value.visitSortList[i].projectDictionaryList[j].procedureList.length; k++){
								if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procNo == value.procNo){
									for(let t = 0; t < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList.length; t++){
										if(value.listFirstProcQuestionNo == ""){
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.procQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.procFieldNo){
														let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
														if(fieldTypeCd == '03'){
															let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].radioOptionsLabel;
															let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].radioOptionsValue;
															let labelList = optionLabel.split(';');
															let valueList = optionValue.split(';');
															for(let y = 0; y < labelList.length; y++){
																optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
															}
															return optionList;
														}
														else if(fieldTypeCd == '04'){
															let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].checkboxOptionsLabel;
															let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].checkboxOptionsValue;
															let labelList = optionLabel.split(';');
															let valueList = optionValue.split(';');
															for(let y = 0; y < labelList.length; y++){
																optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
															}
															return optionList;
														}
														else if(fieldTypeCd == '05'){
															let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].selectOptionsLabel;
															let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].selectOptionsValue;
															let labelList = optionLabel.split(';');
															let valueList = optionValue.split(';');
															for(let y = 0; y < labelList.length; y++){
																optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
															}
															return optionList;
														}
													}
												}
											}
										}
										else{
											if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].procQuestionNo == value.listFirstProcQuestionNo){
												for(let p = 0; p < value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList.length; p++){
													if(value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].procFieldNo == value.listFirstProcFieldNo){
														let fieldTypeCd = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].fieldTypeCd;
														if(fieldTypeCd == '03'){
															let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].radioOptionsLabel;
															let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].radioOptionsValue;
															let labelList = optionLabel.split(';');
															let valueList = optionValue.split(';');
															for(let y = 0; y < labelList.length; y++){
																optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
															}
															return optionList;
														}
														else if(fieldTypeCd == '04'){
															let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].checkboxOptionsLabel;
															let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].checkboxOptionsValue;
															let labelList = optionLabel.split(';');
															let valueList = optionValue.split(';');
															for(let y = 0; y < labelList.length; y++){
																optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
															}
															return optionList;
														}
														else if(fieldTypeCd == '05'){
															let optionLabel = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].selectOptionsLabel;
															let optionValue = value.visitSortList[i].projectDictionaryList[j].procedureList[k].procQuestionList[t].fieldList[p].selectOptionsValue;
															let labelList = optionLabel.split(';');
															let valueList = optionValue.split(';');
															for(let y = 0; y < labelList.length; y++){
																optionList.push({optionLabel: labelList[y], optionValue: valueList[y]});
															}
															return optionList;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		addDsaDetail(detailList) {
			this.logger.debug(this, "addDsaDetail(detailList)");
			let sortOrder = detailList.length + 1;

			let validResultDsaDetailForm = JSON.parse(JSON.stringify(this.validResultDsaDetailForm));
			validResultDsaDetailForm.sortOrder = sortOrder;

			detailList.push(validResultDsaDetailForm);
		},
		removeDsaDetail(detailList, detailIndex) {
			this.logger.debug(this, "removeDsaDetail(detailList, detailIndex)");
			detailList.splice(detailIndex, 1);
		}, 
		addLinkDsaDetail(detailList) {
			this.logger.debug(this, "addLinkDsaDetail(detailList)");
			let sortOrder = detailList.length + 1;

			let dataLinkDsaDetailForm = JSON.parse(JSON.stringify(this.dataLinkDsaDetailForm));
			dataLinkDsaDetailForm.sortOrder = sortOrder;

			detailList.push(dataLinkDsaDetailForm);
		},
		// Form 관련 기능 끝
		// Drop List 관련 기능 시작
		openPageList(index, event) {
			this.logger.debug(this, index);
			this.$set(this, "openFieldIndex", index);
			this.$nextTick(() => {
				event.target.parentNode.children[1].children[0].focus();
			});
		},
		setDropValue(obj, target, validIndex) {
			if(target == 'conField')
			{
				if(obj.value.isRepeat == 'true' && obj.targetIsRepeat == 'true'){
					obj.value = {};
					obj.visitNo = "";
					obj.pageNo = "";
					obj.procNo = "";
					obj.procQuestionNo = "";
					obj.procFieldNo = "";
					obj.isRepeat = "";
					this.visitSortList[validIndex] = [];
					this.$alert("Repeated regions cannot be compared!", "Warning", "warning");
				}
				else if(obj.value.dateFormat != "" && obj.value.dateFormat != 'YYYY-MM-DD'){
					obj.value = {};
					obj.visitNo = "";
					obj.pageNo = "";
					obj.procNo = "";
					obj.procQuestionNo = "";
					obj.procFieldNo = "";
					obj.isRepeat = "";
					this.visitSortList[validIndex] = [];
					this.$alert("Only fields with YYYY-MM-DD format can be compared!\nUse function instead.", "Warning", "warning");
				}
				else {
					obj.visitNo = obj.value.visitNo;
					obj.pageNo = obj.value.pageNo;
					obj.procNo = obj.value.procNo;
					obj.procQuestionNo = obj.value.procQuestionNo;
					obj.procFieldNo = obj.value.procFieldNo;
					obj.isRepeat = obj.value.isRepeat;
					this.visitSortList[validIndex] = obj.value.visitSortList;
				}

				// //field 변경시 result 값 초기화
				// delete this.validForm.validDetailList[validIndex].validResultMsg['value'];
				// this.validForm.validDetailList[validIndex].validResultMsg.visitNo = "";
				// this.validForm.validDetailList[validIndex].validResultMsg.pageNo = "";
				// this.validForm.validDetailList[validIndex].validResultMsg.procNo = "";
				// this.validForm.validDetailList[validIndex].validResultMsg.procQuestionNo = "";

				// if(this.validForm.validDetailList[validIndex].pageDsa.length != 0)
				// {
				// 	for(let j = 0; j < this.validForm.validDetailList[validIndex].pageDsa.validResultDsaDetailList.length; j++)
				// 	{
				// 		delete this.validForm.validDetailList[validIndex].pageDsa.validResultDsaDetailList[j]['value'];
				// 		this.validForm.validDetailList[validIndex].pageDsa.validResultDsaDetailList[j].pageNo = "";
				// 		this.validForm.validDetailList[validIndex].pageDsa.validResultDsaDetailList[j].visitNo = "";
				// 	}
				// }

				// if(this.validForm.validDetailList[validIndex].procDsa.length != 0)
				// {
				// 	for(let j = 0; j < this.validForm.validDetailList[validIndex].procDsa.validResultDsaDetailList.length; j++)
				// 	{
				// 		delete this.validForm.validDetailList[validIndex].procDsa.validResultDsaDetailList[j]['value'];
				// 		this.validForm.validDetailList[validIndex].procDsa.validResultDsaDetailList[j].pageNo = "";
				// 		this.validForm.validDetailList[validIndex].procDsa.validResultDsaDetailList[j].procNo = "";
				// 		this.validForm.validDetailList[validIndex].procDsa.validResultDsaDetailList[j].visitNo = "";
				// 	}
				// }
				// if(this.validForm.validDetailList[validIndex].questionDsa.length != 0)
				// {
				// 	for(let j = 0; j < this.validForm.validDetailList[validIndex].questionDsa.validResultDsaDetailList.length; j++)
				// 	{
				// 		delete this.validForm.validDetailList[validIndex].questionDsa.validResultDsaDetailList[j]['value'];
				// 		this.validForm.validDetailList[validIndex].questionDsa.validResultDsaDetailList[j].pageNo = "";
				// 		this.validForm.validDetailList[validIndex].questionDsa.validResultDsaDetailList[j].procNo = "";
				// 		this.validForm.validDetailList[validIndex].questionDsa.validResultDsaDetailList[j].visitNo = "";
				// 		this.validForm.validDetailList[validIndex].questionDsa.validResultDsaDetailList[j].procQuestionNo = "";
				// 	}
				// }
				
			}
			else if(target == 'conTargetField')
			{
				if(obj.target.isRepeat == 'true' && obj.isRepeat == 'true'){
					obj.target = {};
					obj.targetVisitNo = "";
					obj.targetPageNo = "";
					obj.targetProcNo = "";
					obj.targetProcQuestionNo = "";
					obj.targetProcFieldNo = "";
					obj.targetIsRepeat = "";
					this.$alert("Repeated regions cannot be compared!", "Warning", "warning");
				}
				else{
					obj.targetVisitNo = obj.target.visitNo;
					obj.targetPageNo = obj.target.pageNo;
					obj.targetProcNo = obj.target.procNo;
					obj.targetProcQuestionNo = obj.target.procQuestionNo;
					obj.targetProcFieldNo = obj.target.procFieldNo;
					obj.targetIsRepeat = obj.target.isRepeat;
				}
			}
			else if(target == 'resultMsg')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
			}
			else if(target == 'resultPopup')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
			}
			else if(target == 'dsaVisit')
			{
				obj.visitNo = obj.value.visitNo;
			}
			else if(target == 'dsaPage')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
			}
			else if(target == 'dsaProc')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
			}
			else if(target == 'dsaQuestion')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
			}
			else if(target == 'hideQuestion')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
			}
			else if(target == 'dsaDataLink')
			{
				obj.visitNo = obj.value.visitNo;
				obj.pageNo = obj.value.pageNo;
				obj.procNo = obj.value.procNo;
				obj.procQuestionNo = obj.value.procQuestionNo;
				obj.procFieldNo = obj.value.procFieldNo;
				obj.targetValue = "";
				if(this.setRadioCheckSelectBox(obj)){
					obj.target = {};
					obj.targetVisitNo = "";
					obj.targetPageNo = "";
					obj.targetProcNo = "";
					obj.targetProcQuestionNo = "";
					obj.targetProcFieldNo = "";
					obj.targetIsRepeat = "";
					obj.targetTypeCd = "VALUE";
				}
			}
			else if(target == 'dsaDataLinkTarget')
			{
				obj.targetVisitNo = obj.target.visitNo;
				obj.targetPageNo = obj.target.pageNo;
				obj.targetProcNo = obj.target.procNo;
				obj.targetProcQuestionNo = obj.target.procQuestionNo;
				obj.targetProcFieldNo = obj.target.procFieldNo;
			}
			else if(target == 'gotoPage')
			{
				obj.goVisitNo = obj.goPage.visitNo;
				obj.goPageNo = obj.goPage.pageNo;
			}
			else if(target == 'gotoUnder')
			{
				obj.visitNo = obj.underQuestion.visitNo;
				obj.pageNo = obj.underQuestion.pageNo;
				obj.procNo = obj.underQuestion.procNo;
				obj.procQuestionNo = obj.underQuestion.procQuestionNo;
			}
		},
		getDateTypeList(){
			this.logger.debug(this, "getDateTypeList()");
			axios
				.post(this.config.contextRoot + "/valid/getDateTypeList.do", this.item)
				.then((response) => {
					console.log(response);
					this.dateTypeList = response.data.validVisitList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		getOperatorList(targetType){
			if(targetType == 'EMPTY'){
				var operatorList = [];
				this.operatorList.forEach((obj) =>{
					if(obj.comCode == '01' || obj.comCode == '02'){
						operatorList.push(obj);
					}
				});
				return operatorList;
			}
			else{
				return this.operatorList
			}
		},
		// Drop List 관련 기능 끝
		// 데이터 저장 및 기본 동작 기능 시작
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if (result && !this.isDuplicate)
					{
						// type check 최소 한개 이상 여부 검사
						let isTypeCheck = true;
						for(let i = 0 ; i < this.validForm.validDetailList.length ; i++)
						{
							let validDetail = this.validForm.validDetailList[i];
							if(!validDetail.printMsgYn && !validDetail.disablePageYn && !validDetail.disableProcYn && !validDetail.disableQuestionYn && !validDetail.disableVisitYn && !validDetail.gotoPageYn && !validDetail.systemPopupYn && !validDetail.dynamicYn && !validDetail.dataLinkYn && !validDetail.hideQuestionYn)
							{
								isTypeCheck = false;
								break;
							}
						}
						if(isTypeCheck)
						{
							this.$confirm("Do you want to register the current validation?", "Question", "question").then(() => {
								this.doInsert();
							});
						}
						else
						{
							this.$alert("At least one result type must be checked!", "Warning", "warning");
							return false;
						}
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doInsert() {
			this.logger.debug(this, "doInsert()");
			axios
				.post(this.config.contextRoot + "/valid/insert.do", this.validForm)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						EventBus.$emit("project-loading", true);
						var url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/validation";
						if (this.$route.path !== url)
						{
							this.$router.push(url);
						}
					}
					else
					{
						this.$alert(response, "Error", "error");
						console.log(response);
					}
				})  
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the validation registration?", "Info", "info").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.validationCreate.style.animation = "fadeOutUp 0.4s ease-in-out both";
			setTimeout(function() {
				let url = this.config.contextRoot + "/project/" + this.$route.params.projectNo + "/validation"
				if (this.$route.path !== url)
				{
					EventBus.$emit("project-loading", true);
					this.$router.push(url);
				}
			}.bind(this), 400);
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/valid/duplicateCheck.do", this.validForm)
				.then((response) => {
					console.log(response);
					if(response.data.validExistCount == 0)
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				});
		},
		// 데이터 저장 및 기본 동작 기능 끝
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				projectNo: this.$route.params.projectNo,
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
		getProjectStateCd() {
			// project 셋팅
			axios
				.post(this.config.contextRoot + "/project/select.do", {projectNo: this.$route.params.projectNo})
				.then((response) => {
					console.log(response);
					this.projectStateCd = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		validSelectCheck(type, validYn){
			console.log(type, validYn);
			let isTypeCheck = true;
			for(let i = 0 ; i < this.validForm.validDetailList.length ; i++)
			{
				let validDetail = this.validForm.validDetailList[i];
				if(!validDetail.printMsgYn && !validDetail.disablePageYn && !validDetail.disableProcYn && !validDetail.disableQuestionYn && !validDetail.disableVisitYn && !validDetail.gotoPageYn && !validDetail.systemPopupYn && !validDetail.dynamicYn && !validDetail.dataLinkYn && !validDetail.hideQuestionYn)
				{
					isTypeCheck = false;
					break;
				}
			}
			if(isTypeCheck)
			{
				for(let i = 0 ; i < this.validForm.validDetailList.length ; i++)
				{
					let validDetail = this.validForm.validDetailList[i];
					this.$nextTick(function () {
						setTimeout(function() {
							// this.$alert("You must select only one result type!", "Warning", "warning");
							validDetail[type] = false;
						}.bind(this), 100);
					});
				}
			}
		}
	}
};
</script>

<style scoped>
.txt-form-title {
	height: 35px;
	line-height: 35px;
	margin: 20px 0 5px;
	padding: 0 10px;
	font-weight: 600;
	font-size: 13pt;
	color: #666;
}

caption, .txt-form-stitle {
	margin: 20px 0 5px;
	padding: 0 20px;
	font-weight: 600 !important;
	font-size: 11pt !important;
}

caption {
	margin: 50px 0 5px;
}

.txt-form-stitle {
	height: 35px;
	line-height: 35px;
}

.select-crud {
	padding: 0 10px;
}

.con-group-wrap {
	margin: 10px 0;
	padding: 20px;
	border-radius: 10px;
	background-color: #f5f5f5;
}

.con-group-title {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 25px;
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 11pt;
	color: #2bade0;
	border-bottom: 1px solid #2bade0;
    padding: 10px 0px;
}

.con-operator-title {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 25px;
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 11pt;
	color: #2bade0;
}

.btn-small-remove {
	width: 20px;
	height: 20px;
	border: 1px solid #999;
	border-radius: 3px;
	background-color: #fff;
}

.con-detail-wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 15px 0 0;
}

.btn-add-row {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #2bade0;
}

.btn-remove-row {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: #c44944;
}

.con-detail-wrap > .label > p {
	margin: 0 0 5px;
	font-weight: 600;
	font-size: 10pt;
}

.select-field-value {
	font-weight: 600;
	font-size: 10pt;
	color: #999;
	text-decoration: underline;
}

.select-field-value:hover {
	color: #666;
}

.selected-field-value {
	color: #2bade0;
}

.selected-field-value:hover {
	color: #2bade0;
}

.con-detail-wrap > .label:nth-child(1) {
	width: 35%;
}

.con-detail-wrap > .label:nth-child(2) {
	width: 10%;
}

.con-detail-wrap > .label:nth-child(3) {
	width: 35%;
}

.con-detail-wrap > .label:nth-child(4) {
	width: 10%;
}

.drop-list-wrap {
	position: relative;
	width:100%;
	height: 32px;
}

.drop-list {
	display: block;
	position: relative;
	width: 100%;
	height: 32px;
	line-height: 31px;
	padding: 0 30px 0 15px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 10pt;
	color: #ccc;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-user-select: none;
	-webkit-user-select: none;
	cursor: unset;
}

.drop-list::after {
	content: ">";
	display: inline-block;
	position: absolute;
	top: 0;
	right: 10px;
	font-weight: 600;
	font-family: cursive;
	transform: rotate(90deg);
}

.result-row {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 0 10px;
}

.result-row-label {
	width: 25%;
	font-weight: 600;
	font-size: 11pt;
}

.result-row-form {
	width: 75%;
	font-size: 11pt;
}

.result-row-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.result-row-list > .drop-list-wrap {
	width: calc(100% - 47px);
}

.btn-cancel {
	margin-right: 10px;
}
.date-crud input{
	height: 32px !important;
	border: 1px solid #ccc;
    font-size: 10pt;
}
</style>
<style>
.mx-input{
	height: 32px;
	font-size: 10pt;
	padding-left: 15px;
	color: rgb(153, 153, 153);
	border-radius: 0px;
	box-shadow: none;
}
.mx-input:hover, .mx-input:focus {
	border: 1px solid #ccc;
}
.dynamic-warn{
	font-size: 8pt;
    color: rgb(224, 91, 91);
}
</style>

