<template>
	<div ref="projectEdit" class="dialog-container">
		<div class="dialog" style="max-height: 600px; transform: translate(0, -300px);">
			<form @submit.stop="doValidation()">
				<div class="top"> 
					<p class="txt-crud-title">
						Project Edit
					</p>
				</div>
				<div class="center">
					<table class="tb-crud-horizon">
						<colgroup>
							<col width="17%" />
							<col width="83%" />
						</colgroup>
						<tbody>
							<tr>
								<th>Project Type</th>
								<td>
									<label
										v-for="(option, index) in projectTypeList"
										:key="index"
										class="radio-crud project-crud"
										:class="option == 'Attendance' ? 'radio-crud-disabled' : ''"
									>
										<input
											type="radio"
											name="projectType"
											v-validate="'required'"
											data-vv-as="Project Type"
											:value="index+1"
											v-model="items.projectType"
											:disabled="option == 'Attendance'"
										/>
										<div class="radio" />
										{{option}}
									</label>
								</td>
							</tr>
							<tr>
								<th>Protocol</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="protocolName"
										v-model="items.protocolName"
										v-validate="'required'"
										data-vv-as="Protocol"
									/>
									<!-- <input
										type="text"
										class="input-crud"
										name="protocolName"
										v-model="items.protocolName"
										v-validate="'required'"
										data-vv-as="Protocol"
										@keyup="doDuplicateCheck()"
									/> -->
									<p class="txt-validation" v-if="errors.has('protocolName')">
										* {{ errors.first("protocolName") }}
									</p>
									<!-- <p class="txt-validation" v-if="isDuplicate">
										* Protocol is duplicated.
									</p> -->
								</td>
							</tr>
							<tr>
								<th>Study Title</th>
								<td>
									<input
										type="text"
										class="input-crud"
										name="projectStudyTitle"
										v-model="items.projectStudyTitle"
										v-validate="'required'"
										data-vv-as="Study Title"
									/>
									<p class="txt-validation" v-if="errors.has('projectStudyTitle')">
										* {{ errors.first("projectStudyTitle") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>Project Manager</th>
								<td>
									<input
										type="text"
										class="input-crud half"
										:class="(errors.has('projectManager') ? 'input-crud-error' : '')"
										name="projectManager"
										v-model="items.projectManager"
										v-validate="'required'"
										data-vv-as="Project Manager"
										:readonly="!isDInputPM"
									/>
									<select
										class="select-crud"
										:class="(errors.has('projectManager') ? 'input-crud-error' : '')"
										@input="selectValue($event.target.value, 'projectManager')"
										:disabled="isDInputPM"
									>
										<option value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in userList" :key="index" :value="item.userName">
											{{ item.userName }}
										</option>
									</select>
									<label class="checkbox-crud">
										<input type="checkbox" v-model="isDInputPM" />
										<span class="checkbox"></span>
										Direct Input
									</label>
								</td>
							</tr>
							<tr>
								<th>Lead Manager</th>
								<td>
									<input
										type="text"
										class="input-crud half"
										name="leadResearcher"
										v-model="items.leadResearcher"
										data-vv-as="Lead Researcher"
										:readonly="!isDInputLM"
									/>
									<select
										class="select-crud"
										@input="selectValue($event.target.value, 'leadResearcher')"
										:disabled="isDInputLM"
									>
										<option value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in userList" :key="index" :value="item.userName">
											{{ item.userName }}
										</option>
									</select>
									<label class="checkbox-crud">
										<input type="checkbox" v-model="isDInputLM" />
										<span class="checkbox"></span>
										Direct Input
									</label>
								</td>
							</tr>
							<tr>
								<th>Sponsor</th>
								<td>
									<input
										type="text"
										class="input-crud half"
										:class="(errors.has('sponsor') ? 'input-crud-error' : '')"
										name="sponsor"
										v-model="items.sponsor"
										v-validate="'required'"
										data-vv-as="Sponsor"
										:readonly="!isDInputSP"
									/>
									<select
										class="select-crud"
										:class="(errors.has('sponsor') ? 'input-crud-error' : '')"
										@input="selectValue($event.target.value, 'sponsor')"
										:disabled="isDInputSP"
									>
										<option value="" selected disabled>--- select ---</option>
										<option v-for="(item, index) in userList" :key="index" :value="item.userName">
											{{ item.userName }}
										</option>
									</select>
									<label class="checkbox-crud">
										<input type="checkbox" v-model="isDInputSP" />
										<span class="checkbox"></span>
										Direct Input
									</label>
								</td>
							</tr>
							<tr>
								<th>
									Project State
								</th>
								<td>
									<label
										v-for="(option, index) in projectStateList"
										:key="index"
										class="radio-crud"
										:class="(ogProjectState == '3' && (option.comCode == '1' || option.comCode == '2')) || (ogProjectState == '4' && (option.comCode == '1' || option.comCode == '2')) ? 'radio-crud-disabled' : ''"
									>
										<input
											type="radio"
											name="projectStateCd"
											:value="option.comCode"
											v-model="items.projectStateCd"
											v-validate="'required'"
											data-vv-as="Project State"
											:disabled="(ogProjectState == '3' && (option.comCode == '1' || option.comCode == '2')) || (ogProjectState == '4' && (option.comCode == '1' || option.comCode == '2'))"
										/><div class="radio" />
										{{ option.comCodeName }}
									</label>
									<p class="txt-validation" v-if="errors.has('projectStateCd')">
										* {{ errors.first("projectStateCd") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>
									Entry Unit
								</th>
								<td>
									<label
										v-for="(option, index) in projectEntryList"
										:key="index"
										class="radio-crud radio-crud-disabled"
									>
										<input
											type="radio"
											name="projectEntryUnitCd"
											:value="option.comCode"
											v-model="items.projectEntryUnitCd"
											v-validate="'required'"
											data-vv-as="Entry Unit"
											disabled
										/><div class="radio" />
										{{ option.comCodeName }}
									</label>
									<p class="txt-validation" v-if="errors.has('projectEntryUnitCd')">
										* {{ errors.first("projectEntryUnitCd") }}
									</p>
								</td>
							</tr>
							<tr v-if="items.projectEntryUnitCd == '2'">
								<th>
									Head Count
								</th>
								<td>
									<label
										v-for="(option, index) in projectheadCountList"
										:key="index"
										class="radio-crud radio-crud-disabled"
									>
										<input
											type="radio"
											name="projectEntryHeadCount"
											:value="option"
											v-model="items.projectEntryHeadCount"
											v-validate="'required'"
											data-vv-as="Head Count"
											disabled
										/><div class="radio" />
										{{ option }}
									</label>
									<p class="txt-validation" v-if="errors.has('projectEntryHeadCount')">
										* {{ errors.first("projectEntryHeadCount") }}
									</p>
								</td>
							</tr>
							<tr>
								<th>Regist Date</th>
								<td>{{ items.firstRegistDate }}</td>
							</tr>
							<tr>
								<th>Register</th>
								<td>{{ userInfo.userName }} ({{ userInfo.userId }})</td>
							</tr>
							<tr>
								<th>Note</th>
								<td>
									<textarea
										class="textarea-crud"
										name="projectNote"
										v-model="items.projectNote"
										data-vv-as="Note"
									></textarea>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-save fright" title="Modify" @click.stop="doValidation()">
						Modify
					</button>
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doCancel()">
						Cancel
					</button>
					<button v-if="workAccessCheck('DELETE')" type="button" class="btn-cancel" title="Delete" @click="checkData()">
						Delete
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "ProjectEdit",
	beforeCreate(){
		let item = {
			userId: sessionStorage.getItem("userId"),
			privilegeTypeCd: "1",
			privilegeCategory: "PROJECT",
			privilegeName: "ACCESS"
		}

		this.$accessAuth(item).then((status) => {
			if(status == false){
				this.$router.go(-1);
				this.$alert("You are not authorized.","Warning","warning");
			}
		})
	},
	created() {
		this.logger.debug(this, "created()");
		this.items.projectNo = this.projectNo;
		this.selectProjectStateList();
		this.selectProjectEntryList();
		this.selectUserList();
		this.getAccessList('PROJECT');
		this.doSelect();
	},
	props: {
		projectNo: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			items: {},
			projectStateList: [],
			projectTypeList: ["General Clinical trial","Attendance"],
			userList: [],
			projectheadCountList: ["2", "3", "4"],
			isDuplicate: false,
			isDInputPM: false,
			isDInputLM: false,
			isDInputSP: false,
			ogProjectState: "",
			accessList: [],
		};
	},
	methods: {
		selectProjectStateList() {
			this.logger.debug(this, "selectProjectStateList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "PROJECT_STATE"})
				.then((response) => {
					console.log(response);
					this.projectStateList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectProjectEntryList() {
			this.logger.debug(this, "selectProjectEntryList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCode: "PROJECT_ENTRY"})
				.then((response) => {
					console.log(response);
					this.projectEntryList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectUserList() {
			this.logger.debug(this, "selectUserList()");
			axios
				.post(this.config.contextRoot + "/user/selectUserAuthList.do", {})
				.then((response) => {
					console.log(response);
					this.userList = response.data.userList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doSelect() {
			this.logger.debug(this, "doSelect()");
			axios
				.post(this.config.contextRoot + "/project/select.do", this.items)
				.then((response) => {
					console.log(response);
					this.items = response.data.item;
					this.ogProjectState = response.data.item.projectStateCd;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		selectValue(value, target) {
			this.$set(this.items, target, value);
		},
		doValidation() {
			this.logger.debug(this, "doValidation()");
			this.$validator
				.validateAll()
				.then((result) => {
					this.logger.debug(this, "doValidationResult:" + result);
					this.doDuplicateCheck();
					if (result && !this.isDuplicate)
					{
						this.$confirm("Do you want to modify the current project?","Question","question").then(() => {
							EventBus.$emit("project-loading", true);
							this.doUpdate();
						});
					}
				})
				.catch(() => {
					this.logger.debug(this, "-->" + this.errors.all());
					alert(this.errors.all());
					return false;
				});
		},
		doDuplicateCheck() {
			this.logger.debug(this, "doDuplicateCheck()");
			axios
				.post(this.config.contextRoot + "/project/duplicateCheck.do", this.items)
				.then((response) => {
					console.log(response);
					if(response.data.projectExistCount == 0 )
					{
						this.isDuplicate = false;
					}
					else
					{
						this.isDuplicate = true;
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doUpdate() {
			this.logger.debug(this, "doUpdate()");
			axios
				.post(this.config.contextRoot + "/project/update.do", this.items)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		checkData() {
			this.logger.debug(this, "checkData()");
			if(this.items.projectVersion == 1 && this.items.projectVersionList.length > 1){
				this.$alert("You need to delete other versions of the project first.","Warning","warning");
			}
			else{
				axios
				.post(this.config.contextRoot + "/project/checkData.do", this.items)
				.then((response) => {
					console.log(response);
					if(response.data.projectPageCount == 0 )
					{
						this.$confirm("Do you want to delete the current project?","Question","question").then(() => {
								this.doDelete();
							});
					}
					else
					{
						this.$confirm("The project already has existing data.\nDo you want to continue?","Warning","warning").then(() => {
							this.$confirm("Do you want to delete the current project?","Question","question").then(() => {
								this.doDelete();
							});
						});
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			}
		},
		doDelete() {
			this.logger.debug(this, "doDelete()");
			EventBus.$emit("project-loading", true);
			axios
				.post(this.config.contextRoot + "/project/delete.do", this.items)
				.then((response) => {
					if (response.status == 200)
					{
						console.log(response);
						location.reload();
					}
					else
					{
						alert(response);
						console.log(response);
					}
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		doCancel() {
			this.logger.debug(this, "doCancel()");
			this.$confirm("Do you want to cancel the project editing?","Question","question").then(() => {
				this.doClose();
			});
		},
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.projectEdit.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.projectEdit.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("project-cancel", "isEdit");
			}.bind(this), 410);
		},
		getAccessList(category){
			axios
				.post(this.config.contextRoot + "/access/getCategoryPrivilegeList.do", {privilegeCategory: category})
				.then((response) => {
					console.log(response);
					this.accessList = response.data.privilegeList;
					this.accessList.forEach((obj) =>{
						this.workCheck(obj);
					})
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
		async workCheck(obj){
			let item = {};
			item = {
				userId: sessionStorage.getItem("userId"),
				privilegeTypeCd: obj.privilegeTypeCd,
				privilegeCategory: obj.privilegeCategory,
				privilegeName: obj.privilegeName
			}
			obj.isAccess = await this.$accessAuth(item);
		},
		workAccessCheck(name){
			for(let  i = 0; i < this.accessList.length; i++){
				if(this.accessList[i].privilegeName == name){
					return this.accessList[i].isAccess;
				}
			}
		},
	}
};
</script>

<style scoped>
.half {
	width: 45%;
}
.radio-crud {
	width: 13%;
}
.project-crud {
	width: 24%;
}
.select-crud {
	width: 33%;
	margin: 0 8px;
}
.checkbox-crud {
	color: #333;
	float: right;
}
.btn-cancel {
	margin-right: 10px;
}

.radio-crud-disabled {
	opacity: 0.6;
}
textarea::-webkit-scrollbar {
    border-radius: 20px;
}
textarea::-webkit-scrollbar-thumb{
    float: right;
    border: 6px solid transparent;
    border-radius: 20px;
    background: linear-gradient(to top, #9ecc5a, #2bade0);
    background-clip: padding-box;
}
</style>