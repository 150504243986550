<template>
	<div ref="queryHistory" class="dialog-container">
		<div class="dialog" style="max-height: 560px; transform: translate(0, -280px); max-width: 1500px;">
			<form @submit.stop="doValidation()">
				<div class="top" style="height: 30px">
					<p class="txt-crud-title">
						Test Query History
					</p>
				</div>
				<div class="center">
					<table class="tb-list-vertical">
                        <colgroup>
                            <col width="3%" />
                            <col width="12%" />
                            <col width="auto" />
                            <col width="15%" />
                            <col width="15%" />
                            <!-- <col width="7%" /> -->
                            <col width="7%" />
                            <col width="7%" />
                            <col width="10%" />
                            <col width="7%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    No
                                </th>
                                <th>
                                    Screening No
                                </th>
                                <th>
                                    Field Name
                                </th>
                                <th>
                                    Send Message
                                </th>
                                <th>
                                    Response
                                </th>
                                <!-- <th>
                                    Degree
                                </th> -->
                                <th>
                                    Before
                                </th>
                                <th>
                                    After
                                </th>
                                <th>
                                    User
                                </th>
                                <th>
                                    Date/Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(history, index) in queryHistoryList" :key="index">
                                <td>
                                    {{ index+1 }}
                                </td>
                                <td>
                                    {{ history.screeningName }}
                                </td>
                                <td>
                                    {{ history.visitName + ' > ' + history.pageLabel + ' > ' + history.questionName }}
                                </td>
                                <td :title="history.sendMsg" style="word-break: break-all;">
                                    {{ history.sendMsg != '' ? history.sendMsg : '-' }}
                                </td>
                                <td :title="history.answerMsg" style="word-break: break-all;">
                                    {{ history.answerMsg != '' ? (getStatusName(history.afterStatus)!='OPEN' ? history.answerMsg : '-') : '-' }}
                                </td>
                                <!-- <td>
                                    {{ history.queryDegree != '' ?  history.queryDegree : '-'}}
                                </td> -->
                                <td
                                    class="bold"
                                    :class="getStatusName(history.beforeStatus)=='CLOSE' ? 'red' : getStatusName(history.beforeStatus)=='OPEN' ? 'blue' : getStatusName(history.beforeStatus)=='ANSWER' ? 'green' : ''"
                                >
                                    {{ history.beforeStatus != '' ? getStatusName(history.beforeStatus) : '-' }}
                                </td>
                                <td
                                class="bold"
                                    :class="getStatusName(history.afterStatus)=='CLOSE' ? 'red' : getStatusName(history.afterStatus)=='OPEN' ? 'blue' : getStatusName(history.afterStatus)=='ANSWER' ? 'green' : ''"
                                >
                                    {{ getStatusName(history.afterStatus)}}
                                </td>
                                <td>
                                    <b>{{ history.firstRegistUserId }}</b><br>
                                    ({{ history.userName }})
                                </td>
                                <td>
                                    {{ history.firstRegistDate.substring(0, 19) }}
                                </td>
                            </tr>
                            <tr v-if="queryHistoryList.length == 0">
                                <td colspan="10" class="no-list">
                                    There is no registered history, Please register the history.
                                </td>
                            </tr>
                        </tbody>
                    </table>
				</div>
				<div class="bottom">
					<button type="button" class="btn-cancel fright" title="Cancel" @click="doClose()">
						Close
					</button>
                    <button type="button" class="btn-cancel fright" title="Cancel" @click="excelDownload()">
						Excel Download
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "queryHistory",
	created() {
        this.logger.debug(this, "created()");
        this.item.projectNo = this.$route.params.projectNo;
        this.item.validNo = this.selectedValidationNo;

        this.selectqueryHistory();
        this.selectComCodeList();
    },
    props: {
		selectedValidationNo: {
			type: String,
			required: true
		},
        printMsg: {
			type: String,
			required: true
		}
	},
	data() {
		return {
            item: {},
            queryHistoryList: [],
            codeList: [],
		};
    },
	methods: {
		selectqueryHistory() {
            this.logger.debug(this, "selectqueryHistory()");
			axios
				.post(this.config.contextRoot + "/testQueryResolution/selectqueryHistory.do", this.item)
				.then((response) => {
					console.log(response);
                    this.queryHistoryList = response.data.queryHistoryList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
			
        },
        selectComCodeList() {
			this.logger.debug(this, "selectComCodeList()");
			axios
				.post(this.config.contextRoot + "/common/code/selectComCodeList.do", {classCodeArray: ["QUERY_STATUS_CD"]})
				.then((response) => {
					this.codeList = response.data.codeList;
				})
				.catch((error) => {
					this.exceptionHandler(this, error);
				});
		},
        excelDownload() {
            EventBus.$emit("project-loading", true);
            this.logger.debug(this, "excelDownload()");

            this.item.queryHistoryVoList = this.queryHistoryList;

			axios
				.post(this.config.contextRoot + "/excel/queryHistoryDownload.do", this.item, {responseType: "blob"})
				.then((response) => {
					console.log(response);
                    const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					
					let today = new Date();
					let year = today.getFullYear();
					let month = ('0' + (today.getMonth() +1)).slice(-2);
					let day = ('0' + (today.getDate())).slice(-2);

					today = year + "-" + month + "-" + day;

                    var name = "Test_Query_History";

					link.setAttribute("download", name + "_"+today+".xlsx");
					document.body.appendChild(link);
					link.click();

					EventBus.$emit("project-loading", false);
					
					this.$alert("The file has been downloaded.", "Info", "info").then((result) => {
						if (result === true) {
							// location.reload();
						}
					});
				})
				.catch((error) => {
                    this.exceptionHandler(this, error);
				});
                
        },
        getStatusName(code){
            let statusName = '';
            for(let i = 0; i < this.codeList.length; i++){
                if(this.codeList[i].comCode == code){
                    statusName = this.codeList[i].comCodeName;
                }
            }

            return statusName;
        },
		doClose() {
			this.logger.debug(this, "doClose()");
			this.$refs.queryHistory.style.animation = "fadeOut 0.4s ease-in-out both";
			this.$refs.queryHistory.children[0].style.animation = "fadeOutDrop 0.4s ease-in-out both";
			setTimeout(function() {
				EventBus.$emit("view-close", "isQueryHistory");
			}.bind(this), 410);
		}
	}
};
</script>

<style scoped>
.btn-cancel {
	margin-right: 10px;
}
.red {
	color: rgb(224, 91, 91);
}
.green {
	color: #9ecc5a;
}

.blue{
	color: #2bade0;
}
</style>